<template>
  <div class="container__info--user" v-if="contact">
    <div class="container__flex">
      <div>
        <profile-image
          :photo="contactUserPicture"
          :name="contact.fullName"
          size="large"
          class="image--user"
        />
      </div>
      <div class="data--user">
        <p style="margin-bottom: 6px">
          <span class="text--bold">{{ contact.fullName }}</span>
          <a-tooltip v-if="contact.origin">
            <template #title>
              <div>{{ tooltipContactCreate }}</div>
            </template>
            <EnvironmentFilled :class="'create ' + classContactCreateIcon" />
          </a-tooltip>
        </p>
        <!-- Icon / EnvironmentFilled -->
         
         <span class="campaign">Campaña: {{ contact?.portfolio?.name }}</span>
        <!-- <span class="campaign">Campaña: {{ campaignName }}</span> -->
      </div>
    </div>
    <div v-if="!isWorkspace" class="section_channels">
      <p style="margin: 0px" v-if="contact.lastInteraction?.identifier && getChannels?.length > 0">
        Canales interactuados
      </p>
      <popover-channels :channels="getChannels" :size="20" />
    </div>
  </div>
  <div class="warning--portfolio" v-if="getWorkpace">
    <InfoCircleOutlined />
    <span>
      Para poder realizar llamadas o enviar mensajes a este contacto debes asignar a tu usuario a la
      misma campaña de este contacto. Recuerda también tener acceso al módulo de Workspace.
    </span>
  </div>
  <div class="warning--workspace" v-if="!getWorkspace">
    <InfoCircleOutlined />
    <span>
      Para poder realizar llamadas o enviar mensajes a los contactos, tu usuario debe tener acceso
      al módulo de Workspace.
    </span>
  </div>

  <!-- <a-tabs v-model:activeKey="currentTab" class="client-tabs" type="card" centered> -->
  <a-tabs v-model:activeKey="currentTab" class="client-tabs" type="card" centered>
    <a-tab-pane :key="ContactDetailsTabs.Personal">
      <template #tab>
        <div class="tab-content">
          <IconCrm in-tab :active="currentTab === ContactDetailsTabs.Personal" />
          Personal
        </div>
      </template>
      <section class="container">
        <a-tabs default-active-key="1" class="client-tab">
          <a-tab-pane key="1" tab="Datos personales">
            <contact-data-table :itemId="itemId" v-if="itemId" />
          </a-tab-pane>
          <a-tab-pane key="2" tab="Teléfonos" force-render>
            <contact-phones-table
              :itemId="itemId"
              v-if="itemId"
              :userDataInfo="getWorkspace"
              :portfolio="portfolio"
              @update-table="handleUpdate"
            />
          </a-tab-pane>
          <a-tab-pane key="3" tab="Direcciones">
            <contact-addresses-table :itemId="itemId" v-if="itemId" />
          </a-tab-pane>
          <a-tab-pane key="4" tab="Correos">
            <contact-emails-table :itemId="itemId" v-if="itemId" />
          </a-tab-pane>
          <a-tab-pane key="5" tab="Referidos">
            <contact-referrals-table :itemId="itemId" v-if="itemId" />
          </a-tab-pane>
          <a-tab-pane key="6" tab="Datos personalizados">
            <contact-data-custom-table :itemId="itemId" v-if="itemId" />
          </a-tab-pane>
        </a-tabs>
      </section>
    </a-tab-pane>
    <a-tab-pane :key="ContactDetailsTabs.Product">
      <template #tab>
        <div class="tab-content">
          <IconCredicard in-tab :active="currentTab === ContactDetailsTabs.Product" />
          Producto
        </div>
      </template>
      <section class="container">
        <a-tabs default-active-key="1" class="client-tab">
          <a-tab-pane key="1" tab="Productos"
            ><contact-products-table :itemId="itemId" v-if="itemId" />
          </a-tab-pane>
          <!-- v-if="contact.operationType === 4" -->
          <a-tab-pane key="2" tab="Pagos">
            <contact-payments-data-table :itemId="itemId" v-if="itemId"
          /></a-tab-pane>
          <!-- v-if="contact.operationType === 4"" -->
          <a-tab-pane key="3" tab="Promesas">
            <contact-promises-data-table :itemId="itemId" v-if="itemId"
          /></a-tab-pane>
          <!-- v-if="contact.operationType === 3" -->
          <!-- <a-tab-pane key="4" tab="Ventas">
              <contact-promises-data-table :itemId="itemId"
            /></a-tab-pane> -->
        </a-tabs>
      </section>
      <!-- <WorkspaceProductsCard /> -->
    </a-tab-pane>

    <a-tab-pane :key="ContactDetailsTabs.Ticket">
      <template #tab>
        <div class="tab-content">
          <IconTickets in-tab :active="currentTab === ContactDetailsTabs.Ticket" />
          Ticket
        </div>
      </template>
      <section class="container">
        <a-tabs default-active-key="1" class="client-tab">
          <a-tab-pane key="1" tab="Tipificación">
            <contact-typifications-table :itemId="itemId" v-if="itemId" />
          </a-tab-pane>
          <a-tab-pane key="2" tab="Historial">
            <contact-record-table :itemId="itemId" v-if="itemId" :contactInfo="getChannels" />
          </a-tab-pane>
          <a-tab-pane key="3" tab="Tipificaciones externas">
            <!-- <ExternalTypificationsTable :contactId="contact?.id" /> -->
            <ExternalTypificationsTable :contactId="itemId" />
          </a-tab-pane>
        </a-tabs>
      </section>
      <!-- <WorkspaceTypificationsCard /> -->
    </a-tab-pane>

    <a-tab-pane :key="ContactDetailsTabs.Email">
      <template #tab>
        <div class="tab-content">
          <IconEmail in-tab :active="currentTab === ContactDetailsTabs.Email" />
          Correo
        </div>
      </template>
      <EmailTabTable :contactId="itemId" :isWorkspace="isWorkspace" />
    </a-tab-pane>
  </a-tabs>

  <ChangeStatusContactModal
    v-if="isCallInactiveModal"
    :record="contact"
    :itemId="itemId"
    :status="contact.status == ContactStatus.Active ? ContactStatus.Inactive : ContactStatus.Active"
    @onCancel="handleCloseModal"
  />
  
  <!-- <DeleteContactModal
    :record="contact"
    v-if="isCallInactiveModal"
    @onCancel="handleCloseModal"
    :itemId="itemId"
    @onCloseDrawer="handleCloseDrawer"
  /> -->
  <DeleteContactCallActiveModal
    :record="contact"
    v-if="isCallActiveModal"
    @onCancel="handleCloseModal"
    :itemId="itemId"
    @onCloseDrawer="handleCloseDrawer"
    :contact="contact"
  />
</template>

<script>
import { inject, ref } from "vue";
import { fetchLoggedUser } from "@/app/settings/services";

import EventBus from "@/app/shared/utils/eventBus";

import ContactDataTable from "@/app/contacts/components/tables/ContactDataTable.vue";
import ContactPhonesTable from "@/app/contacts/components/tables/phone/ContactPhonesTable.vue";
import ContactAddressesTable from "@/app/contacts/components/tables/addresses/ContactAddressesTable.vue";
import ContactEmailsTable from "@/app/contacts/components/tables/email/ContactEmailsTable.vue";
import ContactTypificationsTable from "@/app/contacts/components/tables/typifications/ContactTypificationsTable.vue";
import ContactProductsTable from "@/app/contacts/components/tables/products/ContactProductsTable.vue";
import ContactPaymentsDataTable from "@/app/contacts/components/tables/payments/ContactPaymentsDataTable.vue";
import ContactPromisesDataTable from "@/app/contacts/components/tables/promises/ContactPromisesDataTable.vue";
import ContactReferralsTable from "@/app/contacts/components/tables/referrals/ContactReferralsTable.vue";
import ProfileImage from "@/app/shared/components/organisms/ProfileImage.vue";
import ContactRecordTable from "@/app/contacts/components/tables/record/ContactRecordTable.vue";
import ExternalTypificationsTable from "@/app/contacts/components/tables/record/ExternalTypificationsTable.vue";
import ContactDataCustomTable from "@/app/contacts/components/tables/ContactDataCustomTable.vue";
// import DeleteContactModal from "@/app/contacts/components/modal/DeleteContactModal.vue";
import ChangeStatusContactModal from "@/app/contacts/components/modal/ChangeStatusContactModal.vue";
import DeleteContactCallActiveModal from "@/app/contacts/components/modal/DeleteContactCallActiveModal.vue";
import PopoverChannels from "@/app/shared/components/molecules/PopoverChannels.vue";
import EmailTabTable from "@/app/contacts/components/tables/emailTab/EmailTabTable.vue";

import { EnvironmentFilled } from "@ant-design/icons-vue";

import { ContactDetailsTabs } from "@/app/workspace/utils/enums";
import { DialingType } from "@/app/shared/utils/enums";
import { ProductName } from "@/app/shared/utils/constants";

import IconCrm from "@/app/common/components/atoms/IconCrm.vue";
import IconCredicard from "@/app/common/components/atoms/IconCredicard.vue";
import IconTickets from "@/app/common/components/atoms/IconTickets.vue";
import IconEmail from "@/app/common/components/atoms/IconEmail.vue";

import { ContactStatus } from "@/app/contacts/utils/enums";

export default {
  components: {
    ContactDataTable,
    ContactPhonesTable,
    ContactAddressesTable,
    ContactEmailsTable,
    ContactTypificationsTable,
    ContactRecordTable,
    ContactDataCustomTable,
    ContactProductsTable,
    ContactPaymentsDataTable,
    ContactPromisesDataTable,
    ContactReferralsTable,
    ProfileImage,
    // DeleteContactModal,
    ChangeStatusContactModal,
    DeleteContactCallActiveModal,
    ExternalTypificationsTable,
    PopoverChannels,
    EmailTabTable,
    EnvironmentFilled,
    IconCrm,
    IconCredicard,
    IconTickets,
    IconEmail,
  },
  props: {
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
    campaignName: {
      required: false,
      type: String,
    },
    isWorkspace: {
      required: false,
      type: Boolean,
      default: false,
    },
    portfolio: {
      type: Object,
      default: () => ({}),
    },
    isCallInactiveModal: {
      required: false,
      type: Boolean,
      default: false,
    },
    isCallActiveModal: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const selectedTabId = inject("selectedTabId");
    const currentTab = ref(ContactDetailsTabs.Personal);

    return { selectedTabId, currentTab, ContactDetailsTabs, ContactStatus };
  },
  computed: {
    contact() {
      return this.$store.getters["getContact"];
    },
    getChannels() {
      return this.contact?.channels;
    },
    getWorkspace() {
      return !!this.getUserData?.options_menu.find((item) => item.scope === "menu_workspace");
    },
    getUserData() {
      return this.$store.getters["getDataUser"];
    },
    contactUserPicture() {
      return this.contact?.profile_picture || null;
    },
    classContactCreateIcon() {
      if (this.contact?.origin == 1) return "create_import";
      else if (this.contact?.origin == 2) return "create_manual";
      else if (this.contact?.origin == 3) return "create_score";
      else return "create_leads";
    },
    tooltipContactCreate() {
      if (this.contact?.origin == 1) return "Contacto creado desde una importación.";
      else if (this.contact?.origin == 2)
        return "Contacto creado de forma manual en la plataforma.";
      else if (this.contact?.origin == 3) return `Contacto creado desde ${ProductName} API.`;
      else return "Contacto creado desde Leads API.";
    },
  },
  data() {
    return {
      updateContactListener: null,
    }
  },
  watch: {
    itemId(value) {
      if (value) {
        this.refreshContact(this.itemId);
      }
    },
  },
  mounted() {
    this.fetchBusiness();
    if (this.itemId) {
      this.refreshContact(this.itemId);
    }
    this.updateContactListener = () => {
      this.$store.dispatch("fetchContact", this.itemId);
    };
    EventBus.on("UPDATE_DATA_CONTACT", this.updateContactListener);
  },
  beforeUnmount() {
    EventBus.off("UPDATE_DATA_CONTACT", this.updateContactListener);
  },
  methods: {
    async fetchBusiness() {
      this.business = await fetchLoggedUser(this.getUserData);
    },
    async refreshContact(value) {
      this.$store.dispatch("fetchContact", value);
      // this.$store.dispatch("fetchContactCustomFields", value);
      // this.$store.dispatch("fetchContactCustomData", value);
      // this.$store.dispatch("fetchPhoneContact", { id: value });
      // this.$store.dispatch("fieldsProductContact", value);
      // this.$store.dispatch("fetchProductContact", value);
      // this.$store.dispatch("fetchPaymentsClient", value);
      // this.$store.dispatch("fetchPromisesClient", value);
    },
    async handlePhoneClick(number) {
      this.$store.commit("SET_DIALING_TYPE", 1);
      this.$jssip.clearData();
      await this.$jssip.call(number, DialingType.Manual, this.getUserData.id, this.contact.id);
      this.$store.dispatch("setCallFinish", false);
    },
    handleCloseModal() {
      this.$emit("handleCloseModal");
    },
    handleCloseDrawer() {
      this.$emit("handleCloseDrawer");
    },
  },
};
</script>

<style lang="sass" scoped>
.ant-form-item
  margin-bottom: 4px

.button__drawe--close
  margin-right: 8px

.drawer__footer--fixed
  bottom: 0
  width: 100%
  border-top: 1px solid $gray-4
  padding: 10px 16px
  left: 0
  background: $white
  border-radius: 0 0 4px 4px
  margin-right: 10px
  position: absolute
  display: flex
  justify-content: flex-end
  align-items: center
  z-index: 1

.image
  width: 17.78px
  margin-right: 15px
  margin-bottom: 10px

.drawer__body
  padding-bottom: 35px

  &--title
    font-weight: 600
    color: $gray-10

.phone
  cursor: pointer

.text__delete
  color: $red-6
  margin-right: 372px

.image--user
  width: 50px
  height: 50px
  border-radius: 50%
  margin-left: 13px

.channels
  width: 16px
  height: 16px
  margin-left: 15px

.container__flex
  display: flex

.container__info--user
  display: flex
  justify-content: space-between
  margin-top: -10px

.container__flex--channels
  display: flex
  flex-direction: row-reverse

.data--user
  margin-left: 10px
  .campaign
    font-size: 13px
    color: $gray-7
    font-style: italic

.text--bold
  margin-bottom: 5px
  color: $gray-9
  font-weight: 600
  font-size: 16px

:deep(.ant-tabs-nav .ant-tabs-tab)
  // margin: 0

:deep(.ant-tabs-nav-scroll)
  border-bottom: 2px solid white !important

.last__contact
  font-size: 12px
  margin-left: 10px
  color: $gray-6
  font-style: italic

.channel--id
  width: 600px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.container
  margin-top: 0
  border-top: $border-medium
  // border-bottom: $border-medium

.warning--portfolio
  margin-top: 20px
  margin-bottom: 2px
  font-style: italic

.warning--workspace
  margin-top: 15px
  margin-bottom: 5px
  font-style: italic

.client-tabs
  margin-top: 10px
  :deep(.ant-tabs-nav)
    margin: 0

  :deep(.ant-tabs-nav-wrap)
    display: block !important

  :deep(.ant-tabs-nav-list)
    justify-content: space-evenly

  :deep(.ant-tabs-tab)
    min-width: 23%
    padding-top: 14px !important
    padding-bottom: 14px !important
    justify-content: center

    .tab-content
      display: flex
      justify-content: center
      align-items: center
      gap: 10px
      color: $gray-8

    &.ant-tabs-tab-active
      .tab-content
        color: $blue-6

.container
  margin-top: 0
  padding: 12px 16px
  border-top: $border-medium

  .client-tab
    :deep(.ant-tabs-nav)
      margin-bottom: 16px

    :deep(.ant-tabs-nav-wrap)
      display: flex !important

    :deep(.ant-tabs-nav-list)
      justify-content: flex-start !important
      padding: 0 !important

    :deep(.ant-tabs-tab)
      min-width: 0 !important
      padding-top: 12px !important
      padding-bottom: 12px !important

.section_channels
  text-align: right
.create
  margin-left: 10px
  font-size: 16px
  &_manual
    color: #C41D7F
  &_score
    color: #D46B08
  &_import
    color: #1D39C4
  &_leads
    color: #08979C
</style>
