<template>
  <template v-if="showElement">
    <a-divider v-if="isWorkspace && index !== 0" type="horizontal" class="divider" />
    <component
      ref="formElementRef"
      :is="ComponentByType[input.type]"
      :input="input"
      :index="typeof index !== 'undefined' ? index + 1 : undefined"
      :isWorkspace="isWorkspace"
      :conditionedAttributes="conditionalBody?.[input.name]"
      :modalKey="modalKey"
      :isPerProduct="isPerProduct"
      :hasInputsFilled="hasInputsFilled"
    />
  </template>
</template>

<script setup lang="ts">
import { ref, computed, inject, watch } from "vue";

import { ConditionedAttribute, ElementType, FormElement, isDate } from "@/@types/entities/forms";

import Header from "./inputs/Header.vue";
import File from "./inputs/File.vue";
import ShortText from "./inputs/ShortText.vue";
import LongText from "./inputs/LongText.vue";
import SingleOption from "./inputs/SingleOption.vue";
import MultipleOptions from "./inputs/MultipleOptions.vue";
import Dropdown from "./inputs/Dropdown.vue";
import Reminder from "./inputs/Reminder.vue";
import Date from "./inputs/Date.vue";
import Hour from "./inputs/Hour.vue";
import Pdp from "./inputs/Pdp.vue";

const props = defineProps<{
  input: FormElement;
  isWorkspace?: boolean;
  index?: number;
  conditionalBody?: Record<string, ConditionedAttribute>;
  modalKey: string;
  isPerProduct?: boolean;
  hasInputsFilled?: boolean;
}>();

const ComponentByType: Record<ElementType, unknown> = {
  header: Header,
  file: File,
  "short-text": ShortText,
  "long-text": LongText,
  "single-option": SingleOption,
  "multiple-options": MultipleOptions,
  dropdown: Dropdown,
  reminder: Reminder,
  date: Date,
  hour: Hour,
  pdp: Pdp,
};

const showElement = computed(() => {
  if (typeof props.conditionalBody?.[props.input.name]?.show === "undefined") return true;

  return props.conditionalBody?.[props.input.name]?.show;
});

const workspaceFormState = inject<any>("workspaceFormState");

watch(
  showElement,
  (value) => {
    if (!value) {
      console.log(workspaceFormState, 'workspaceFormState');
      console.log(props.input.name, 'props.input.name');
      workspaceFormState[props.input.name] = null;
      console.log(workspaceFormState, 'workspaceFormState');
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formElementRef = ref<any>();

const element = computed(() => {
  return {
    name: props.input.name,
    label: props.input.label,
    value: formElementRef.value?.initialValue,
    type: props.input.type,
    is_range: isDate(props.input) ? props.input.is_range : null,
    status: props.input.status,
  };
});

defineExpose({ element });
</script>

<style scoped lang="sass">
.divider
  margin: 20px 0
  color: $gray-4
</style>
