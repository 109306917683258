<template>
  <section>
    <a-table
      row-key="id"
      size="middle"
      :columns="columnsTable"
      :data-source="productsData"
      :scroll="{ x: 700 }"
      :pagination="false"
    >
    </a-table>
  </section>
</template>

<script>
import { columnsFixedTableLeft } from "@/app/shared/utils/columnTable";

export default {
  components: {},
  props: {
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
  },
  data() {
    return {
      isAddAddressesVisible: false,
    };
  },
  created() {
    this.handleFetchProducts();
  },
  computed: {
    productsFields() {
      return this.$store.getters["getProductsFieldsContact"];
    },
    productsData() {
      return this.$store.getters["getProductContactData"]?.results;
    },
    // columnsTable() {
    //   let columns = columnsFixedTableLeft(this.productsFields);
    //   const index = columns.findIndex((item) => {
    //     return item.key === "id";
    //   });
    //   columns.splice(index, 1);
    //   return columns;
    // },
    columnsTable() {
      let columns = this.productsFields.map((col) => ({
        title: col.title == "code" ? "Código" : col.title,
        dataIndex: col.index,
        key: col.index,
      }));
      const index = columns.findIndex((item) => {
        return item.key === "id";
      });
      columns.splice(index, 1);
      return columnsFixedTableLeft(columns);
    },
  },
  methods: {
    handleFetchProducts() {
      this.$store.dispatch("fieldsProductContact", this.itemId);
      this.$store.dispatch("fetchProductContact", this.itemId);
    }
  }
};
</script>

<style lang="sass" scoped>
.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7
.icon
  color: $blue-6
  margin-left: $margin-tiny
  margin-top: 3px

.actions
  display: flex

:deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td)
  overflow-wrap: initial
  width: 11em
</style>
