<template>
  <div class="actions">
    <a-popover
      v-if="hasEmoji"
      v-model:visible="showEmojiPopover"
      :title="null"
      overlayClassName="emoji-popover"
      trigger="click"
      placement="topLeft"
      arrowPointAtCenter
    >
      <ActionButton class="box" type="emoji" @click="showEmojiPopover = true" />

      <template #content>
        <EmojiPicker
          native
          hide-search
          hide-group-icons
          disable-skin-tones
          :group-names="groupNames"
          @select="handleSelectEmoji"
        />
      </template>
    </a-popover>

    <label for="file-input" v-if="hasFile">
      
      <ActionButton :disabled="(Boolean(file) && !multiple) || (multiple && disabledMaxFilesPerType('file'))" class="box" type="file" prevent-event />

      <input
        ref="fileInputRef"
        id="file-input"
        type="file"
        :multiple="multiple"
        :disabled="(Boolean(file) && !multiple) || (multiple && disabledMaxFilesPerType('file'))"
        :accept="supportedFileTypes.join()"
        @change="handleFileInputChange($event)"
      />
    </label>

    <label for="media-input" v-if="hasMedia">
      <ActionButton :disabled="(Boolean(file) && !multiple)  || (multiple && disabledMaxFilesPerType('media'))" class="box" type="media" 
        prevent-event :tooltip="tooltipMedia" />
      
      <input
        ref="mediaInputRef"
        id="media-input"
        type="file"
        :multiple="multiple"
        :disabled="(Boolean(file) && !multiple)  || (multiple && disabledMaxFilesPerType('media'))"
        :accept="supportedMediaTypes.join()"
        @change="handleFileInputChange($event)"
      />
    </label>
  </div>

  <div class="file" v-if="file && !multiple">
    <p>
      <PaperClipOutlined style="font-size: 18px" v-if="hasFile" />
      <img v-else :src="attachImageIcon" draggable="false" class="icon-media" />

      <span class="name">{{ getFileName(file) }}</span>
    </p>

    <a-button type="link" class="button" danger @click="handleDelete">
      <DeleteOutlined />
    </a-button>
  </div>

  <CustomScrollbar height="220px" auto v-if="multiple">
    <div class="files">
      <div class="file" v-for="file, pos in files" :key="pos">
        <p>
          <PaperClipOutlined style="font-size: 18px" v-if="hasFile" />
          <img v-else :src="attachImageIcon" draggable="false" class="icon-media" />

          <span class="name">{{ getFileName(file) }}</span>
        </p>

        <a-button type="link" class="button" danger @click="handleDelete(pos)">
          <DeleteOutlined />
        </a-button>
      </div>
    </div>
  </CustomScrollbar>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { message } from "ant-design-vue";
import { PaperClipOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";

import { Emoji } from "@/@types/workspace";
import { groupNames } from "@/app/workspace/utils/emojiPickerOptions";
import {
  supportedMediaTypes,
  supportedFileTypes,
} from "@/app/workspace/components/chat/input/supportedFiles";

import ActionButton from "@/app/workspace/components/chat/input/ActionButton.vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import attachImageIcon from "@/app/workspace/assets/chat/attach-image-icon.svg?url";

const OneMegaByte = 1_000_000;

const props = withDefaults(
  defineProps<{
    file: string | File | undefined;
    files: string[] | File[] | undefined;
    multiple: boolean;
    hasEmoji: boolean;
    hasFile: boolean;
    hasMedia: boolean;
    tooltipMedia?: string;
    validatePerType: boolean;
  }>(),
  {
    multiple: false,
    files: [],
    hasEmoji: true,
    hasFile: true,
    hasMedia: true,
    tooltipMedia: undefined,
    validatePerType: true,
  }
);

const emit = defineEmits<{
  selectEmoji: [emoji: string];
  "update:file": [file: File | undefined];
  "update:files": [files: File[]];
}>();

const showEmojiPopover = ref(false);

const fileInputRef = ref<HTMLInputElement>();
const mediaInputRef = ref<HTMLInputElement>();

const getFileName = (file: File | string) => {
  let fileName;
  if (typeof file === "string") fileName = file.split("/").at(-1);
  else fileName = file?.name;

  if (!fileName) return '';

  const maxLength = 43;
  const extension = fileName.split('.').pop() || '';
  const name = fileName.replace(`.${extension}`, '');

  if (name.length <= maxLength) {
    return fileName;
  }

  return `${name.slice(0, maxLength)}...${name.slice(-5)}.${extension}`;
};

const handleSelectEmoji = (emojiMetadata: Emoji) => {
  const emojiChar = emojiMetadata.i;
  emit("selectEmoji", emojiChar);
  showEmojiPopover.value = false;
};

const handleFileInputChange = (event: Event) => {
  const target = event.target as HTMLInputElement;

  try {
    if (!props.multiple) {
      validateFile(target.files?.[0]);
      const selectedFile = target.files?.[0];
      emit("update:file", selectedFile);
    } else if (props.multiple && target?.files && target?.files?.length > 0) {
      const filesValidated = validateFileMultiple(target.files);
      const currentFiles = props.files || [];
      
      const validatedByType = filesValidatedByType(filesValidated, currentFiles)

      if (validatedByType.length < filesValidated.length && props.validatePerType) {
        message.error("Se excedió el límite máximo de 5 archivos por tipo (imágenes, videos o documentos)");
      } else if (validatedByType.length < filesValidated.length && !props.validatePerType) {
        message.error("Se excedió el límite máximo de 5 archivos");
      }

      if (validatedByType.length > 0) {
        const files = [...props.files, ...validatedByType];
        emit("update:files", files)
      }
    }
  } catch (error) {
    console.error(error);
    message.error(error as string);
  }
};

const filesValidatedByType = (filesValidated: File[], currentFiles: string[] | File[]) => {
  const maxFilesPerType = 5;

  if (props.validatePerType) {
    const filesByType = {
      images: currentFiles?.filter((f: File | string) => f instanceof File && f?.type.startsWith('image/')) || [],
      videos: currentFiles?.filter((f: File | string) => f instanceof File && f?.type.startsWith('video/')) || [],
      documents: currentFiles?.filter((f: File | string) => f instanceof File && !f?.type.startsWith('image/') && !f.type.startsWith('video/')) || []
    };

    const validatedByType = filesValidated.reduce((acc: File[], file: File) => {
      if (file.type.startsWith('image/') && filesByType.images.length < maxFilesPerType) {
        filesByType.images.push(file);
        acc.push(file);
      } else if (file.type.startsWith('video/') && filesByType.videos.length < maxFilesPerType) {
        filesByType.videos.push(file);
        acc.push(file);
      } else if (!file.type.startsWith('image/') && !file.type.startsWith('video/') && filesByType.documents.length < maxFilesPerType) {
        filesByType.documents.push(file);
        acc.push(file);
      }
      return acc;
    }, []);

    return validatedByType;
  } else {
    const files = [...currentFiles] || [];
    const validatedByType = filesValidated.reduce((acc: File[], file: File) => {
      if (files.length < maxFilesPerType) {
        files.push(file);
        acc.push(file);
      }
      return acc;
    }, []);

    return validatedByType;
  }
};

const disabledMaxFilesPerType = (type: 'file' | 'media') => {
  const maxFilesPerType = 5;
  const files = props.files || [];

  if (props.validatePerType) {
    const filesByType = {
      images: files.filter((f: File | string) => f instanceof File && f?.type.startsWith('image/')),
      videos: files.filter((f: File | string) => f instanceof File && f?.type.startsWith('video/')),
      documents: files.filter((f: File | string) => f instanceof File && !f?.type.startsWith('image/') && !f.type.startsWith('video/'))
    };

    if (type === 'file' && filesByType.documents.length >= maxFilesPerType) return true;
    if (type === 'media' && (filesByType.images.length >= maxFilesPerType && filesByType.videos.length >= maxFilesPerType)) return true;

    return false;
  } else {
    return files.length >= maxFilesPerType;
  }
}

const validateFileMultiple = (files: FileList) => {
  if (files.length == 0) {
    message.error("No se seleccinó ningun archivo");
    return [];
  }

  const filesValidated = Array.from(files).filter((file) => (file.size <= 5 * OneMegaByte));
  if (filesValidated.length < files.length) {
    message.error("No se agregaron los archivos que superan el tamaño máximo de 5MB");
  }

  return filesValidated;
};

const validateFile = (file: File | undefined) => {
  if (!file) throw "No se seleccinó ningun archivo";

  if (file.size > 5 * OneMegaByte) throw "Supera el tamaño máximo de 5MB";
};

const handleDelete = (pos: number) => {
  emit("update:file", undefined);
  if (props.multiple) {
    const files = props.files;
    console.log(files, 'files 1');
    files.splice(pos, 1)
    console.log(files, 'files 2');
    emit("update:files", files);
  }
  if (fileInputRef.value) fileInputRef.value.value = "";
  if (mediaInputRef.value) mediaInputRef.value.value = "";
};
</script>

<style scoped lang="sass">
.button
  padding: 0
  height: 22px

.actions
  display: flex
  align-items: center
  gap: 4px
  margin-top: 8px

#file-input, #media-input
  display: none

:deep(.box)
  border-radius: 4px
  background-color: $gray-3
  overflow: hidden

:deep(.box img)
  width: 18px

.files
  padding: 0 10px
.file
  display: flex
  justify-content: space-between
  align-items: center
  background-color: $geekblue-1
  border-radius: 4px
  padding: 0 8px
  margin-top: 12px
  color: $gray-8

  p
    margin: 0
    display: flex
    align-items: center
    gap: 4px

  .name
    color: #4096FF

.icon-media
  font-size: 16px
  margin-left: -6px
</style>

<style>
.emoji-popover .ant-popover-inner-content {
  padding: 0 !important;
}

.v3-emoji-picker {
  box-shadow: none !important;
}

.v3-emoji-picker.v3-color-theme-light .v3-footer {
  display: none;
}
</style>
