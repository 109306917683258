<template>
  <a-tooltip :title="tooltip || tooltipTitle[type]">
    <component
      :is="preventEvent ? 'span' : Button"
      class="action__button"
      :class="{ span: preventEvent, disabled: disabled }"
      type="text"
      :disabled="disabled"
      @click.stop="handleClick"
    >
      <SmileOutlined v-if="fromAnt" :style="iconStyles" />
      <img v-else :src="typeIconSrc[type]" :alt="type" draggable="false" :style="iconStyles" />
    </component>
  </a-tooltip>
</template>

<script setup lang="ts">
import { Button } from "ant-design-vue";
import { StyleValue } from "vue";
import { SmileOutlined } from "@ant-design/icons-vue";

import emojiIcon from "@/app/workspace/assets/chat/emoji-icon.svg?url";
import attachFileIcon from "@/app/workspace/assets/chat/attach-file-icon.svg?url";
import attachImageIcon from "@/app/workspace/assets/chat/attach-image-icon.svg?url";
import templateIcon from "@/app/workspace/assets/chat/template-icon.svg?url";
import quickAnswerIcon from "@/app/workspace/assets/chat/quick-answers-icon.svg?url";

type IconType = "emoji" | "file" | "media" | "template" | "quick-answers";

const typeIconSrc: Record<IconType, string> = {
  emoji: emojiIcon,
  file: attachFileIcon,
  media: attachImageIcon,
  template: templateIcon,
  "quick-answers": quickAnswerIcon,
};

withDefaults(
  defineProps<{
    type: IconType;
    preventEvent?: boolean;
    iconStyles?: StyleValue;
    fromAnt?: boolean;
    disabled?: boolean;
    tooltip?: string | null;
  }>(),
  {
    preventEvent: false,
    disabled: false,
    tooltip: null,
  }
);

const emit = defineEmits<{
  (event: "click"): void;
}>();

const tooltipTitle: Record<IconType, string> = {
  emoji: "Emojis",
  file: "Adjuntar documento (Max. 5MB)",
  media: "Adjuntar multimedia",
  template: "Plantillas",
  "quick-answers": "Respuestas rápidas",
};

const handleClick = () => emit("click");
</script>

<style lang="sass" scoped>
.action__button
  padding: 0
  display: flex
  justify-content: center
  align-items: center
  height: 24px
  width: 24px
  min-width: 24px
  border-radius: 4px

  &.span
    cursor: pointer
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)

    &:hover
      background-color: rgba(0, 0, 0, 0.06)

  &.no-events
    pointer-events: none

  &.disabled
    opacity: 0.55
    cursor: not-allowed

img
  width: 22px
  filter: brightness(0.5)

  &[alt="quick-answers"]
    height: 20px
    width: auto
</style>
