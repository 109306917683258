import {
  fetchAgentsPortfolio,
  fetchActiveUsers,
  fetchSearchActiveUsers,
  pushAgentsPortfolio,
} from "@/app/entities/services";
import {
  fetchAllFilterProfile,
} from "@/app/users/services";
import { callHangUp } from "@/app/shared/services";
import {
  SET_ACTIVE_USERS,
  SET_AGENTSPORTFOLIO,
  SELECT_ALL_ACTIVE_USERS,
  SELECT_SINGLE_ACTIVE_USER,
  SELECT_ACTIVE_USERS_BY_PROFILE,
  SET_SELECTED_ACTIVE_USERS,
  SET_CLEAN_LIST_AGENT,
} from "./mutation-types";

const actions = {
  // Agents
  // async fetchAgentPortfolio({ commit }, idPortfolio) {
  //   const agentsPortfolio = await fetchAgentsPortfolio(idPortfolio);
  //   commit(SET_AGENTSPORTFOLIO, agentsPortfolio);
  // },
  // Users Profile
  async fetchActiveUsers({ commit }, search) {
    const items = await fetchActiveUsers(search);
    commit(SET_ACTIVE_USERS, []);
    commit(
      SET_ACTIVE_USERS,
      items.filter((item) => item.status == 1)
    );
  },
  async fetchSearchActiveUsers({ commit }, search) {
    const items = await fetchSearchActiveUsers(search);
    commit(SET_ACTIVE_USERS, []);
    commit(
      SET_ACTIVE_USERS,
      items.filter((item) => item.status == 1)
    );
  },
  selectAllActiveUsers({ commit }, status) {
    commit(SELECT_ALL_ACTIVE_USERS, status);
  },
  selectSingleActiveUser({ commit }, { status, userId }) {
    commit(SELECT_SINGLE_ACTIVE_USER, { status, userId });
  },
  selectActiveUsersByProfile({ commit }, { status, profileName }) {
    commit(SELECT_ACTIVE_USERS_BY_PROFILE, { status, profileName });
  },
  async fetchSelectedActiveUsers({ commit }, idPortfolio) {
    const items = await fetchAgentsPortfolio(idPortfolio);
    commit(SET_SELECTED_ACTIVE_USERS, items);
  },
  async addUsersProfilePortfolio({ commit, dispatch, state }, { portfolioId }) {
    const usersAdded = state.filteredActiveUsers.filter((user) => user.added);
    const addAgentsPortfolio = await pushAgentsPortfolio(portfolioId, usersAdded);
    commit(SET_AGENTSPORTFOLIO, addAgentsPortfolio);
    dispatch("fetchAllPortfoliosByEntity");
  },
  async callHangUp(_, data) {
    await callHangUp(data);
  },
  async cleanListAgents({ commit }) {
    commit(SET_CLEAN_LIST_AGENT, []);
  },
  async fetchAllActiveUsersFilterProfile({ commit }, payload) {
    const data = await fetchAllFilterProfile(payload?.profile, payload?.search);
    commit(SET_ACTIVE_USERS, []);
    commit(SET_ACTIVE_USERS, data.results.filter((item) => item.status == 1));
  },
};

export default actions;
