<template>
  <a-form ref="form" class="form" :model="formState" :rules="rules" layout="vertical">
    <a-form-item label="Correo electrónico" name="username">
      <a-input
        v-model:value="formState.username"
        placeholder="Escribir correo electrónico"
        :disabled="loading"
      />
    </a-form-item>

    <a-form-item label="Contraseña" name="password">
      <a-input-password
        v-model:value="formState.password"
        placeholder="Escribir contraseña"
        :disabled="loading"
      />
    </a-form-item>

    <div class="form__footer">
      <p><a @click="handleRedirectToForgotPassword"> ¿Olvidaste tu contraseña? </a></p>

      <p class="recaptcha-policies">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </p>

      <a-button
        type="primary"
        class="login"
        html-type="submit"
        :loading="loading"
        @click="handleLogIn"
        :disabled="formState.username === ''"
      >
        Ingresar
      </a-button>

      <div class="back">
        <a @click="handleLoginBack">Iniciar sesión en otro equipo de trabajo</a>
      </div>
    </div>
  </a-form>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from "vue";
import { fetchDataUser } from "@/app/auth/services";
import { tokenManager } from "@/app/shared/utils/tokenManager";
import items from "@/app/common/components/organisms/TheSidebar/items";
import { EventManagement, StateOptions } from "@/app/shared/utils/enums";
import { registerEventManagement } from "@/app/shared/services";

export default defineComponent({
  setup() {
    const form = ref();
    const formState = reactive({
      username: "",
      password: "",
    });
    const rules = {
      username: [{ required: true, message: "Campo requerido" }],
      password: [{ required: true, message: "Campo requerido" }],
    };
    return {
      form,
      formState,
      rules,
    };
  },
  data() {
    return {
      credentials: {
        username: "",
        password: "",
      },
      loading: false,
    };
  },
  methods: {
    handleRedirectToForgotPassword() {
      const workspace = localStorage.getItem("workspace");
      this.$router.push(`/recovery/${workspace}`);
    },
    async handleLogIn() {
      this.form.validate().then(async () => {
        try {
          this.loading = true;
          sessionStorage.removeItem('tab_browser')
          sessionStorage.removeItem('tabrowser_id')
          localStorage.removeItem('history_tabs')
          await this.$store.dispatch("fetchAccessToken", toRaw(this.formState));
          const response = await fetchDataUser();
          if (response.token)
            this.$router.push(`/user/register/${this.formState.password}/?changePassword=true`);
          else this.goIntoApp(response);
        } catch (error) {
          console.error(error);
          console.log(error.data);

          if (!error.status) {
            this.$message.error("El recaptcha no es válido, refresque y vuelva a intentarlo");
            this.loading = false;
          }

          switch (error.status) {
            case 400: {
              // SET DATA FOR LOGIN AFTER PAY EXPIRED BILLING
              if (error.data.code == "BILLING") {
                const encodedAuthData = window.btoa(
                  `${this.formState.username}:${this.formState.password}`
                );

                sessionStorage.setItem("authenticationData", encodedAuthData);
                sessionStorage.setItem("userEmail", this.formState.username);

                const parsedQueryParams = new URLSearchParams(error.data.data);
                console.log(parsedQueryParams.toString(), "parsedQueryParams");
                this.$router.push(`/billing/expired?${parsedQueryParams.toString()}`);
                break;
              } else if (error.data.code == "USER") {
                this.$router.push(`/billing/expired?user=disabled`);
                break;
              } else {
                this.$notification.error({
                  message: error?.getErrorMessage(),
                });
                break;
              }
            }

            case 401: {
              this.$notification.error({
                message: "El usuario o la contraseña no son correctos",
              });
              break;
            }

            case 403: {
              console.log(this.formState);
              this.$router.push(`/user/register/${this.formState.password}/?generatedUser=true`);
              break;
            }

            default: {
              this.$message.error("Ha ocurrido un error");
              break;
            }
          }
        } finally {
          this.loading = false;
        }
      });
    },
    async goIntoApp(user) {
      try {
        // const menu = items.find((item) =>
        //   user.options_menu.find((_menu) => _menu.scope === item.scope)
        // );

        const firstUserMenu = user.options_menu[0];
        const firstUserSubmenu = firstUserMenu?.items?.[0];

        const menusList = items.flatMap((item) => {
          const menus = [item];

          if (item.subitems) menus.push(...item.subitems);

          return menus;
        });

        // const path = firstUserSubmenu
        //   ? menusList.find((item) => item.scope === firstUserSubmenu.scope)
        //   : menusList.find((item) => item.scope === firstUserMenu.scope);

        const path = menusList.find((item) =>
          firstUserSubmenu
            ? item.scope === firstUserSubmenu.scope
            : item.scope === firstUserMenu.scope
        );

        console.log({ menusList, firstUserMenu, firstUserSubmenu, path });

        // const path = menu.route;
        localStorage.removeItem("tabCount");
        await registerEventManagement(EventManagement.Login, {
          state: StateOptions.Connected,
        });
        this.$router.push(path.route);
      } catch (error) {
        console.log(error);
      }
    },
    handleLoginBack() {
      if (window.location.hostname == "localhost") {
        this.$router.push({ path: "/home", query: { searchModal: true } });
      } else {
        window.location.href = `https://${import.meta.env.VITE_HOSTNAME}/home?searchModal=true`;
      }
    },
  },
  async created() {
    if (tokenManager.existToken()) {
      this.loading = true;

      try {
        const response = await fetchDataUser();
        this.goIntoApp(response);
      } catch (e) {
        console.log(e);
        tokenManager.removeToken();
      }

      this.loading = false;
    }
  },
});
</script>

<style lang="sass" scoped>
.ant-form
  padding: 12px 0
  position: relative

.drawing
  position: absolute
  overflow: hidden
  right: -52rem
  bottom: -40px
  text-align-last: center

.text--plan
  font-size: 20px
  font-weight: 400
  margin-bottom: 0

.text--score
  font-size: 20px
  font-weight: 600

.login
  width: 100%


.back
  margin-top: 20px
  text-align: center

.recaptcha-policies
  margin-top: 20px
  color: $text-tertiary
  margin-bottom: 8px
  font-size: 12px
</style>
