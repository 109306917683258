<template>
  <a-popover
    arrowPointAtCenter
    title="Selecciona etiquetas"
    placement="bottomRight"
    :disabled="!hasSelectedTickets"
    :trigger="['click']"
    @visibleChange="handleVisibleChange"
    :open="isPopoverOpen"
  >
    <!-- <a-tooltip :title="fullWidth ? null : 'Agregar etiqueta'"> -->
    <a-button
      type="text"
      class="button-tags"
      :disabled="!(hasSelectedTickets || simpleTag)"
      @click.prevent
    >
      <TagOutlined class="icon" />

      Agregar etiquetas
    </a-button>
    <!-- </a-tooltip> -->

    <template #content>
      <div class="list__container" v-if="tags.length !== 0">
        <span class="list__detail"> {{ selectedTagsCount }} Actualmente seleccionados </span>

        <a-checkbox-group
          class="checkbox-group"
          v-model:value="selectedTags"
          @change="handleChangeSelectedTags"
        >
          <div class="list__tags">
            <a-checkbox v-for="tag in tags" :key="tag.id" :value="tag.id" :disabled="tag.is_permanent">
              <TagFilled :style="{ color: `#${tag.color}` }" />
              {{ tag.name }}
            </a-checkbox>
          </div>
        </a-checkbox-group>
      </div>

      <a-menu v-else>
        <div class="no-tags-data">
          Actualmente, no se cuenta con etiquetas.
          <span v-if="hasSettingsMenu">
            Puedes crearlas desde el
            <router-link :to="{ path: '/settings', query: { openAddModal: 'true' } }">
              módulo de Ajustes.
            </router-link>
          </span>
        </div>
      </a-menu>
    </template>
  </a-popover>
</template>

<script setup lang="ts">
import { ref, computed, watch, onUpdated, inject } from "vue";
import { useStore } from "vuex";
import type { Ref } from "vue";
import { TagOutlined, TagFilled } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import _ from "lodash";

import { assignTagToTickets } from "@/app/workspace/services";
import { Ticket, Tag, UserData } from "@/@types/global";
import { WorkspaceVars } from "@/@types/workspace";
import { findIntersection } from "@/app/shared/utils/helpers";

const props = defineProps<{
  hasSelectedTickets: boolean;
  simpleTag: boolean;
  tickets: Ticket[];
  fullWidth?: boolean;
}>();

const emit = defineEmits<{
  (event: "update:ticket"): void;
}>();

const store = useStore();

const selectedTags = ref<number[]>([]);

const workspaceDataSource = inject("workspaceDataSource") as Ref<WorkspaceVars | undefined>;

onUpdated(() => {
  setSelectedTags();
});

watch(
  () => props.tickets,
  () => {
    setSelectedTags();
  }
);

const isPopoverOpen = ref(false);

const handleVisibleChange = (visible: boolean) => {
  if (props.hasSelectedTickets || props.simpleTag) {
    isPopoverOpen.value = visible;
  } else {
    isPopoverOpen.value = false; // Evita que se abra si no cumple la condición
  }
};

const setSelectedTags = () => {
  selectedTags.value = props.simpleTag
    ? props.tickets.flatMap((ticket) => ticket.tags.map((tag) => tag.id))
    : intersectionTags.value;
};

// COMPUTED
const hasSettingsMenu = computed<boolean>(() => {
  const user: UserData = store.getters["getDataUser"];
  const menus = user?.options_menu;

  return menus.some((menu) => menu.scope === "menu_settings");
});

const tags = computed<Tag[]>(() => {
  return workspaceDataSource.value?.tags || [];
});

const intersectionTags = computed<number[]>(() => {
  const tagsId = props.tickets.map((ticket) => ticket.tags.map((tag) => tag.id));

  return findIntersection(...tagsId);
});

const selectedTagsCount = computed<string>(
  () => `${selectedTags.value.length}/${tags.value.length}`
);

// WATCHERS
watch(intersectionTags, () => {
  selectedTags.value = _.cloneDeep(intersectionTags.value);
});

// FUNCTIONS
const handleChangeSelectedTags = _.debounce(async (selectedTagsId: number[]) => {
  const ticketsCurrentTagsId = props.tickets.flatMap((ticket) => ticket.tags.map((tag) => tag.id));

  let tagsToAdd: number[] = [];
  let tagsToRemove: number[] = [];
  let promises: Promise<unknown>[] = [];

  if (props.simpleTag) {
    tagsToAdd = selectedTagsId.filter((tagId) => !ticketsCurrentTagsId?.includes(tagId));
    tagsToRemove = ticketsCurrentTagsId.filter((tagId) => !selectedTagsId.includes(tagId));

    promises = [...tagsToAdd, ...tagsToRemove].map((tagId) =>
      assignTagToTickets({ tickets: props.tickets.map((ticket) => ticket.id), tag: tagId })
    );
  } else {
    tagsToAdd = selectedTagsId.filter((tagId) => !intersectionTags.value.includes(tagId));
    tagsToRemove = intersectionTags.value.filter((tagId) => !selectedTagsId.includes(tagId));

    const ticketsToAdd = props.tickets.filter(
      (ticket) => !ticket.tags.some((tag) => tagsToAdd.includes(tag.id))
    );

    const addPromises = tagsToAdd.map((tagId) =>
      assignTagToTickets({ tickets: ticketsToAdd.map((ticket) => ticket.id), tag: tagId })
    );

    const removePromises = tagsToRemove.map((tagId) =>
      assignTagToTickets({ tickets: props.tickets.map((ticket) => ticket.id), tag: tagId })
    );

    promises = [...addPromises, ...removePromises];
  }

  console.log({ selectedTagsId, tagsToAdd, tagsToRemove });

  try {
    await Promise.all(promises);

    emit("update:ticket");
    store.dispatch("fetchActiveWorkspaceTickets");

    message.success("Se guardaron los cambios");
  } catch (error) {
    message.error("Ocurrió un error");
    console.error(error);
  }
}, 800);
</script>

<style lang="sass" scoped>
.button-tags
  display: flex
  align-items: center
  justify-content: flex-start
  width: 100%

  .icon
    font-size: 18px

.ant-dropdown-menu
  width: 190px !important

.ant-dropdown-link
  height: 15px

.no-tags-data
  color: $gray-8
  text-align: center
  padding: 15px

:deep(.ant-message-notice-content)
  background: red !important
  width: 10px
:deep(.ant-badge-status-dot)
  width: 8px
  height: 7px
.color-icon
  color: $blue-6
  font-size: $font-medium
.color-icon-disabled
  color: $gray-6
  font-size: $font-medium
  cursor: not-allowed

.checkbox-group
  width: 100%

.list
  &__container
    display: flex
    flex-direction: column
    gap: 8px

  &__detail
    color: $gray-7

  &__tags
    display: flex
    flex-direction: column
    gap: 10px

:deep(.ant-checkbox-wrapper)
  margin: 0 !important
  color: $gray-8 !important
</style>
