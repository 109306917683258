<template>
  <div v-if="isNotification" class="notification">
    <span v-if="message.message"> {{ message.message }} </span>
    <span v-else> El ticket fue reasignado </span>
  </div>

  <div v-else>
    <div class="message-referral" :class="getClass" v-if="message?.referral">
      <span class="message-referral--text">Respondió a tu anuncio</span>
      <div class="message-referral__body">
        <div class="message-referral__body--content">
          <p class="message-referral__body--content-title">{{ message?.referral?.headline }}</p>
          <!-- <p class="message-referral__body--content-product">product.brand</p> -->
          <a class="text-truncate" :href="message?.referral?.source_url" target="_blank">
            {{ message?.referral?.source_url }}
          </a>
        </div>
      </div>
    </div>
    <div class="message" :class="getClass">
      <div v-if="isMessage" class="message-wrapper" :class="{ right: isAgent, card: isCard }">
        <a-tooltip>
          <template #title>
            {{ tooltipName }}
          </template>

          <div
            v-if="isAgent"
            class="background__profile"
            :class="[
              person?.role?.name === 'Agente' ? 'background__agent' : 'border-blue-3',
              isChatbot ? 'border-chatbot' : '',
            ]"
          >
            <img
              class="image__user"
              :src="person.picture"
              alt="foto perfil"
              v-if="person.picture"
            />

            <img
              v-if="!person.picture"
              class="user__profile"
              :class="person?.role?.name === 'Agente' ? 'user__agente' : ''"
              :src="getProfileImage(person?.icon || iconDefault)"
              alt="perfil"
            />
          </div>
          <div v-else class="background__profile">
            <profile-image :photo="photo" :name="clientName" size="small" />
          </div>
        </a-tooltip>

        <div class="message__body">
          <span v-if="isBlocked" class="message__content--text message--blocked">
            Mensaje retenido por falta de saldo
          </span>

          <span v-else-if="isContact">
            <div class="message__content--contacts" v-if="contacts.length > 0">
              <div class="contacts__info">
                <a-avatar-group :max-count="3" style="transform: scaleX(-1)">
                  <a-avatar
                    v-for="item in contacts.length < 3 ? contacts.length : 3"
                    style="background-color: #f0f0f0; color: #00000073"
                  >
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
                </a-avatar-group>
                <div>
                  <span class="contacts__info--text">
                    {{ contacts[0]?.name?.formatted_name }}
                    {{
                      contacts.length > 1
                        ? `y ${contacts.length - 1} ${
                            contacts.length == 2 ? "contacto" : "contactos"
                          } más`
                        : ""
                    }}
                  </span>
                </div>
              </div>
              <a-divider style="margin: 4px 0" />
              <div class="contacts__footer" :class="{ 'text--right': contacts.length == 1 }">
                <a-button type="link" @click="visibleContacts = true">{{
                  contacts.length == 1 ? "Ver contacto" : "Ver todos"
                }}</a-button>
              </div>
            </div>
          </span>

          <span v-else-if="isLocation">
            <iframe
              title="workspace-maps"
              :src="`https://maps.google.com/maps?q=${latitude}%2C${longitude}&z=14&output=embed`"
              height="220px"
              width="100%"
            />
          </span>

          <div v-else-if="isImage" class="message__content--image" @click="handleImageClick">
            <img :src="message.media_url" alt="multimedia" />
          </div>

          <div v-else-if="isVideo" class="message__content--image" @click="handleImageClick">
            <video>
              <source :src="message.media_url" type="video/mp4" />
            </video>
          </div>

          <div v-else-if="isAudio" class="message__content--audio">
            <audio controls>
              <source :src="message.media_url" type="audio/mp3" />
              Error del navegador
            </audio>
          </div>

          <div v-else-if="isFile" class="message__content--file">
            <FilePreview
              :file="{ file: message.media_url, inputType: 'file' }"
              canDownload
              workspace
              @download="handleDownloadFile"
            />
          </div>

          <div v-else-if="isCard" class="message__content--card">
            <CardMessage
              :payload="message.full_payload"
              :channelId="message.channel.id"
              :responseText="nextMessage.message"
            />
          </div>
          <template v-if="!isBlocked">
            <span v-if="message.message && !isCard" class="message__content--text">
              <span v-if="isBoldText | isItalicText | isStrikethrough">
                <span v-html="formatSpecialText"></span>
              </span>

              <span v-else>{{ message.message }}</span>
            </span>
          </template>
          <div class="message__content--clock">
            <span>{{ date }}</span>
            <!--Status-->
            <template v-if="isAgent && message.status_sent">
              <template v-if="hasError">
                <div class="msg-error flex-center">
                  <div>
                    <a-tooltip placement="bottom">
                      <template #title> {{ errorMsgInfo }} </template>
                      <WarningOutlined />
                    </a-tooltip>
                  </div>
                  <span class="message__status message__status--error"> Error al enviar </span>
                </div>
              </template>

              <img
                v-else
                class="message__status"
                :src="getMessageStatusImage(message.status_sent)"
                alt="Imagen del estado del mensaje"
              />
            </template>
          </div>
          <MessagesActionButton v-if="message.buttons" :buttons="message.buttons.buttons" />
        </div>
        <div
          v-if="[MessageType.Buttons, MessageType.List].includes(message.type_message)"
          class="message__options"
          :class="getClass"
        >
          <ButtonsMessage
            v-if="message.type_message === MessageType.Buttons"
            :payload="message.full_payload"
            :channelId="message.channel.id"
            :responseText="nextMessage.message"
          />

          <ListMessage
            v-if="message.type_message === MessageType.List"
            :payload="message.full_payload"
          />
        </div>
      </div>
    </div>
  </div>
  <AIConclusionTextArea
    v-if="isSummary"
    :conclusion="message.summary"
    type="chat"
    :summaryId="message.id"
    :messages="messages"
    :username="message.user.fullname"
  />

  <a-modal
    v-model:visible="visible"
    :title="message.media_url?.split('/')?.pop()"
    :footer="false"
    centered
    :keyboard="false"
    :mask-closable="false"
    :width="900"
    destroyOnClose
    @cancel="visible = false"
  >
    <ImagePreview v-if="isImage" :media_url="message.media_url" />
    <!-- <img  :src="message.media_url" alt="Imagen" class="image" /> -->

    <video controls class="preview_video" v-if="isVideo">
      <source :src="message.media_url" type="video/mp4" />
    </video>
  </a-modal>

  <a-modal
    v-model:visible="visibleContacts"
    :title="`Detalle de ${contacts.length > 1 ? 'contactos' : 'contacto'}`"
    centered
    :keyboard="false"
    :mask-closable="false"
    :width="520"
    destroyOnClose
    @cancel="visibleContacts = false"
  >
    <CustomScrollbar height="calc(100vh - 300px)">
      <a-card v-for="contact in contacts" class="contact">
        <a-avatar style="background-color: #91caff">
          <template #icon><UserOutlined /></template>
        </a-avatar>
        <div style="margin-left: 10px; padding-top: 5px; width: 100%">
          <div class="contact__name">
            <span>{{ contact?.name?.formatted_name }}</span>
          </div>
          <span class="contact__text" v-for="phone in contact?.phones">
            <PhoneOutlined /> {{ phone.phone }}
          </span>
          <span class="contact__text not_number" v-if="contact?.phones?.length == 0">
            <PhoneOutlined /> Sin número de contacto
          </span>
          <a-divider style="margin: 4px 0" v-if="contact?.emails?.length > 0" />
          <span class="contact__text" v-for="email in contact?.emails">
            <MailOutlined /> {{ email.email }}
          </span>
          <a-divider style="margin: 4px 0" v-if="contact?.addresses?.length > 0" />
          <span class="contact__text" v-for="address in contact?.addresses">
            <EnvironmentOutlined /> {{ address.street }}
          </span>
          <a-divider style="margin: 4px 0" v-if="contact?.urls?.length > 0" />
          <span class="contact__text" v-for="url in contact?.urls">
            <LinkOutlined /> {{ url.url }}
          </span>
        </div>
      </a-card>
    </CustomScrollbar>
    <template #footer>
      <a-button style="width: 150px" @click="visibleContacts = false">Cerrar</a-button>
    </template>
  </a-modal>
</template>

<script>
import dayjs from "dayjs";

import { MessageType, SupportType, ChannelType } from "@/app/shared/utils/enums";
import { ChatStatusPicture } from "@/app/workspace/utils/enums";
import { WhatsappTemplateTypeSpecificButtons } from "@/app/channels/utils/enums.js";
import { dateCalendar } from "@/app/shared/utils/dates";
import {
  WarningOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  LinkOutlined,
} from "@ant-design/icons-vue";

import ProfileImage from "@/app/shared/components/organisms/ProfileImage.vue";
import ButtonsMessage from "@/app/workspace/components/chat/messages/ButtonsMessage.vue";
import MessagesActionButton from "@/app/workspace/components/buttons/MessagesActionButton.vue";
import ListMessage from "@/app/workspace/components/chat/messages/ListMessage.vue";
import CardMessage from "@/app/workspace/components/chat/messages/CardMessage.vue";
import ImagePreview from "@/app/shared/components/organisms/ImagePreview.vue";
import FilePreview from "./input/FilePreview.vue";
import AIConclusionTextArea from "@/app/integrations/components/inputs/AIConclusionTextArea.vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";

export default {
  components: {
    ProfileImage,
    ButtonsMessage,
    ListMessage,
    CardMessage,
    FilePreview,
    WarningOutlined,
    MessagesActionButton,
    AIConclusionTextArea,
    ImagePreview,
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
    EnvironmentOutlined,
    LinkOutlined,
    CustomScrollbar,
  },
  props: {
    message: { required: true, type: Object },
    messages: { required: false, type: Array },
    nextMessage: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      visible: false,
      ChatStatusPicture,
      MessageType,
      WhatsappTemplateTypeSpecificButtons,
      visibleContacts: false,
    };
  },
  computed: {
    client() {
      return this.$store.getters["getWorkspaceClient"];
    },
    photo() {
      return this.client?.data_client[0]?.picture;
    },
    clientName() {
      const name = this.client?.data_client[0]?.first_name;
      const paternalSurname = this.client?.data_client[0]?.paternal_surname;
      const maternalSurname = this.client?.data_client[0]?.maternal_surname;
      if (name && !paternalSurname && !maternalSurname) {
        return name;
      } else if (!name && paternalSurname && !maternalSurname) {
        return paternalSurname;
      } else if (!name && !paternalSurname && maternalSurname) {
        return maternalSurname;
      } else if (name && paternalSurname && !maternalSurname) {
        return name + " " + paternalSurname;
      } else if (name && !paternalSurname && maternalSurname) {
        return name + " " + maternalSurname;
      } else if (!name && paternalSurname && maternalSurname) {
        return paternalSurname + " " + maternalSurname;
      } else if (name && paternalSurname && maternalSurname) {
        return name + " " + paternalSurname + " " + maternalSurname;
      }
      return "No identificado";
    },
    isBlocked() {
      return this.message?.blocked;
    },
    isContact() {
      return this.message.type_message === MessageType.Contacts;
    },
    isLocation() {
      return this.message.type_message === MessageType.Location;
    },
    isText() {
      return this.message.type_message === MessageType.Text;
    },
    isFile() {
      return [MessageType.File, MessageType.TextWithFile].includes(this.message.type_message);
    },
    isImage() {
      return [MessageType.Image, MessageType.Sticker, MessageType.TextWithImage].includes(
        this.message.type_message
      );
    },
    isVideo() {
      return [MessageType.Video, MessageType.TextWithVideo].includes(this.message.type_message);
    },
    isAudio() {
      return this.message.type_message === MessageType.Audio;
    },
    isCard() {
      return this.message.type_message === MessageType.Card;
    },
    isNotification() {
      return this.message.type_message === MessageType.Notification;
    },
    isAgent() {
      return this.message.origin === "agent" || this.message?.created_by_agent;
    },
    isMessage() {
      if (this.message.type === "message") return true;
      return false;
    },
    isSummary() {
      if (this.message.type === "summary") return true;
      return false;
    },
    isPerson() {
      return this.message.origin === "client";
    },
    getClass() {
      return {
        "message--right": this.isAgent,
        "message--left": this.isPerson,
        "message--error": this.isAgent && this.hasError,
      };
    },
    person() {
      return this.message?.person || {};
    },
    isChatbot() {
      return this.message?.type_support === SupportType.Chatbot;
    },
    tooltipName() {
      switch (true) {
        case this.isChatbot:
          return "Chatbot";
        case this.isAgent:
          return this.person?.full_name;
        case !this.isAgent:
          return this.clientName;
        default:
          return "No identificado";
      }
    },
    iconDefault() {
      return this.isChatbot ? "chatbot.svg" : "smile.svg";
    },
    hasError() {
      return this.message.status_sent && ChatStatusPicture[this.message.status_sent] === "failed";
    },
    date() {
      if (!this.message.created_at) return "";
      const diff = dayjs(new Date()).diff(this.message.created_at, "days");
      if (diff <= 1) {
        return dateCalendar(this.message.created_at);
      } else return dayjs(this.message.created_at).format("DD/MM/YY, HH:mm");
    },
    errorMsgInfo() {
      const code = this.message.full_payload.code;
      const message = this.message.full_payload.reason;
      const channelMsg = this.message.channel.id;

      let errorInfo;
      if (channelMsg === ChannelType.Whatsapp) {
        errorInfo = `Error #${code}: ${message}`;
      } else {
        const canalName = Object.keys(ChannelType).find((key) => ChannelType[key] === channelMsg);
        errorInfo = canalName
          ? `Error al enviar en ${canalName}`
          : `Error desconocido en el canal ${channelMsg}`;
      }

      if (errorInfo.length > 77) {
        errorInfo = errorInfo.slice(0, 77) + "...";
      }

      return errorInfo;
    },
    isBoldText() {
      return (
        this.message.channel.id === ChannelType.Whatsapp &&
        this.message.message.includes("*") &&
        this.message.message.match(/\*/gi).length % 2 === 0
      );
    },
    isItalicText() {
      return (
        this.message.channel.id === ChannelType.Whatsapp &&
        this.message.message.includes("_") &&
        this.message.message.match(/_/gi).length % 2 === 0
      );
    },
    isStrikethrough() {
      return (
        this.message.channel.id === ChannelType.Whatsapp &&
        this.message.message.includes("~") &&
        this.message.message.match(/~/gi).length % 2 === 0
      );
    },
    formatSpecialText() {
      const formattedParts = this.message.message.split(" ").map((part) => {
        const current_part = part.replace(/[!,:;?¡]/gi, "");
        if (current_part.startsWith("*") && current_part.endsWith("*")) {
          return `<b>${part.replace(/\*/gi, "")}</b>`;
        } else if (current_part.startsWith("*")) {
          return `<b>${part.replace(/\*/gi, "")}`;
        } else if (current_part.endsWith("*")) {
          return `${part.replace(/\*/gi, "")}</b>`;
        } else if (current_part.startsWith("~") && current_part.endsWith("~")) {
          return `<s>${part.replace(/~/gi, "")}</s>`;
        } else if (current_part.startsWith("~")) {
          return `<s>${part.replace(/~/gi, "")}`;
        } else if (current_part.endsWith("~")) {
          return `${part.replace(/~/gi, "")}</s>`;
        } else if (current_part.startsWith("_") && current_part.endsWith("_")) {
          return `<em>${part.replace(/_/gi, "")}</em>`;
        } else if (current_part.startsWith("_")) {
          return `<em>${part.replace(/_/gi, "")}`;
        } else if (current_part.endsWith("_")) {
          return `${part.replace(/_/gi, "")}</em>`;
        }
        return part;
      });
      return formattedParts.join(" ");
    },
    latitude() {
      return this.message?.full_payload?.payload?.payload?.latitude;
    },
    longitude() {
      return this.message?.full_payload?.payload?.payload?.longitude;
    },
    contacts() {
      return this.message?.full_payload?.payload?.payload?.contacts ?? [];
    },
  },
  methods: {
    getProfileImage(profile) {
      return new URL(`../../../users/assets/profiles/${profile}`, import.meta.url).href;
    },
    getMessageStatusImage(status) {
      return new URL(`../../assets/chat/chat-${ChatStatusPicture[status]}.svg`, import.meta.url)
        .href;
    },
    handleDownloadFile(fileSrc) {
      const link = document.createElement("a");
      link.href = fileSrc;
      link.download = true;
      link.target = "_blank";
      // if (fileSrc.includes("mpeg")) link.target = "_blank";
      link.click();
      URL.revokeObjectURL(link.href);
    },
    handleImageClick() {
      this.visible = true;
    },
  },
};
</script>

<style lang="sass" scoped>
$bg-error: #FFDBDB
$bg-msg-right: #CCE7EF

.message
  // margin-top: 16px
  padding-bottom: 16px
  display: flex
  color: #262626E0
  // align-items: flex-end
  font-size: 12px
  width: 100%
  gap: 8px

  &-wrapper
    display: grid
    grid-template-columns: auto minmax(0, auto)
    max-width: 75%
    gap: 8px

    &.right
      direction: rtl

    &.card
      width: 100%

  &--blocked
    color: $gray-dark-5
    font-style: italic

  &__body
    // background-color: white
    // background-color: $gray-3
    background-color: $bg-msg-right
    padding: 0
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.20)
    border-radius: 8px 8px 8px 0
    direction: ltr
    width: 100%

  &__options
    display: flex
    grid-column: 2/3

  &__content
    &--clock
      display: flex
      padding: 6px 12px
      gap: 4px
      color: $gray-7
      font-size: 10px
    &--text
      display: block
      padding: 6px 12px
      word-break: break-word
      white-space: pre-wrap
      // background-color: $bg-msg-right

    &--contacts
      padding: 8px
      .contacts__info
        display: flex
        align-items: center
        &--text
          display: block
          word-break: break-word
          white-space: pre-wrap
          font-weight: 500
          margin-left: 5px

      .contacts__footer
        text-align: center
        .ant-btn
          padding: 0

    &--image
      padding: 4px
      position: relative
      border-radius: 8px
      overflow: hidden
      // background-color: $bg-msg-right
      border-radius: 8px 8px 0 0

      & img
        height: auto
        display: block
        max-width: 100%
        border-radius: 8px

      & video
        max-width: 100%
        height: auto
        display: block
        border-radius: 8px

      &:hover:after
        content: 'Ver'
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        color: $white
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        border-radius: 8px
        margin: 4px
        background-color: transparentize($gray-9, 0.6)

    &--audio
      padding: 4px

    &--file
      padding: 4px
      display: flex
      align-items: center
      gap: 4px

    &--card
      padding: 12px

  &--right &__content--clock
    display: flex
    justify-content: flex-end
    padding: 6px 12px
    color: $gray-7
    font-size: 10px
    // background-color: $bg-msg-right

  &--right
    flex-direction: row-reverse
    .message
      &-wrapper
        .message
          &__body
            .message
              &__content
                &--clock
                  border-radius: 0 0 0 12px

  &--left
    flex-direction: row
    .message
      &-wrapper
        display: flex
        flex-direction: row
        .message
          &__body
            background-color: white
            .message
              &__content
                &--text
                  // background-color: white
                  border-radius: 12px 12px 12px 0

                &--clock
                  // background-color: white
                  border-radius: 0 0 12px 0

  &--right &__body
    // background-color: $bg-msg-right
    // background-color: transparent
    border-radius: 8px 8px 0 8px
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.20)

  &--right &__image
    margin: 0
    margin-left: 8px

  &__icon
    font-size: 16px
    margin-right: 4px
  &--error &__body
    background-color: $bg-error

.text
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  margin-right: 4px

.image
  display: block
  margin: 0 auto
  max-height: 540px
  max-width: 100%
  height: auto

.preview_video
  display: block
  margin: 0 auto
  max-width: 100%
  max-height: 540px

.italic
  font-style: oblique

.background__profile
  background-color: rgb(230, 247, 255)
  border-radius: 50%
  display: flex
  align-items: center
  place-self: flex-end

.background__agent
  background: #FFF6E2
  border-radius: 50%
  display: flex
  align-items: center
  place-self: flex-end
  border: 1px solid $orange-3

.user__profile
  width: 22px
  height: 22px
  margin: 6px
  filter: invert(62%) sepia(84%) saturate(1521%) hue-rotate(178deg) brightness(103%) contrast(99%)

.user__agente
  width: 22px
  height: 22px
  margin: 6px
  filter: invert(55%) sepia(46%) saturate(1606%) hue-rotate(351deg) brightness(105%) contrast(96%)

.border-chatbot
  border: 1px solid $cyan-3

.border-blue-3
  border: 1px solid #91CAFF

.image__user
  width: 28px
  height: 28px
  border-radius: 50%

.notification
  font-size: 10px
  padding: 8px 16px
  text-align: center
  border-radius: 8px
  background-color: $gray-2
  color: $gray-7

.msg-error
  margin-left: 8px
  display: flex
  gap: 4px
  color: $red-6

  div
    transform: translateY(-1px)

audio
  height: 50px
  max-width: 100%

:deep(.ant-avatar-group >*:not(:first-child))
  margin-inline-start: -16px !important

.text--right
  text-align: right !important

.contact
  margin-bottom: 8px
  &__name
    font-weight: 500
    margin-bottom: 10px
  &__text
    display: block

  .not_number
    color: #00000040

:global(.contact .ant-card-body)
  padding: 10px !important
  display: flex
  width: 100%

.message-referral
  margin-left: 35px
  padding-bottom: 8px
  &--text
    font-size: 10px
    color: #858585
  &__body
    border-left: 2px solid #d7d7d7
    padding-left: 5px
    width: fit-content
    &--content
      background-color: #e7e7e7
      padding: 8px
      border-radius: 4px
      &-title
        margin-bottom: 0
        font-size: 12px
        font-weight: 700
        color: #797979
      &-product
        font-size: 12px
        color: #797979
      a
        font-size: 12px

.text-truncate
  display: block
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 40ch
</style>
