import { createRouter, createWebHistory } from "vue-router";

import { tokenManager } from "@/app/shared/utils/tokenManager";

import app from "@/app/index";
import { validateUserProfileScopes } from "@/router/utils/validateUserProfileScopes";
import httpManager from "@/app/shared/utils/httpManager";

const routes = [
  ...app.routes,
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/app/common/pages/NotFoundPage.vue"),
    meta: {
      requiresAuth: false,
      layout: "footer",
    },
  },
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  console.log(to);

  const existAccessToken = tokenManager.existToken();
  const workspace = localStorage.getItem("workspace");

  if (to.meta.requiresAuth) {
    if (!existAccessToken) {
      if (workspace) return "/login";
      else return "/home";
    }

    if (!to.meta.hasScope) return true;

    httpManager.updateBaseURL(`https://${workspace}.backend.${import.meta.env.VITE_HOSTNAME}`);
    const hasRequiredScope = await validateUserProfileScopes(to.path);

    if (hasRequiredScope) return true;
    else return "/not-found";
  } else {
    return true;
  }
});

router.afterEach((to, from) => {
  console.log('Navegación completada');
  console.log('Nueva ruta:', to.fullPath);
  console.log('Ruta anterior:', from.fullPath);

  const isPageReload = sessionStorage.getItem('isPageReload');
  if (isPageReload === 'true') {
    console.log('Recarga de página');
    sessionStorage.removeItem('isPageReload');
  } else {
    console.log('Cambio de ruta');
    const openRefreshNotification = localStorage.getItem('openRefreshNotification');
    if (openRefreshNotification == "true") {
      localStorage.removeItem('openRefreshNotification')
      sessionStorage.setItem('serviceWorkerUpdated', 'true');
      document.dispatchEvent(new CustomEvent("refreshApp"));
      // window.location.reload();
    }
  }

});