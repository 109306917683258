<template>
  <div class="email-menu">
    <a-button class="btn" @click="showEmailModal" :disabled="!(menuItems.length > 0)">
      Nuevo Correo
    </a-button>
    <a-tabs v-model:activeKey="activeKey" @tabClick="handleShowInbox">
      <a-tab-pane
        v-for="item in tabsMenu"
        :key="item.key"
        :tab="item.tab"
        :force-render="item.key == '2'"
        :disabled="item.key == '2' && !(menuItems.length > 0)"
      >
        <a-menu
          v-if="menuItems.length > 0"
          v-model:selectedKeys="selectedKeys"
          class="menu-items"
          mode="inline"
          :open-keys="openKeys"
          @openChange="onOpenChange"
        >
          <a-sub-menu v-for="item in menuItems" :key="item.id" :title="item.title">
            <a-menu-item v-for="subItem in item.subItems" :key="subItem.id">
              <div
                class="menulist"
                :class="{ active: subItem.id === selectedKeys[0] }"
                @click="handleButtonClick(item, subItem, true)"
              >
                <div>
                  <span>{{ subItem.title }}</span>
                  <a-tooltip>
                    <template #title>
                      Este identificador tiene configurado un correo
                      {{
                        subItem.emailIncoming && subItem.emailIncoming != "" ? " entrante y" : ""
                      }}
                      saliente: <br /><br />
                      <div>
                        <strong>Saliente:</strong>
                        &lt;{{ subItem.email }}&gt;
                      </div>
                      <div v-if="subItem.emailIncoming && subItem.emailIncoming != ''">
                        <strong>Entrante:</strong>
                        &lt;{{ subItem.emailIncoming }}&gt;
                      </div>
                    </template>
                    <InfoCircleOutlined class="icon-info" :key="subItem.id" />
                  </a-tooltip>
                </div>
                <div class="counter-messages">
                  <span :class="{ hasNotification: subItem.unread }" />
                  <span class="count-message">
                    {{ subItem.buttonLabel }}
                  </span>
                </div>
              </div>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
        <!-- <a-menu
          v-if="menuItems.length > 0"
          v-model:selectedKeys="selectedKeys"
          class="menu-items"
          mode="inline"
          :open-keys="openKeys"
          :items="processItems(menuItems)"
          @openChange="onOpenChange"
        ></a-menu> -->
        <div v-else>
          <div class="empty">
            <img src="@/app/emails/assets/mailbox.png" alt="Bandeja de correo" />
            <p>1. No tienes identificadores asignados a tus campañas por el momento</p>
          </div>
        </div>
      </a-tab-pane>
      <!-- <a-tab-pane key="2" tab="Asignados a mí" force-render :disabled="!(menuItems.length > 0)">
        <a-menu
          v-model:selectedKeys="selectedKeys"
          class="menu-items"
          mode="inline"
          :open-keys="openKeys"
          :items="processItems(menuItems)"
          @openChange="onOpenChange"
        ></a-menu>
      </a-tab-pane> -->
    </a-tabs>
    <div class="resolve" v-if="isAssigned">
      <a-button
        type="link"
        @click="showResolveEmail"
        class="resolve-button"
        :class="resolveSelected && 'selected'"
      >
        Resueltos
      </a-button>
    </div>
    <RedactNewEmail v-model:open="open" @updateList="handleUpdateList" />
  </div>
</template>

<script setup lang="ts">
import { ref, h, watch, inject, provide } from "vue";
import EventBus from "@/app/shared/utils/eventBus";
import RedactNewEmail from "../modals/RedactNewEmail.vue";
import { useStore } from "vuex";
import { IMenutem, IMenuSubItem, TypeInbox } from "@/@types/emails";
import { onMounted } from "vue";
import { emailService } from "@/app/emails/services/Email.service";
import { Ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
import { UserData } from "@/@types/global";
import { nextTick } from "vue";
import { SET_SELECTED_CAMPAIGN } from "@/app/emails/store/emails/mutation-types";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { Tooltip } from "ant-design-vue";
import SubItemComponent from "./SubItemComponent";

const store = useStore();

defineProps({
  data: { type: Array, required: true },
});

/*
  #TODO: Mejorar acción SET_THREADS_INBOX, deberia tomar todos los valores necesarios de state y query
  Para poder actualizar el listado de inbox de una manera más rápida desde acciones como resolver, transferir,
  mover, asignar, etc, ya que actualmente el código es muy engorroso y dificulta agregar parametros a la petición.
*/
const emit = defineEmits<{
  // fetchList: [query: { channelCustomerId?: number; assigned: boolean; resolved?: boolean }];
  redirectToList: [];
}>();

const replyEmail = inject("replyEmail") as Ref<boolean>;

const open = ref<boolean>(false);
const activeKey = ref("1");
const resolveSelected = ref<boolean>(false);
const isAssigned = ref<boolean>(false);

const tabsMenu = ref([
  { key: "1", tab: "Bandeja de Entrada" },
  { key: "2", tab: "Asignados a mí" },
]);

const menuItems = computed(() => {
  console.log(store.getters.getMenuItems, 'store.getters.getMenuItems');
  return store.getters.getMenuItems;
});
// const items = inject("menuItems") as Ref<IMenutem[]>;
// const items = ref<IMenutem[]>([]);
// provide("menuItems", items);

const user = computed<UserData>(() => store.getters["getDataUser"]);

const fetchMenuList = async () => {
  // const userId = activeKey.value === "1" ? undefined : user.value.id;

  // menuItems.value = await emailService.getEmailsMenuList(userId); // CAMBIAR Y HACERLO EN EL ACTION CUANDO YA TENGA LOS GETTERS
  await store.dispatch("updateMenuItems", user.value?.id);
  // store.dispatch("updateSelectedMenu", TypeInbox.MisAsignados);
  await store.dispatch("updateOpenKeysMenuItems", [menuItems.value?.[0]?.id]);
  await store.dispatch("updateSelectedKeysMenuItems", [menuItems.value?.[0]?.subItems?.[0].id]);
  // state.value.openKeys = [menuItems.value?.[0]?.id];
  // state.value.selectedKeys = [menuItems.value?.[0]?.subItems?.[0].id];

  await nextTick();
  await handleButtonClick(menuItems.value?.[0], menuItems.value?.[0]?.subItems?.[0]);
};

onMounted(() => {
  fetchMenuList();
});

// watch(activeKey, () => {
//   fetchMenuList();
// });

const processItems = (data: IMenutem[] | undefined) => {
  return data?.map((item) => {
    return {
      key: item?.id,
      label: item?.title,
      children: item?.subItems.map((subItem) => {
        return {
          key: subItem.id,
          label: h(
            "div",
            {
              // class: { active: subItem.isActive },
              class: { menulist: true, active: subItem.id == selectedKeys.value?.[0] },
              onClick: () => handleButtonClick(item, subItem, true),
            },
            [
              h("div", [
                h("span", subItem.title),
                h(
                  Tooltip,
                  { title: tooltipSubItem(subItem) },
                  h(InfoCircleOutlined, { class: "icon-info" })
                ),
              ]),
              h(
                "div",
                {
                  class: { ["counter-messages"]: true },
                },
                [
                  h("span", {
                    class: { hasNotification: subItem.unread },
                  }),
                  h(
                    "span",
                    {
                      class: { ["count-message"]: true },
                    },
                    subItem.buttonLabel
                  ),
                ]
              ),
            ]
          ),
        };
      }),
    };
  });
};

const tooltipSubItem = (subItem: IMenuSubItem) => {
  // const emailOutbound = subItem?.email;
  // const emailIncoming = subItem?.emailIncoming;

  // if (emailIncoming) {
  //   const message = "Este identificador tiene configurado un correo entrante: "
  // }
  return subItem?.email;
};

// const state = ref<{ rootSubmenuKeys: string[]; openKeys: string[]; selectedKeys: IMenuSubItem[] }>({
//   rootSubmenuKeys: menuItems.value.map((item: IMenutem) => item.subMenu),
//   openKeys: [],
//   selectedKeys: [],
// });

// openKeys: number[]; selectedKeys: number[]
const state = ref<{ rootSubmenuKeys: string[] }>({
  rootSubmenuKeys: menuItems.value.map((item: IMenutem) => item.subMenu),
  // openKeys: [],
  // selectedKeys: [],
});

const openKeys = computed(() => store.getters.getOpenKeysMenuItems);
const selectedKeys = computed(() => store.getters.getSelectedKeysMenuItems);

const router = useRouter();
const route = useRoute();

/*
  #TODO: Mejorar la funcionalidad de selección de tab y campaña en inbox email.
  Se debe revisar todos los lados en los que pueda afectar para hacer la mejora.
*/
// watch([() => state.value.selectedKeys, activeKey], ([selectedChannel, currentTab]) => {
//   console.log("watch de selected keys in menu template");
//   if (!selectedChannel[0]) return;

//   const channelCustomerId = selectedChannel[0];

//   router.push({
//     path: "mailbox",
//     query: { channelCustomer: channelCustomerId, assigned: String(currentTab === "2") },
//   });

//   emit("fetchList", { channelCustomerId, assigned: currentTab === "2" });
// });

watch([() => selectedKeys.value], async ([selectedChannel]) => {
  if (!selectedChannel[0]) return;

  const channelCustomerId = selectedChannel[0];

  await router.push({
    path: "mailbox",
    query: { channelCustomer: channelCustomerId, assigned: String(activeKey.value === "2") },
  });

  await store.dispatch("updateThreadsInbox", {
    assignedUser: user.value?.id,
    resetPagination: true,
  });

  // emit("fetchList", { channelCustomerId, assigned: activeKey.value === "2" });
});

const handleUpdateList = async ({ resolved = false } = {}) => {
  // const channelCustomerId = selectedKeys.value?.[0];
  await store.dispatch("updateThreadsInbox", {
    assignedUser: user.value?.id,
    resetPagination: true,
  });
  // emit("fetchList", {
  //   channelCustomerId,
  //   assigned: activeKey.value === "2",
  //   resolved: resolved,
  // });
  if (!resolved) fetchMenuList();
};

const redirectToList = async () => {
  const channelCustomerId = selectedKeys.value?.[0];

  await router.push({
    path: "mailbox",
    query: { channelCustomer: channelCustomerId, assigned: String(activeKey.value === "2") },
  });

  emit("redirectToList");
  // await store.dispatch("removeThreadActual");
};

const onOpenChange = (openKeysParam: number[]) => {
  store.dispatch("updateOpenKeysMenuItems", openKeysParam);
  // const latestOpenKey = openKeysParam.find((key) => openKeys.value.indexOf(key) === -1);
  // if (latestOpenKey) {
  //   if (state.value.rootSubmenuKeys.indexOf(latestOpenKey.toString()!) === -1) {
  //     store.dispatch("updateOpenKeysMenuItems", openKeysParam);
  //     // state.value.openKeys = openKeysParam;
  //   } else {
  //     store.dispatch("updateOpenKeysMenuItems", latestOpenKey ? [latestOpenKey] : []);
  //     // state.value.openKeys = latestOpenKey ? [latestOpenKey] : [];
  //   }
  // }
};

const handleButtonClick = async (item: IMenutem, subItem: IMenuSubItem, fromMenu?: boolean) => {
  if (resolveSelected.value) {
    resolveSelected.value = false;
    await store.dispatch("updateSelectedMenu", TypeInbox.MisAsignados);
  }
  inactiveClassMenu();
  if (subItem) subItem.isActive = !subItem.isActive;
  await store.dispatch("updateSelectedCampaign", {
    channelCustomerId: item?.id,
    ...subItem,
    incomingEmail: subItem?.emailIncoming,
    outgoingEmail: subItem?.email,
    totalEmail: subItem?.buttonLabel,
  });
  await store.dispatch("updateSelectedKeysMenuItems", [subItem.id]);

  if (fromMenu) await redirectToList();
  // state.value.selectedKeys = [subItem.id];
  // store.commit(SET_SELECTED_CAMPAIGN, {
  //   ...subItem,
  //   incomingEmail: subItem.email,
  //   outgoingEmail: subItem.email,
  //   totalEmail: subItem.buttonLabel,
  // });
};

const handleShowInbox = async (event: string) => {
  // console.log(event);
  // console.log(activeKey.value, "active keeey");
  activeKey.value = event;
  EventBus.emit("showInboxTable");
  if (activeKey.value === "2") {
    EventBus.emit("assignMenu");
    isAssigned.value = true;
    await store.dispatch("updateSelectedMenu", TypeInbox.MisAsignados);
    // store.dispatch("updateSelectedCampaign", null);
    inactiveClassMenu();
  }
  if (activeKey.value === "1") {
    isAssigned.value = false;
    await store.dispatch("updateSelectedMenu", TypeInbox.BandejaEntrada);
    // store.dispatch("updateSelectedCampaign", null);
    inactiveClassMenu();
  }
  resolveSelected.value = false;
  await fetchMenuList();
};

const inactiveClassMenu = () => {
  menuItems.value.forEach((item) => {
    item.subItems.forEach((element) => {
      element.isActive = false;
    });
  });
};

const showEmailModal = () => {
  replyEmail.value = false;
  open.value = true;
};

const showResolveEmail = async () => {
  await handleShowInbox("2");
  console.log("showResolveEmail ingresa");
  store.dispatch("updateOpenKeysMenuItems", []);
  store.dispatch("updateSelectedKeysMenuItems", []);
  // state.value.openKeys = [];
  // state.value.selectedKeys = [];
  store.dispatch("updateSelectedMenu", TypeInbox.Resueltos);
  resolveSelected.value = true;
  router.push({ query: { ...route.query, resolved: "true" } });
  await handleUpdateList({ resolved: true });
  emit("redirectToList");
  // await store.dispatch("removeThreadActual");
};

defineExpose({ fetchMenuList, showResolveEmail, resolveSelected, handleButtonClick });
</script>

<style lang="sass" scoped>
.active
  background: #E6F4FF
  color: #1677FF
  font-weight: 500
.email-menu
  padding-top: 10px
  box-shadow: 4px 0 3px -2px #C5C5C5
  width: 20%
  display: flex
  flex-direction: column
  z-index: 2
  .ant-tabs
    flex-grow: 1
  .empty
    width: 248px
    margin: 0 auto
    padding: 10px
    display: flex
    flex-direction: column
    align-items: center
    p
      text-align: center
      color: $gray-8
  .btn
    width: 96%
    padding: 0 16px
    height: 40px
    font-size: $font-medium
    display: block
    margin: 0 auto
    margin-bottom: 10px
  .resolve
    padding: 10px 0
    display: flex
    align-items: end
    border-top: 1px solid $gray-5
    .selected
      padding: 10px
      width: 100%
      background: #E6F4FF
      color: #1677FF
      font-weight: 500
      margin: 0
      border-right: 4px solid #1677FF
      border-radius: 0
      display: flex
      align-items: center
    button
      color: black

.resolve-button
  width: 100%
  text-align: left

.email-menu .ant-menu-title-content .menulist .count-message
  height: 20px
  padding: 11px 18px
  display: flex
  align-items: center
  color: black
  font-weight: 400
  border: 1px solid $gray-6
  background: white
  border-radius: 4px
  width: 35px
  justify-content: center

:deep(.ant-menu-submenu-arrow)
  left: 10px
  right: auto
  margin-right: 10px

:deep(.ant-tabs-nav)
  margin-bottom: 0

.email-menu .icon-info
  margin-left: 10px

.email-menu .counter-messages
  display: flex
  align-items: center
  justify-content: end
</style>

<style>
.email-menu .ant-tabs-content .ant-menu-sub .ant-menu-item-only-child {
  padding: 0 !important;
  border-radius: 0;
  margin: 0;
  width: 100%;
  background-color: white;
}
.email-menu .ant-tabs-content .ant-menu-sub .ant-menu-item-only-child .menulist {
  padding: 10px 15px 10px 35px;
}
.email-menu .ant-tabs-content .ant-menu-submenu-title {
  font-weight: 500;
  margin: 0;
  width: 100%;
  border-radius: 0;
}
.email-menu .ant-tabs-nav-wrap {
  background-color: #f2f5f7;
}
.email-menu .ant-tabs-nav-wrap .ant-tabs-nav-list {
  width: 100%;
  justify-content: space-between;
}
.email-menu .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  padding: 12px;
  margin: 0;
  width: 100%;
  justify-content: center;
}
.email-menu .ant-tabs-tab-active {
  font-weight: 500;
}
.email-menu .ant-menu-title-content .menulist {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email-menu .ant-menu-title-content div {
  width: 100%;
  display: flex;
}

.email-menu .ant-menu-item-selected {
  border-right: 4px solid #1677ff;
}
/* .email-menu .ant-menu-title-content span a {
  height: 20px;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  border: 1px solid gray;
  background: white;
  border-radius: 4px;
  width: 35px;
  justify-content: center;
} */
.email-menu .ant-menu-title-content .menulist .hasNotification {
  content: "";
  /* left: 80% !important; */
  left: auto;
  right: 65px !important;
  top: 50% !important;
  width: 7px;
  height: 7px;
  background-color: red !important;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
