import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todos los usuarios registrados.
 *
 * @return {Promise<Array>} Lista de usuarios.
 */
export default async function (search) {
  const response = await httpManager.get(`/users/active/all/?search=${search}`);
  return response?.data;
}
