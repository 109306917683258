<template>
  <div>
    <div class="container">
      <p>
        Monitorea en tiempo real las llamadas y chats que están siendo atendidos, en espera y
        abandonados de tus colas.
        <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer">Click aquí</a> para conocer
        más.
      </p>
      <viewer-list-controls
        @change:entity="entity = $event"
        @change:campaign="campaign = $event"
        @click:update="handleClickReload"
      />
    </div>

    <custom-scrollbar height="calc(100vh - 232px)" class="container--flat">
      <viewer-list v-if="showList" :list="campaigns" />
    </custom-scrollbar>
  </div>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";

import ViewerListControls from "../list/ViewerListControls.vue";
import ViewerList from "../list/ViewerList.vue";
import { defineComponent, ref, provide } from "vue";

export default defineComponent({
  components: {
    CustomScrollbar,
    ViewerListControls,
    ViewerList,
  },
  setup() {
    const TIME_TO_REFRESH = 5;

    const secondsToUpdate = ref(TIME_TO_REFRESH);

    provide("secondsToUpdate", secondsToUpdate);
    provide("TIME_TO_REFRESH", TIME_TO_REFRESH);

    return { TIME_TO_REFRESH, secondsToUpdate };
  },
  data() {
    const DOCS_URL = `${import.meta.env.VITE_WEB_DOC}/docs/score/supervision/visor-de-colas/`;
    return {
      entity: undefined,
      campaign: 0,
      intervalId: null,
      DOCS_URL: DOCS_URL,
    };
  },
  watch: {
    entity() {
      this.handleSearch();
      this.resetTimer();
    },
    campaign() {
      this.handleSearch();
      this.resetTimer();
    },
    secondsToUpdate(secondsLeft) {
      if (secondsLeft === 0 && this.showList) this.handleReload();
    },
  },
  methods: {
    resetTimer() {
      this.secondsToUpdate = this.TIME_TO_REFRESH;
    },
    startCounter() {
      if (this.intervalId) clearInterval(this.intervalId);

      this.intervalId = setInterval(() => {
        this.secondsToUpdate--;

        if (this.secondsToUpdate === -1) this.secondsToUpdate = this.TIME_TO_REFRESH;
      }, 1000);
    },
    handleSearch() {
      const filters = { entity: this.entity, portfolio: this.campaign };
      this.$store.dispatch("fetchViewerCampaigns", filters);
    },
    handleReload() {
      this.handleSearch();
    },
    handleClickReload() {
      this.handleReload();
      // this.resetTimer();
    },
  },
  computed: {
    campaigns() {
      return this.$store.getters["getViewerCampaigns"];
    },
    showList() {
      if (!this.campaigns) return false;
      return this.campaigns.length > 0;
    },
  },
  created() {
    this.$store.dispatch("resetViewerCampaigns");
    this.$store.dispatch("fetchAllProfiles");
  },
  mounted() {
    this.startCounter();
  },
  unmounted() {
    if (!this.intervalId) return;

    clearInterval(this.intervalId);
  },
});
</script>

<style lang="sass" scoped>
.container
  padding: 12px 20px 0 20px

  &--flat
    padding: 0 20px
</style>
