<template>
  <a-modal
    :open="open"
    width="80%"
    centered
    :title="!previewFull ? imageUrl?.split('/')?.at(-1) : null"
    :footer="null"
    :mask-style="previewFull ? { backgroundColor: 'rgba(0, 0, 0, 0.80)' } : {}"
    :class="previewFull ? 'image_preview_full' : ''"
    @cancel="emit('update:open', false)"
  >
    <img :src="imageUrl" :alt="imageUrl" />
  </a-modal>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{ open: boolean; imageUrl: string; previewFull: boolean }>(), 
  {
    previewFull: false,
  }
);

const emit = defineEmits<{
  "update:open": [visible: boolean];
}>();
</script>

<style scoped lang="sass">
img
  width: 100%


:global(.image_preview_full.ant-modal .ant-modal-content)
  padding: 0 !important
.image_preview_full img
    border-radius: 10px !important
</style>
