import axios from "@/plugins/axios";
import { tokenManager } from "@/app/shared/utils/tokenManager";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";

const setGlobalDownloadState = (isDownloading) =>
  EventBus.emit(WorkspaceEvent.CHANGED_GLOBAL_DOWNLOAD_STATE, isDownloading);

export class ResponseError extends Error {
  constructor(error) {
    super(error.data?.message || "Error desconocido");
    // super(error.data.message);
    this.status = error.status;
    this.data = error.data;
    this.message = error.data.message;
    this.errors = error.data.errors;
    this.details = error.data.detail;
    this.config = error.config;
    this.responseUrl = error.request?.responseURL;
  }

  getErrorMessage(customMessage, showKey = true) {
    const defaultMessage = customMessage || "Ha ocurrido un error";

    if (!this.errors?.length)
      return JSON.stringify(this.data)
        .replace(/[{}[\]"]/g, "")
        .replace(/,/g, " ");

    return showKey
      ? this.errors?.join(", ") || defaultMessage
      : Object.values(this.details).join(", ") || defaultMessage;
  }
  getCleanErrorMessage(error) {
    const defaultMessage = this.getErrorMessage(error);
    return defaultMessage?.replace(/^[^:]+:\s*/, '') || error;
  }
  getError(customMessage) {
    const defaultMessage = customMessage || "Ha ocurrido un error";
    if (!this.errors?.length) return this.data;

    return { error: this.errors?.join(", ") || defaultMessage };
  }
  getErrorDetails() {
    return {
      status: this.status,
      url: this.responseUrl,
      method: this.config.method,
      data: JSON.parse(this.config.data),
      response: this.data,
    }
  }
}
class HttpManager {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  }

  updateBaseURL(baseURL) {
    axios.defaults.baseURL = baseURL;
  }

  getHTTPClient(options) {
    // const accessToken = tokenManager.getToken();

    // if (accessToken) axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    // if (options.isPublic) {
    //   this.removeHeader();
    //   axios.defaults.headers.common["Authorization"] = `Basic ${import.meta.env.VITE_PUBLIC_TOKEN}`;
    // }
    console.log("HTTP MANAGER", options);
    axios.defaults.isPublic = options.isPublic ?? false;

    if (options.authData) {
      this.removeHeader();
      axios.defaults.headers.common["Authorization"] = `bearer ${options.authData}`;
    }

    return axios;
  }

  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${tokenManager.getToken()}`;
  }

  removeHeader() {
    axios.defaults.headers.common = {};
  }

  get(resource, options = {}) {
    return this.getHTTPClient(options)
      .get(resource)
      .catch((error) => {
        const errorResponse = error.response || "Ha ocurrido un error";
        throw new ResponseError(errorResponse);
      });
  }

  getBlob(resource, options = {}) {
    setGlobalDownloadState(true);

    return this.getHTTPClient(options)
      .get(resource, {
        responseType: "blob",
      })
      .then((response) => {
        setGlobalDownloadState(false);
        return response;
      })
      .catch((error) => {
        setGlobalDownloadState(false);
        throw new ResponseError(error.response);
      });
  }

  upload(endpoint, data, options = {}) {
    return this.getHTTPClient(options).post(endpoint, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...options.headers,
      },
    });
  }

  uploadPut(endpoint, data, options = {}) {
    return this.getHTTPClient(options)
      .put(endpoint, data, {
        headers: {
          ...options.headers,
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((error) => {
        throw new ResponseError(error.response);
      });
  }

  post(resource, data, options = {}) {
    if (options.blob) setGlobalDownloadState(true);

    return this.getHTTPClient(options)
      .post(resource, data, { responseType: options.blob ? "blob" : "json" })
      .then((response) => {
        if (options.blob) setGlobalDownloadState(false);
        return response;
      })
      .catch((error) => {
        if (options.blob) setGlobalDownloadState(false);
        throw new ResponseError(error.response);
      });
  }

  put(resource, data, options = {}) {
    return this.getHTTPClient(options)
      .put(resource, data)
      .catch((error) => {
        throw new ResponseError(error.response);
      });
  }

  patch(resource, data, options = {}) {
    return this.getHTTPClient(options)
      .patch(resource, data)
      .catch((error) => {
        throw new ResponseError(error.response);
      });
  }

  delete(resource, data, options = {}) {
    return this.getHTTPClient(options)
      .delete(resource, {
        data,
      })
      .catch((error) => {
        throw new ResponseError(error.response);
      });
  }
}

export default new HttpManager();
