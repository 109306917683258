<template>
  <a-drawer
    :visible="visible"
    title="Agregar teléfono"
    destroy-on-close
    :mask-closable="false"
    placement="right"
    width="480"
    keyboard
    :body-style="{ padding: 0 }"
    @close="handleCloseDrawer"
  >
    <custom-scrollbar class="modal__body" height="calc(100vh - 108px)">
      <a-form
        class="form"
        layout="vertical"
        ref="formRef"
        :model="formState"
        :rules="rules"
        @submit.prevent="handleSubmit"
      >
        <a-form-item label="Número telefónico" name="phone">
          <a-row :gutter="24">
            <a-col span="7">
              <selector-country v-model:value="formState.country" @selected="code_phone = $event" />
            </a-col>
            <a-col span="17">
              <a-input
                v-model:value="formState.phone"
                placeholder="Ingresar número de teléfono"
                @keypress="handleNumberPhone($event)"
                @input="handleChangeInput"
                @paste="handlePaste"
              />
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item label="Tipo de teléfono" name="type_phone">
          <a-select
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            v-model:value="formState.type_phone"
            placeholder="Seleccionar aquí"
            @change="handleChangeInput"
          >
            <a-select-option :value="1"> Fijo </a-select-option>
            <a-select-option :value="2">Celular</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item name="type_origin">
          <template #label>
            <span class="label">
              Origen de subida a la plataforma
              <a-tooltip>
                <template v-slot:title>
                  Medio por el que ingresa el teléfono a la plataforma: Importación o Manual (desde
                  el módulo de workspace o de contactos).
                </template>
                <InfoCircleOutlined />
              </a-tooltip>
            </span>
          </template>
          <a-input
            v-model:value="formState.type_origin"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
          />
        </a-form-item>

        <a-form-item label="Operador telefónico" name="phone_operator">
          <a-input
            v-model:value="formState.phone_operator"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
          />
        </a-form-item>

        <a-form-item name="priority">
          <template #label>
            <span class="label">
              Prioridad para la llamada
              <a-tooltip>
                <template v-slot:title> Orden en que se debería llamar a los teléfonos. </template>
                <InfoCircleOutlined />
              </a-tooltip>
            </span>
          </template>
          <a-input
            v-model:value="formState.priority"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
            v-mask="'##########'"
          />
        </a-form-item>

        <a-form-item label="Observación" name="observación">
          <a-textarea
            v-model:value="formState.observation"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
            :maxlength="commentLimit"
            class="textarea"
          />
          <span class="counter"> {{ formState.observation?.length }}/{{ commentLimit }}</span>
        </a-form-item>
      </a-form>
    </custom-scrollbar>

    <div class="footer">
      <a-button class="footer__button" @click="handleCloseDrawer"> Cancelar </a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="!changeInput">
        Agregar teléfono
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { defineComponent, ref, reactive, toRaw } from "vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import { usePhoneNumberValidation } from "@/app/shared/composables/usePhoneNumberValidation";
import { createVNode } from "vue";
import { Modal } from "ant-design-vue";

export default defineComponent({
  components: {
    CustomScrollbar,
    SelectorCountry,
    InfoCircleOutlined,
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    itemId: {
      type: Number || String,
      required: true,
    },
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      phone: "",
      type_phone: 2,
      type_origin: undefined,
      phone_operator: "",
      priority: undefined,
      observation: "",
      country: 1,
    });
    const rules = {
      phone: [{ required: true, message: "Campo requerido" }],
      type_phone: [{ required: true, message: "Campo requerido" }],
    };

    const { handlePaste } = usePhoneNumberValidation();

    return {
      formState,
      formRef,
      rules,
      handlePaste,
    };
  },
  data() {
    return {
      changeInput: false,
      commentLimit: 200,
      phoneIdReactivate: null,
    };
  },

  methods: {
    handleChangeInput() {
      this.changeInput = true;
    },
    handleNumberPhone(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    handleCloseDrawer() {
      this.$emit("onCloseDrawerEdit");
      this.changeInput = false;
      this.resetFields();
    },
    handleConfirmReactivatePhone() {
      Modal.confirm({
        icon: createVNode(QuestionCircleOutlined),
        title: "Este número ya está asociado al contacto, pero se encuentra inactivo. ¿Deseas reactivarlo?",
        content: "Si no se reactiva el número, este no podrá ser agregado.",
        okText: "Sí, reactivar y agregar",
        cancelText: "No, cancelar",
        centered: true,
        onOk: async () => {
          this.handleEdit();
        }
      });
    },
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          await this.$store.dispatch("addPhonesContact", {
            contactId: this.itemId,
            phones: toRaw({ ...this.formState }),
          });
          this.$emit("update");
          this.handleCloseDrawer();
          this.changeInput = false;
          this.$message.success("Se ha agregado información correctamente");
          this.resetFields();
        } catch (error) {
          if (error.data.code == 'associated_phone_inactivate') {
            this.phoneIdReactivate = error.data.phone_id;
            this.handleConfirmReactivatePhone();
          } else {
            const errorMessage = error.data.error || "Se ha producido un error";

            this.$message.error(errorMessage);
          }
        }
      });
    },
    async handleEdit() {
      try {
        const values = toRaw(this.formState);
        const formData = new FormData();
        if (this.modifiedPhone) formData.append("phone", values.phone);
        if (this.formState.type_phone) formData.append("type_phone", values.type_phone);
        if (this.formState.type_origin) formData.append("type_origin", values.type_origin);
        if (this.formState.phone_operator)
          formData.append("phone_operator", values.phone_operator);
        if (this.formState.priority) formData.append("priority", values.priority);
        if (this.formState.observation) formData.append("observation", values.observation);
        if (this.formState.country) formData.append("country", values.country);
        formData.append("status", '1');
        await this.$store.dispatch("updatePhonesContact", {
          id: this.phoneIdReactivate,
          phones: formData,
        });
        this.$emit("update");
        this.handleCloseDrawer();
        this.changeInput = false;
        this.phoneIdReactivate = null;
        this.$message.success("Se ha reactivado y guardado cambios correctamente");
        this.resetFields();
      } catch (error) {
        this.$message.warning(`Ocurrió un error al registrar al guardar los cambios.`);
      }
    },
    resetFields() {
      this.formState.observation = "";
      this.formState.type_phone = 2;
      this.formState.type_origin = undefined;
      this.formState.phone_operator = "";
      this.formState.priority = undefined;
      this.formState.phone = "";
      this.formState.country = 1;
    },
  },
});
</script>

<style lang="sass" scoped>
.modal
  &__body
    padding: 12px 24px

.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 10px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px

.flex-column
  display: flex
  flex-direction: column

.ant-form-item
  .textarea
    height: 85px

  .counter
    position: absolute
    right: 12px
    bottom: 2px
    color: $gray-6
    font-size: $font-small

:deep(.ant-calendar-picker)
  width: 100%
</style>
