<template>
  <a-modal
    :visible="open"
    width="847px"
    title="Conecta tu correo electrónico"
    centered
    :maskClosable="false"
    :bodyStyle="{ height: successIntegration ? 'calc(100vh - 120px)' : 'inherit' }"
    :footer="null"
    @cancel="handleCloseConfirm"
    class="modal-email"
  >
    <SelectTypeEmailChannel v-if="!formState.type" @selectedType="selectedType"/>
    <template v-else-if="!successIntegration">
      <div class="email">
        <sidebar>
          <div class="content">
            <!-- <SocialIcon :type="'Correo'" /> -->
            <img :src="urlImage(EmailTypeIcon[formState.type])" alt="multichannel" />
            <h3>{{ EmailTypeTitle[formState.type] }}</h3>
            <div class="icon">
              <IconShieldCheck :active="true" />
              <span>Datos protegidos</span>
            </div>
            <p>
              Conecta tu correo electrónico <br/>
              mediante la configuración <strong>SMTP <br/>
              y correo de asistencia </strong> para que <br/>
              gestiones tu comunicación <br/>
              desde {{ ProductName }}.
            </p>
          </div>
          <!-- <p class="sidebar-message">
            💡¿Tienes dudas sobre cómo conectar tu correo? Visita nuestro Centro de ayuda.
          </p> -->
        </sidebar>
        <div class="form">
          <CustomScrollbar class="scrollbar" height="calc(100vh - 180px)" auto>
            <a-form layout="vertical" ref="formRef" :model="formState" :rules="rules" required>
              <div class="">
                <a-form-item name="alias" required>
                  <template #label>
                    Nombre de previsualización (Alias)
                    <a-tooltip>
                      <template #title>
                        <div>Nombre visualizarán tus contactos al recibir un correo.</div>
                      </template>
                      <InfoCircleOutlined style="margin-left: 5px" />
                    </a-tooltip>
                  </template>
                  <a-input v-model:value="formState.alias" placeholder="Escribir nombre" />
                </a-form-item>
                <a-form-item name="email" required>
                  <template #label>
                    Correo electrónico
                    <a-tooltip>
                      <template #title>
                        <div v-if="formState.type == EmailType.Gmail">
                          La dirección de email que utilizarás para enviar correos electrónicos. Es la
                          misma de “SMPT username”.
                        </div>
                        <div v-else>
                          Ingresa el correo electrónico que deseas conectar. 
                          Este es el correo que verán tus contactos.
                        </div>
                      </template>
                      <InfoCircleOutlined style="margin-left: 5px" />
                    </a-tooltip>
                  </template>
                  <a-input
                    v-model:value="formState.email"
                    type="email"
                    :disabled="isEdit ? true : false"
                    :placeholder="EmailTypeExampleMail[formState.type]"
                  />
                </a-form-item>
                <a-form-item name="password" required>
                  <template #label>
                    <div class="label">
                      <div>
                        <span>{{ formState.type == EmailType.Gmail ? 'Contraseña de aplicación' : 'Contraseña del correo'}}</span>  
                        <a-tooltip>
                          <template #title>
                            <div v-if="formState.type == EmailType.Gmail">
                              Contraseña generada desde tu cuenta de Google para conectar aplicaciones. 
                              Debes tener activada la Verificación de 2 pasos antes.
                            </div>
                            <div else>
                              La contraseña asociada a la dirección de correo utilizada.
                            </div>
                          </template>
                          <InfoCircleOutlined style="margin-left: 5px" />
                        </a-tooltip>
                      </div>
                      <a
                        :href="openGeneratePassword"
                        v-if="formState.type == EmailType.Gmail"
                        target="_blank"
                      >
                        <span style="margin-right: 5px;">Generar contraseña en google</span>
                        <ExportOutlined />
                      </a>
                    </div>
                    
                  </template>
                  <div style="display: flex">
                    <a-input-password
                      v-if="!props.isEdit"
                      v-model:value="formState.password"
                      placeholder="Ingresa contraseña aquí"
                      @change="handleChangePassword"
                    />
                    <a-input
                      v-else
                      type="password"
                      v-model:value="formState.password"
                      placeholder="Ingresa contraseña aquí"
                      @change="handleChangePassword"
                    />
                    <a-button
                      style="margin-left: 10px"
                      v-if="formState.type == EmailType.Gmail"
                      @click="showModalTutorial = true"
                    >
                      <QuestionCircleOutlined />
                    </a-button>
                  </div>
                </a-form-item>
                <ModalTutorial 
                  v-model:open="showModalTutorial"
                  :tutorial="tutorialInfo"
                />
                <a-alert type="info" show-icon>
                  <template #message>
                    {{ formState.type == EmailType.Gmail ?
                      'La contraseña de aplicación debe generarse desde la cuenta de Google. Para esto, la verificación de 2 pasos ya debe estar activada.':
                      'Se debe ingresar la contraseña con la que inicias sesión en el correo de tu proveedor.'
                    }}
                  </template>
                </a-alert>
                <div class="server">
                  <h3>Configuración SMTP para enviar correos</h3>
                  <div class="info">
                    ¿No conoces el servidor SMTP?
                    <a
                      class="link-info"
                      :href="openSmtpData()"
                      v-if="formState.type != EmailType.Others"
                      target="_blank"
                    >
                      <span>Encontrar los datos del SMTP</span>
                      <ExportOutlined />
                    </a>
                  </div>
                  <div class="server-smtp">
                    <a-form-item class="server_smtp" name="server_smtp" required>
                      <template #label>
                        Servidor SMTP
                        <a-tooltip>
                          <template #title>
                            <div>
                              Dirección del servidor SMTP que se utilizará para enviar correos desde
                              {{ ProductName }}.
                            </div>
                          </template>
                          <InfoCircleOutlined style="margin-left: 5px" />
                        </a-tooltip>
                      </template>
                      <a-input v-model:value="formState.server_smtp" placeholder="Escribir aquí..." />
                    </a-form-item>

                    <a-form-item class="port_smtp" name="port_smtp" required>
                      <template #label>
                        Puerto
                        <a-tooltip>
                          <template #title>
                            <div>
                              El número de puerto a través del cual se establecerá la conexión con el
                              servidor SMTP.
                            </div>
                          </template>
                          <InfoCircleOutlined style="margin-left: 5px" />
                        </a-tooltip>
                      </template>
                      <a-select
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                        v-model:value="formState.port_smtp"
                      >
                        <a-select-option v-for="option in options" :key="option">
                          {{ option }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="security">
                    <div class="subtitle">
                      <a-tooltip placement="right">
                        <template #title
                          >Establece el protocolo de seguridad para evitar que terceros accedan a tus
                          correos. Esta debe ser compatible con tu servidor. Recuerda validarlo con tu
                          técnico.
                        </template>
                        <span
                          >Conexión de seguridad del servidor <InfoCircleOutlined style="margin-left: 5px"
                        /></span>
                      </a-tooltip>
                    </div>
                    <a-radio-group v-model:value="formState.secure_connection_smtp" name="radioGroup">
                      <a-radio :value="true">
                        Requiere STARTTLS
                      </a-radio>
                      <a-radio :value="false">
                        Requiere SSL/TLS
                      </a-radio>
                    </a-radio-group>
                    <!-- <a-checkbox v-model:checked="formState.secure_connection_smtp"
                      >Este servidor requiere una conexión segura (TLS/SSL)</a-checkbox
                    > -->
                  </div>
                </div>
                <div class="server">
                  <div class="inbound-title">
                    <h3>
                      Configuración de reenvío automático a {{ CompanyName }} CC
                      <a-switch
                        v-model:checked="formState.inbound_activate"
                        :disabled="data?.data_parameters?.inbound_activate === true"
                        size="small"
                      />
                    </h3>
                  </div>
                  <div class="info">
                    Crea un correo de asistencia y añádelo como correo de reenvío en la cuenta del proveedor 
                    para reenviar los correos a {{ CompanyName }} CC.
                    Esta configuración no podrá desactivarse aquí después de guardarla.
                  </div>

                  <ol class="config_info">
                    <li>
                      Armar correo de asistencia y presionar Conectar correo. 
                      No se podrá editar este correo después.
                      <p>
                        <a-form-item name="imap_user" style="margin-top: 8px">
                          <template #label> Correo electrónico de asistencia </template>
                          <div style="display: flex" class="inbound-title">
                            <a-input
                              v-model:value="formState.imap_user"
                              type="imap_user"
                              :disabled="
                                !formState.inbound_activate ||
                                !props.data?.data_parameters?.imap_user?.length == 0
                              "
                              placeholder="Ingresar nombre del correo entrante"
                            />
                            <span style="margin-left: 10px" v-if="imapUserDomain"
                              >@{{ imapUserDomain }}</span
                            >
                          </div>
                          <span class="help_message">{{ imapUser }}</span>
                        </a-form-item>
                      </p>
                    </li>
                    <li>
                      Una vez conectado, añadir correo de asistencia como correo de reenvío. <br/>
                      <p>
                        Si no realizas este paso, no se configurará el reenvío automático. 
                        Revisa cómo hacerlo según el proveedor.
                        <a
                          class="link-info"
                          v-if="formState.type != EmailType.Others"
                          :href="openForwardMail()"
                          target="_blank"
                        >
                          <span>Leer cómo añadir correos de reenvío</span>
                          <ExportOutlined />
                        </a>
                      </p>
                    </li>
                    <li>
                      {{ 
                        formState.type == EmailType.Gmail ?
                        'Verificar el correo enviado al buzón de correo de Beex CC.' : 
                        'Realizar prueba de reenvío después de añadir el correo.'
                      }}
                      <br/>
                      <p>
                        {{ 
                          formState.type == EmailType.Gmail ?
                          'Si no verificas el correo, no se reenviarán los correos a BeexCC.' :
                          `Puedes enviarte un correo de prueba desde la cuenta del proveedor y verificar 
                          que se reenvíe al buzón de correos de Beex CC.`
                        }}
                      </p>
                    </li>
                  </ol>

                  

                  <!-- <div>
                    ¿Cómo vincular tu correo del proveedor para recibir correos en {{ ProductName }}?
                    <br />
                    <a>Haz clic aquí.</a>
                  </div> -->
                  <!-- <div class="server-smtp">
                    <a-form-item name="server_smtp" required>
                      <template #label>
                        Servidor SMTP
                        <a-tooltip>
                          <template #title>
                            <div>
                              Dirección del servidor SMTP que se utilizará para enviar correos desde
                              Score.
                            </div>
                          </template>
                          <InfoCircleOutlined style="margin-left: 5px" />
                        </a-tooltip>
                      </template>
                      <a-input v-model:value="formState.server_smtp" placeholder="Escribir aquí..." />
                    </a-form-item>

                    <a-form-item name="port_smtp" required>
                      <template #label>
                        Puerto
                        <a-tooltip>
                          <template #title>
                            <div>
                              El número de puerto a través del cual se establecerá la conexión con el
                              servidor SMTP.
                            </div>
                          </template>
                          <InfoCircleOutlined style="margin-left: 5px" />
                        </a-tooltip>
                      </template>
                      <a-select :getPopupContainer="triggerNode => triggerNode.parentNode" v-model:value="formState.port_smtp">
                        <a-select-option v-for="option in options" :key="option">
                          {{ option }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="security">
                    <a-tooltip placement="right">
                      <template #title
                        >Establece el protocolo de seguridad para evitar que terceros accedan a tus
                        correos. Esta debe ser compatible con tu servidor. Recuerda validarlo con tu
                        técnico.
                      </template>
                      <span
                        >Conexión de seguridad <InfoCircleOutlined style="margin-left: 5px"
                      /></span>
                    </a-tooltip>
                    <a-checkbox v-model:checked="formState.secure_connection_smtp"
                      >Este servidor requiere una conexión segura (TLS/SSL)</a-checkbox
                    >
                  </div> -->
                </div>
              </div>
            </a-form>
          </CustomScrollbar>
        </div>
      </div>
    </template>
    <footer v-if="formState.type">
      <a-button @click="handleCancel">Cancelar</a-button>
      <a-tooltip>
        <template #title v-if="isDisabled">
          Se deben completar todos los campos solicitados para poder conectar un correo.
        </template>
        <a-button
          type="primary"
          @click="handleConnection"
          :disabled="isDisabled"
          :loading="loading"
          >{{ primaryText }}</a-button
        >
      </a-tooltip>
    </footer>
    <div v-if="isIntegrationCompleted">
      <CompleteIntegration description="Tu nuevo correo electrónico se encuentra listo" />
    </div>
  </a-modal>
</template>
<script setup lang="ts">
import { reactive, ref, toRaw, computed, createVNode } from "vue";
import { FormEmailInfo } from "@/@types/emails/forms";
import CompleteIntegration from "@/app/channels/components/templates/SocialChannels/CompleteIntegration.vue";
import { InfoCircleOutlined, ExportOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import IconShieldCheck from "@/app/common/components/atoms/IconShieldCheck.vue";
import { useStore } from "vuex";
import { Modal, message } from "ant-design-vue";
import { CompanyName, ProductName } from "@/app/shared/utils/constants";
import SelectTypeEmailChannel from "@/app/channels/components/modals/email/SelectTypeEmailChannel.vue";
import ModalTutorial from "@/app/channels/components/modals/email/ModalTutorial.vue";
import { EmailType, EmailTypeIcon, EmailTypeTitle, EmailTypeExampleMail } from "@/app/channels/utils/enums";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";

const props = defineProps<{
  open: boolean;
  isEdit: boolean;
  data: FormEmailInfo;
}>();

const loading = ref(false);
// const options = ["587", "465"];
const isIntegrationCompleted = ref(false);
const formRef = ref();
const successIntegration = ref(false);
const store = useStore();
const isPasswordEdited = ref(false);
const showModalTutorial = ref(false);

const formState = reactive({
  type: props.data ? (props.data?.data_parameters.type_email || EmailType.Others) : null,
  alias: props.data ? props.data?.data_parameters.alias : "",
  email: props.data ? props.data?.data_parameters.email : "",
  password: props.data ? props.data?.data_parameters.password : "",
  server_smtp: props.data ? props.data?.data_parameters.server_smtp : "",
  port_smtp: props.data ? props.data?.data_parameters.port_smtp : "",
  secure_connection_smtp: props.data ? props.data?.data_parameters.secure_connection_smtp : true,
  inbound_activate: props.data ? props.data?.data_parameters.inbound_activate : false,
  imap_user: props.data ? props.data?.data_parameters.imap_user?.split("@")?.[0] : "",
});

const tutorialInfo = {
  multimediaType: 'video',
  title: "Cómo generar una contraseña de aplicación",
  steps: [
    "Ingresa un nombre para identificar la contraseña.",
    "Dale click a Crear.",
    "Copia la contraseña generada y pégala en el campo Contraseña de aplicación.",
  ],
  messageInfo: `Después de cerrar la vista de la contraseña generada, no la podrás visualizar de nuevo 
        desde tu cuenta de Google. Puedes generar nuevas contraseñas y usarlas.`,
  linkExternalText: 'Conocer más sobre contraseñas de aplicaciones',
  linkExternal: 'https://support.google.com/mail/answer/185833?hl=es-419#zippy=%2Colvidaste-tu-contrase%C3%B1a-de-la-aplicaci%C3%B3n',
  urlMedia: "https://storage.googleapis.com/scorecloud_bucket_dev/upload/Tutorial%20-%20Generar%20contrase%C3%B1as.mp4.mp4",
};

const options = computed(() => {
  if (formState.type == EmailType.Gmail) return ["587", "465"];
  else return ["587", "465", "25"];
});

const imapUserDomain = computed(() => {
  const subdomain = localStorage.getItem("workspace");
  return subdomain + "." + import.meta.env.VITE_HOSTNAME;
});

const validateDomain = (rule: any, value: string, callback: (error?: Error) => void) => {
  const domainRegex = /^[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
  if (value && !domainRegex.test(value)) {
    callback(new Error("Por favor ingrese un dominio válido"));
  } else {
    callback();
  }
};

const rules = computed(() => {
  return {
    alias: [{ required: true, message: "Ingresar un nombre o alías válidos" }],
    email: [{ required: true, message: "Ingresar un email válido" }],
    password: [{ required: true, message: "Ingresar un password válido" }],
    server_smtp: [
      { required: true, message: "Por favor ingrese el servidor SMTP" },
      { validator: validateDomain, trigger: "blur" },
    ],
    port_smtp: [{ required: true, message: "Ingresar un puerto smtp válido" }],
    secure_connection_smtp: [{ required: true, message: "Campo requerido" }],
    imap_user: formState.inbound_activate
      ? [{ required: true, message: "Ingresar un correo válido" }]
      : [],
  };
});

const emit = defineEmits(["close", "reloadTable"]);

const handleClose = () => {
  emit("close");
};

const handleCloseConfirm = () => {
  if (formState.type) {
    Modal.confirm({
      title: "¿Deseas descartar los cambios realizados?",
      content: "Recuerda que si no guardas los cambios registrados, estos se perderán.",
      icon: createVNode(QuestionCircleOutlined),
      onOk: () => handleClose(),
      centered: true,
      okText: "Sí, descartar cambios",
      cancelText: "No, seguir editando",
    });
  } else {
    handleClose();
  }
}

const handleCancel = () => {
  if (formState.type && !props.isEdit) {
    formState.type = null;
    formState.server_smtp = "";
    formState.port_smtp = "";
  }
  else handleCloseConfirm();
};

const primaryText = computed(() => {
  console.log(props.data, "props.data!!!!");
  if (props.isEdit) {
    return "Guardar Cambios";
  }
  return "Conectar correo";
});

const handleConnection = async () => {
  try {
    await formRef.value.validateFields();
    handleCreateNewChannel();
  } catch (error) {
    console.error("Validation failed:", error);
  }
};

const isDisabled = computed(() => {
  return (
    !formState.alias ||
    !formState.email ||
    !formState.password ||
    !formState.server_smtp ||
    !formState.port_smtp ||
    (formState.inbound_activate === true && !formState.imap_user)
  );
});

const handleChangePassword = () => {
  isPasswordEdited.value = true;
};

const imapUser = computed(() => {
  return formState.imap_user && imapUserDomain.value
    ? formState.imap_user + "@" + imapUserDomain.value
    : "";
});

const handleCreateNewChannel = async () => {
  try {
    loading.value = true;
    const data = {
      channel: 9,
      data_parameters: {
        ...toRaw({
          ...formState,
          imap_user: imapUser.value,
          type_email: formState.type
        }),
      },
      provider: 4,
    };

    if (props.isEdit) {
      console.log(props.data, "DATA INFOOO");
      if (!isPasswordEdited.value) {
        delete data.data_parameters.password;
      }
      const body = {
        id: props.data.id,
        data_parameters: data.data_parameters,
      };
      await store.dispatch("updateChannelCreate", body); 
      handleClose();
      message.success("Se actualizaron tus datos correctamente");
    } else {
      await store.dispatch("createNewChannel", data); 
      isIntegrationCompleted.value = true;
      message.success("Se ha integrado tu correo correctamente");
    }
    loading.value = false;
    emit("reloadTable");
  } catch (error) {
    const errorMessage =
      error?.getError().error ??
      (props.isEdit
        ? "No se pudo actualizar tus datos. Las credenciales ingresadas son incorrectas."
        : "No se pudo validar las credenciales SMTP. Por favor, verifica tus datos.");

    message.error(errorMessage);
    loading.value = false;
  }
};

const selectedType = (value: number) => {
  formState.type = value;
  if (!formState.server_smtp) {
    formState.server_smtp = formState.type == EmailType.Gmail ? "smtp.gmail.com" : 
      (formState.type == EmailType.Outlook ? "smtp-mail.outlook.com" : formState.server_smtp);
  }
  if (!formState.port_smtp) {
    formState.port_smtp = '587';
  }
};

const urlImage = (image: string) => {
  return new URL(`../../image/${image}`, import.meta.url).href;
};

const openGeneratePassword = "https://myaccount.google.com/apppasswords?rapt=AEjHL4Pb0ThLe3yrrN5rtKaRsUoZ4vUZB0Z-q2575tjzOD-SAiDbJ_yore7kyRn1puAqprrCTKwUmLbWHvZSFnPgIZNbZAYiC0S_kpzLsQJ4cWzi93BTZ8U";

const openSmtpData = () => {
  if (formState.type == EmailType.Gmail) {
    return "https://support.google.com/mail/answer/7104828?hl=es-419&visit_id=01736523057277-8800002518816144994&rd=1";
  } else if (formState.type == EmailType.Outlook) {
    return "https://support.microsoft.com/es-es/office/configuraci%C3%B3n-pop-imap-y-smtp-para-outlook-com-d088b986-291d-42b8-9564-9c414e2aa040";
  } else if (formState.type == EmailType.Webmail) {
    return "https://support.hostinger.com/es/articles/4312781-como-obtener-los-detalles-de-configuracion-de-email-en-cpanel";
  }
  return "";
};

const openForwardMail = () => {
  if (formState.type == EmailType.Gmail) {
    return "https://support.google.com/mail/answer/10957?hl=es";
  } else if (formState.type == EmailType.Outlook) {
    return "https://support.microsoft.com/es-es/topic/activar-el-reenv%C3%ADo-autom%C3%A1tico-en-outlook-7f2670a1-7fff-4475-8a3c-5822d63b0c8e"
  } else if (formState.type == EmailType.Webmail) {
    return "https://webmail.cloud-mail.es/appsuite/help/l10n/es_ES/ox.appsuite.user.sect.email.send.autoforward.html";
  }
};
</script>
<style lang="sass" scoped>
.email
  display: flex
  sidebar
    width: 32.5%
    text-align: center
    padding: 10px 10px 10px 0
    border-right: 1px solid #00000026
    display: flex
    flex-direction: column
    align-items: center
    .content
      flex-grow: 1
      .icon
        color: #389E0D
        display: flex
        align-items: center
        justify-content: center
        padding-bottom: 8px
    .message
      flex-grow: 0
  .form
    width: 67.5%
    padding: 10px 20px
    padding-right: 10px
    .server
      margin-top: 20px
      h3
        margin-bottom: 0
      &-smtp,
      &-imap
        display: flex
        gap: 15px
        p
          margin-bottom: 10px
      .inbound-title
        display: flex
        align-items: center
        > *
          margin-right: 6px
    .server_smtp
      width: calc(100% - 120px)
    .port_smtp
      width: 120px
.modal-email
  .ant-form-item
    margin-bottom: 10px
.security
  margin-bottom: 8px
footer
  display: flex
  justify-content: flex-end
  gap: 10px

.help_message
  color: rgba(0, 0, 0, 0.45)

:deep(label[for="form_item_password"])
  display: flex

:deep(.ant-form-item-label)
  .label
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

.link-info
  padding-left: 0
  span
    text-decoration: underline
    padding-right: 5px

.info
  margin-bottom: 8px
  color: #000000A6

.subtitle
  font-weight: 500
  margin-bottom: 3px
  margin-top: 2px

ol.config_info
  font-weight: 500
  padding-left: 20px
  p
    font-weight: 400
    color: #000000A6

.scrollbar
  padding-right: 10px
</style>
