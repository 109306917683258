<template>
  <section
    class="workspace__panel workspace__panel--bordered workspace__second-section"
    style="overflow: hidden"
    id="workspace-second-section"
  >
    <div v-if="!$route.query.ticket" class="information__conversation">
      <img src="@/app/workspace/assets/workspace2.png" alt="workspace" />
      <p>2. Selecciona un ticket para mostrarte la conversación</p>
    </div>

    <template v-else>
      <template v-if="isTicketLoading">
        <div class="skeleton">
          <a-skeleton-button active block />
          <a-skeleton-button active block />
          <a-skeleton-button active block />
          <a-skeleton-button active block />
        </div>
        <a-skeleton active />
        <a-skeleton active />
      </template>

      <template v-else>
        <WorkspaceChannelsHeader :isChat="isChat" />
        <WorkspaceChatSection :isChat="isChat" v-if="isChat" @update:ticket="$emit('update:ticket')" />
        <WorkspaceCallSection
          v-if="isVoice"
          :ticket="ticket"
          @update:ticket="$emit('update:ticket')"
        />
      </template>
    </template>
  </section>
</template>

<script>
import { inject, ref, provide, watch, computed } from "vue";

import { ChannelType } from "@/app/shared/utils/enums";

import WorkspaceChannelsHeader from "@/app/workspace/components/headers/WorkspaceChannelsHeader.vue";
import WorkspaceChatSection from "@/app/workspace/components/sections/WorkspaceChatSection.vue";
import WorkspaceCallSection from "@/app/workspace/components/sections/WorkspaceCallSection.vue";
import { fetchWorkspaceTypifications } from "@/app/workspace/services";
import { useRoute } from "vue-router";

export default {
  components: {
    WorkspaceChannelsHeader,
    WorkspaceChatSection,
    WorkspaceCallSection,
  },
  created() {
    if (!this.hasClient) {
      this.$store.commit("SET_CLIENT", null);
    }
  },
  setup() {
    const route = useRoute();
    const ticket = inject("selectedTicket");
    const channel = inject("selectedChannel");

    const isTicketLoading = inject("isTicketLoading");
    const hasTypification = ref(false);
    provide('hasTypification', hasTypification);

    const validateHasTypification = async () => {
      const typifications = await fetchWorkspaceTypifications(route.query.ticket);
      if (typifications?.groups?.length > 0) {
        hasTypification.value = true;
      } else {
        hasTypification.value = false;
      }
    }

    watch(
      channel,
      async (value) => {
        console.log('watch channel', value);
        if (value && route.query.client) {
          await validateHasTypification();
        }
      },
      { immediate: true }
    );

    const client = computed(() => route.query?.client);
    const ticketId = computed(() => route.query?.ticket);

    watch(
      ticketId,
      async (newTicket, oldTicket) => {
        if (newTicket && newTicket != oldTicket) {
          await validateHasTypification();
        }
      },
      { immediate: true }
    );

    watch(
      [client, ticketId], // Observamos ambos valores
      async ([newClient, newTicket], [oldClient, oldTicket]) => {
        if (newTicket == oldTicket && newClient != oldClient) { // al asignar un contacto a un ticket
          await validateHasTypification();
        }
      },
      { immediate: true }
    );

    return { ticket, channel, isTicketLoading };
  },
  computed: {
    hasClient() {
      return !!this.$route.query.client;
    },
    isVoice() {
      const voiceChannelsId = [ChannelType.Voice, ChannelType.VoiceInbound];

      const channel = this.channel?.channel?.id;
      if (!channel) return voiceChannelsId.includes(this.ticket?.channel?.id);
      return voiceChannelsId.includes(channel);
    },
    isChat() {
      const chatChannelsId = [
        ChannelType.Inbound,
        ChannelType.Instagram,
        ChannelType.Messenger,
        ChannelType.Sms,
        ChannelType.Whatsapp,
        ChannelType.Email,
      ];

      const channel = this.channel?.channel?.id;
      console.log(this.channel?.channel, "channel");
      console.log(chatChannelsId);
      if (!channel) return chatChannelsId.includes(this.ticket?.channel?.id);
      return chatChannelsId.includes(channel);
    },
  },
};
</script>

<style lang="sass" scoped>
.workspace
  &__panel
    background-color: white

    &--bordered
      border-left: $border-medium
      border-right: $border-medium

  &__second-section
    display: flex
    flex-direction: column
    background-color: #F9FAFF

.information__conversation
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 10px

  p
    text-align: center

.skeleton
  display: flex
  flex-direction: column
  gap: 8px
</style>
