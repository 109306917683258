<template>
  <a-modal
    :open="open"
    centered
    :title="!fromTypification ? 'Transferir a una cola' : 'Tipificar y transferir a una cola'"
    @cancel="handleClose"
  >
    <div class="content">
      <p>Selecciona la cola a la que deseas transferir el ticket.</p>

      <div class="filters">
        <a-form layout="vertical">
          <p style="margin-bottom: 0" v-if="portfolios?.length < 2">
            <!-- <strong>Campaña:</strong> {{ portfolios.at(0).name }} -->
            <strong>Campaña:</strong> {{ portfolio?.name }}
          </p>
          <a-form-item v-else label="Selecciona la campaña:">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="portfolioId"
              placeholder="Seleccionar campaña"
            >
              <a-select-option v-for="portfolio in portfolios" :key="portfolio.id">
                {{ portfolio.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <p style="color: rgba(0, 0, 0, 0.45)">
            <strong>Empresa:</strong>
            <!-- {{
              portfolios.length === 1
                ? portfolios.at(0)?.entity?.name || "--"
                : portfolios.find((p) => p.id === portfolioId)?.entity?.name || "--"
            }} -->
            {{
              portfolios.length < 2
                ? entity?.name || "--"
                : portfolios.find((p) => p.id === portfolioId)?.entity?.name || "--"
            }}
          </p>

          <a-form-item label="Selecciona la cola:">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="queueId"
              placeholder="Seleccionar cola"
            >
              <a-select-option
                v-for="queue in portfolios.length === 1
                  ? queues
                  : queues.filter((q) => q.portfolios.at(0)?.id === portfolioId)"
                :key="queue.id"
              >
                {{ queue.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <template #footer>
      <footer class="footer">
        <a-button v-if="fromTypification" key="back" style="width: 40%" @click="handleBack">
          Volver
        </a-button>
        <a-button
          type="primary"
          :style="!fromTypification ? 'width: 80%' : 'width: 40%'"
          :disabled="!queueId"
          @click="handleTransfer"
        >
          Transferir
        </a-button>
      </footer>
    </template>
  </a-modal>
</template>

<script setup>
import { message } from "ant-design-vue";
import { computed, h, ref, watch, createVNode } from "vue";
import { useStore } from "vuex";

import { Modal } from "ant-design-vue";

import { QuestionCircleOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  open: { type: Boolean, default: false },
  queues: { type: Array, default: () => [] },
  ticketId: { type: Number },
  ticketIdList: { type: Array, default: () => [] },
  fromTypification: { type: Boolean, default: false },
  defaultSelected: { type: Number, default: null },
  origin: { type: Number, default: 1 }, // 1: llamadas o workspace | 2: gestor de tickets
  inCall: { type: Boolean, default: false },
  isAsync: { type: Boolean, default: false },
  portfolio: { type: Object, default: false },
  entity: { type: Object, default: false },
});

const emit = defineEmits(["update:open", "update", "handleBack", "transferCall", "typificationTransfer"]);

const store = useStore();

const portfolioId = ref();
const queueId = ref();

const portfolios = computed(() => {
  const portfoliosList = props.queues.flatMap((q) => q.portfolios);
  const uniquePortfolios = [...new Map(portfoliosList.map((item) => [item.id, item])).values()];

  return uniquePortfolios;
});

const handleClose = () => emit("update:open", false);

const handleBack = () => {
  emit("update:open", false);
  emit("handleBack", { key: "queues", idSelected: queueId.value });
};

const handleTransfer = async () => {
  Modal.confirm({
    title: props.fromTypification
      ? `¿Estás seguro(a) de transferir el ticket y guardar la tipificación de este cliente?`
      : `¿Estás seguro(a) de transferir el ticket?`,
    content: props.fromTypification
      ? "Recuerda que luego podras hacer click en resolver el ticket para poder cerrarlo."
      : "",
    centered: true,
    width: 416,
    icon: createVNode(QuestionCircleOutlined),
    onOk: async () => {
      try {
        if (props.fromTypification) {
          await new Promise((resolve) => {
            emit("typificationTransfer", resolve);
          });
        }

        const { total_tickets, tickets_transfered } = await store.dispatch(
          "transferTicketToQueue",
          {
            ticketIdList: props.ticketId ? [props.ticketId] : props.ticketIdList,
            queueId: queueId.value,
            origin: props.origin,
            isCall: props.inCall,
            isAsync: props.isAsync,
          }
        );

        emit("update", "queues");

        if (!props.isAsync) {
          const successMessage =
            total_tickets === 1
              ? "Se realizó la transferencia correctamente"
              : h("span", [
                  "Se han transferido ",
                  h("strong", `${tickets_transfered} de ${total_tickets}`),
                  ` tickets con éxito. ${
                    total_tickets - tickets_transfered
                  } tickets cuentan con llamada activa. `,
                ]);

          if (tickets_transfered >= 1) {
            message.success(successMessage);
            emit("transferCall", { itemId: queueId.value, type: "queue" });
          } else
            message.error(
              h("span", [
                "No se ha transferido ",
                h("strong", "ningún"),
                " ticket con éxito. Todos los tickets cuentan con llamada activa. ",
              ])
            );
        }

        handleClose();
      } catch (error) {
        if (error?.status === 400)
          message.error(
            h("span", [
              "No se ha transferido este ticket ",
              h("strong", "porque cuenta con una llamada activa."),
            ])
          );
        console.error(error);
      }
    },
    okText: "Transferir",
    cancelText: "Cancelar",
  });
};

watch(props.defaultSelected, (value) => {
  queueId.value = value;
});
</script>

<style scoped lang="sass">
.footer
  width: 100%
  text-align: center

.filters
  margin: 20px 0 40px

.ant-form-item
  margin-bottom: 0
</style>
