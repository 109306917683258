/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * =================================================================
 * Copyright 2022, Securitec Perú S.A.C
 * All Rights Reserved.
 *
 *
 */

import jssip, { UA, WebSocketInterface, debug } from "jssip";
import { RTCSession } from "jssip/lib/RTCSession";

import { JssipReactive } from "./reactive";
import { fetchTicketByPhone, validateBlacklistOutboundCall } from "@/app/workspace/services";
import JssipStorage from "@/packages/vue-jssip/jssip/storage";
import { Config } from "./config";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import { CallMode, CallStatus } from "./phone";
import type { PhoneData } from "./types";
import { SupervisedUser } from "@/@types/supervision";
import EventBus from "@/app/shared/utils/eventBus";
import { fetchWhatsappBalance } from "@/app/settings/services";
import { BillingType } from "@/@types/settings/billing";
import { fetchPortfolioById } from "@/app/entities/services";
import { Portfolio } from "@/@types/global";

export interface JssipManager {
  server: string; //
  serverBaseUrl: string;
  reactive: JssipReactive;
  password: string;
  socket: WebSocketInterface;
  callOptions: any;
  UA: UA;
  isInCall: boolean;
}

export interface DataCallSession {
  ticket?: Ticket;
  channel?: string;
  interaction?: number | null;
  client?: number | null;
  time_acw?: string | number;
  strategyPhone?: string;
  name?: string;
  photo?: string;
  number?: string;
  type_default_management: undefined | number;
  asteriskId?: undefined | string;
  channel_customer?: null | number;
}
export interface Ticket {
  id: number;
  client: any;
  channel: string;
  channel_person: string;
  time_acw: string;
  portfolio: { id: number; dialing_plan?: { id: number } };
}

interface ReferOptionsBody {
  extraHeaders: string[];
}

/**
 * Manager of WebRTC
 * @params Config object     containing the configuration parameters
 * @return JssipManager object containing the methods to use the webrtc
 */

export class JssipManager {
  constructor(options: Config) {
    this.reactive = new JssipReactive();
    this.serverBaseUrl = options.server;
    this.password = options.password;
    this.socket = new jssip.WebSocketInterface(options.socket);
    /**
     * This attribute is not longer used
     * */
    this.isInCall = false;
    this.callOptions = {
      sessionTimersExpires: 7200,
      extraHeaders: [],
      rtcOfferConstraints: {
        offerToReceiveVideo: true,
        offerToReceiveAudio: true,
      },
      mediaConstraints: {
        video: false,
        audio: true,
      },
    };
  }

  get phone() {
    return this.reactive.app as PhoneData;
  }

  get isInPhoneCall() {
    const inCallStatus = [
      CallStatus.Connecting,
      CallStatus.Ringing,
      CallStatus.InCall,
      CallStatus.Transfered,
    ];

    return inCallStatus?.includes(this.phone?.status);
  }

  getCallOptions(isScreenShareSession = false) {
    // return this.callOptions;

    return isScreenShareSession
      ? {
          ...this.callOptions,
          mediaConstraints: {
            video: false,
            audio: false,
          },
        }
      : this.callOptions;
  }

  resetWebphoneContent() {
    this.reactive.setReady(true);
    this.reactive.setReceiver({ name: null, photo: null, number: null });
  }

  reset({ keepStrategyPhone = false } = {}) {
    this.reactive.setReady(true);
    this.clearData({ keepStrategyPhone });
  }

  /**
   * Start the webrtc conection with the Asterisk server
   * @param string annex - annex of the user  this parameter is mandatory
   *
   */

  start(annex: string): void {
    const workspace = localStorage.getItem("workspace");
    this.server = workspace + this.serverBaseUrl;
    const contact = `sip:${annex}@${this.server}`;

    const configuration = {
      uri: contact,
      register: true,
      realm: this.server,
      contact_uri: contact,
      sockets: [this.socket],
      password: this.password,
      registrar_server: this.server,
    };

    // console.log("Configuracion: ", { uri: contact,  });
    console.log("URI: ", contact);

    this.UA = new jssip.UA(configuration);

    // debug.enable("JsSIP:*");

    this.reactive.registerEvents(this.UA);
    this.reactive.useStream([], false);
    this.UA.start();
    this.UA.register();
    console.log("USER AGENT", this.UA);

    // const storage = JssipStorage.get();
    // if (!storage?.ticket) return;
    // this.setData(storage);
  }

  /**
   * Stop and interrupt the connection with the Asterisk server
   *
   * this function must be call when the session is over
   */

  stop(): void {
    this.server = this.serverBaseUrl;
    this.UA.stop();
    this.UA.unregister();
  }

  async hasCallBalance(portfolioId: number | undefined): Promise<boolean> {
    if (!portfolioId) return true;

    const { data: portfolio }: { data: Portfolio } = await fetchPortfolioById(portfolioId);

    // 1 is the id of the Securitec default trunk
    if (portfolio.voip_provider !== 1) return true;

    const response = await fetchWhatsappBalance("calls");

    console.log("Current balance", response);

    if (response.billing_type === BillingType.Postpaid) return true;
    else return response.balance > 0;
  }

  /**
   * Generate a new ticket then makes a call
   * @param annex  number or identifier of the client to be called
   * @param typeDialing  is a string that represents the type of dialing 1 is manual, 2 is progressive and 3 is predictive
   * @param idUser id of user or agent who makes the call
   * @param idClient if the client is identified
   * @returns Ticket object
   */

  async call(
    annex: string,
    typeDialing: number,
    idUser: string,
    idClient?: number,
    codePhone?: string,
    portfolio?: number
  ): Promise<Ticket | undefined> {
    const hasBalance = await this.hasCallBalance(portfolio);

    if (!hasBalance) {
      this.reactive.emit(WorkspaceEvent.CALL_WITHOUT_BALANCE);
      return;
    }

    try {
      this.reactive.emit(WorkspaceEvent.CHECK_MIC, false);
      
      await validateBlacklistOutboundCall({
        code_country: codePhone,
        portfolio: portfolio,
        phone_number: annex,
      });

      if (this.reactive.getMicrophoneState()) {
        const { ticket } = await fetchTicketByPhone(annex, {
          client: idClient,
          type_dialing: typeDialing,
          portfolio: portfolio,
          code_phone: codePhone,
        });
        const name = ticket.client?.name;
        const photo = ticket.client?.profile_picture;
        this.setData({
          ticket: ticket.id,
          channel: ticket.channel.id,
          channel_customer: ticket.channel_customer,
          // channel_person: ticket?.channel_person?.id,
          interaction: ticket.last_interaction?.id,
          client: ticket.client?.id,
          time_acw: ticket.time_acw,
          number: annex,
          name,
          photo,
          type_default_management: ticket?.portfolio?.type_default_management,
        });
        this._call(annex, name, photo, ticket, typeDialing, idUser, "0", "0", {}, codePhone, true);
        this.reactive.emit(WorkspaceEvent.REDIRECT_AT_OUTBOUND_CALL, ticket);
        return ticket;
      } else this.reactive.emit(WorkspaceEvent.MIC_DISABLED, false);
    } catch (error: any) {
      if (error.status === 409 || error.status === 400)
        this.reactive.emit(WorkspaceEvent.ANOTHER_USER_HAS_THIS_TICKET_ACTIVE, { error });
    }
  }

  /**
   * Function to send the call to Asterisk server
   * @param annex number or identifier of the client to be called
   * @param name
   * @param photo
   * @param ticket - Object with the tickets Details
   * @param typeDialing  is a string that represents the type of dialing 1 is manual, 2 is progressive and 3 is predictive
   * @param idUser id of user or agent who makes the call
   */

  async _call(
    annex: string,
    name: string | null,
    photo: string | null | undefined,
    ticket: Partial<Ticket>,
    typeDialing: number,
    idUser: string,
    strategyId: string | undefined = "0",
    strategyPhone: string | undefined = "0",
    { isScreenShareSession = false } = {},
    codePhone?: string,
    alreadyHasBalance = false,
    leadId?: number
  ): Promise<RTCSession | undefined> {
    if (!alreadyHasBalance && ticket?.portfolio) {
      const hasBalance = await this.hasCallBalance(ticket.portfolio.id);

      if (!hasBalance) {
        this.reactive.emit(WorkspaceEvent.CALL_WITHOUT_BALANCE);
        return;
      }
    }

    if (!this.UA.isRegistered()) throw new Error("No registered");
    // The reges match spaces and the content between brackets and replaces it with an empty string
    const user = annex?.replace(/\s|\((.*?)\)/gm, "");
    const host = this.server;
    const port = undefined;
    const uri = new jssip.URI("sip", codePhone ? `${codePhone}${user}` : user, host, port);
    this.callOptions.extraHeaders = [];
    if (ticket) {
      uri.setParam(`X-IdTicket`, String(ticket.id));
      this.callOptions.extraHeaders.push(`X-IdTicket: ${ticket.id}`);

      if (leadId) {
        uri.setParam("X-IdLead", String(leadId));
        this.callOptions.extraHeaders.push(`X-IdLead: ${leadId}`);
      }

      if (ticket.client) {
        uri.setParam("X-IdClient", ticket.client.id);
        this.callOptions.extraHeaders.push(`X-IdClient: ${ticket.client.id}`);
      }

      if (ticket.portfolio) {
        uri.setParam("X-IdPortfolio", String(ticket.portfolio.id));
        this.callOptions.extraHeaders.push(`X-IdPortfolio: ${ticket.portfolio.id}`);

        if (ticket.portfolio.dialing_plan) {
          uri.setParam("X-IdDialplan", String(ticket.portfolio.dialing_plan.id));
          this.callOptions.extraHeaders.push(`X-IdDialplan: ${ticket.portfolio.dialing_plan.id}`);
        }
      }
    }

    // this.setMeta("ivr", {
    //   ...ticket.portfolio,
    //   shouldSent: ticket.portfolio?.survey_submission_type === SentType.Automatic,
    // });

    uri.setParam("X-IdUser", idUser);
    uri.setParam("X-TypeDialing", String(typeDialing));
    uri.setParam("X-IdStrategy", strategyId);
    uri.setParam("X-IdStrategyPhone", strategyPhone);
    uri.setParam("X-IdAsterisk", "0");
    if (isScreenShareSession) uri.setParam("X-WithVideo", "true");
    uri.setParam("X-CountryCode", codePhone);
    uri.setParam("X-PhoneNumber", user);
    this.callOptions.extraHeaders.push(`X-IdUser: ${idUser}`);
    this.callOptions.extraHeaders.push(`X-TypeDialing: ${typeDialing}`);
    this.callOptions.extraHeaders.push(`X-IdStrategy: ${strategyId}`);
    this.callOptions.extraHeaders.push(`X-IdStrategyPhone: ${strategyPhone}`);
    this.callOptions.extraHeaders.push(`X-IdAsterisk: ${0}`);
    if (isScreenShareSession) this.callOptions.extraHeaders.push("X-WithVideo: true");
    this.callOptions.extraHeaders.push(`X-CountryCode: ${codePhone}`);
    this.callOptions.extraHeaders.push(`X-PhoneNumber: ${user}`);

    console.log("IN _CALL", { VARIABLE: this.isInCall, GETTER: this.isInPhoneCall });

    if (!this.isInPhoneCall) {
      const options = this.getCallOptions(isScreenShareSession);

      console.log("call options", options);
      console.log("URI", uri);
      console.log("uri string", uri.toString());
      console.log("URI HEADER", uri.getParam("X-IdAsterisk"));
      this.setMeta("typeDialing", typeDialing);
      this.reactive.emit(WorkspaceEvent.MAKE_OUTBOUND_CALL, true);
      console.log(`jssip _call - uri: ${uri.toString()} - options: ${options}`);
      return this.UA.call(uri.toString(), options);
    }
    this.isInCall = true;
  }

  answer(isScreenShareSession: boolean) {
    const options = this.getCallOptions(isScreenShareSession);
    delete options.eventHandlers;

    console.log("answer options: ", options);

    this.reactive.session.answer(options);
  }

  end({ fromTypification = false } = {}) {
    try {
      const options = {
        extraHeaders: [`X-FromTypification: ${fromTypification}`],
      };
      console.log("INTENTA COLGAR", options);
      console.log("IN END", { VARIABLE: this.isInCall, GETTER: this.isInPhoneCall });

      if (this.isInPhoneCall) {
        console.log("SI CUELGA");
        this.reactive.emit(WorkspaceEvent.ENDED_BY_TYPIFICATION, fromTypification);

        if (this.phone.meta.ivr?.shouldSent)
          this.transfer(`*76${this.phone.meta.ivr.ivr.id}`, this.phone.userId);
        this.reactive.session.terminate(options);
      }
    } catch (error) {
      console.log(error);
      throw new Error("Error al colgar");
    }
  }

  mute() {
    this.reactive.session.mute({ audio: true });
  }
  unmute() {
    this.reactive.session.unmute({ audio: true });
  }

  hold() {
    this.reactive.session.hold({ useUpdate: true });
  }

  unhold() {
    this.reactive.session.unhold({ useUpdate: true });
  }

  sendDTMF(key: string) {
    this.reactive.session.sendDTMF(key);
  }

  /**
   *
   * @param annex The user annex I'm transferring to
   * @param userId The user id I'm transferring to
   */
  transfer(annex: string, userId?: number) {
    // const user = annex;
    const host = this.server;
    const port = undefined;
    const currentCallTicketId = this.reactive.getMetaData("ticket");
    const options: ReferOptionsBody = { extraHeaders: [] };

    const uri = new jssip.URI("sip", annex, host, port);
    uri.setParam("X-Idticket", currentCallTicketId);
    uri.setParam("X-IdUser", String(userId || ""));
    uri.setParam("X-IdAsterisk", this.phone.meta.asteriskId ?? "");
    uri.setParam("X-PhoneNumber", this.phone.receiver.number);
    uri.setParam("X-IdIvr", String(this.phone.meta.ivr?.ivr?.id) ?? "");
    uri.setParam("X-Idtypedialing", String(this.phone.meta.typeDialing) ?? "");
    options.extraHeaders.push(`X-Idticket: ${currentCallTicketId}`);
    options.extraHeaders.push(`X-IdUser: ${userId || ""}`);
    options.extraHeaders.push(`X-IdAsterisk: ${this.phone.meta.asteriskId ?? ""}`);
    options.extraHeaders.push(`X-PhoneNumber: ${this.phone.receiver.number}`);
    options.extraHeaders.push(`X-IdIvr: ${this.phone.meta.ivr?.ivr?.id ?? ""}`);
    options.extraHeaders.push(`X-Idtypedialing: ${this.phone.meta.typeDialing ?? ""}`);

    console.log("TRANSFER TO:", uri, options);

    // const uri = `sip:${annex}@${this.server}`;
    this.reactive.session.refer(uri, options);
  }

  /**
   * Function to register the information about the call in the session of the call
   * @param {DataCallSession} data object that contains the information about the call
   */

  setData(data: DataCallSession) {
    if (data?.ticket) this.setMeta("ticket", data.ticket);
    if (data?.channel) this.setMeta("channel", data.channel);
    // if (data?.channel_person) this.setMeta("channel_person", data.channel_person);
    if (data?.interaction) this.setMeta("interaction", data.interaction);
    if (data?.client) this.setMeta("client", data.client);
    if (data?.time_acw) this.setMeta("acw", data.time_acw);
    if (data?.strategyPhone) this.setMeta("strategyPhone", data.strategyPhone);
    if (data?.type_default_management)
      this.setMeta("type_default_management", data.type_default_management);
    if (data?.asteriskId) this.setMeta("asteriskId", data.asteriskId);
    if (data?.channel_customer) this.setMeta("channel_customer", data.channel_customer);
    this.reactive.setReceiver({
      name: data.name,
      photo: data.photo,
      number: data.number,
    });
    JssipStorage.save(data);
  }

  setMeta(key: string, value: any) {
    this.reactive.setMetaData(key, value);
  }

  /**
   * Clean the information about the call
   */
  clearData({ keepStrategyPhone = false } = {}) {
    if (!keepStrategyPhone) {
      this.setMeta("ticket", null);
      this.setMeta("strategyPhone", null);
    }

    this.setMeta("channel", null);
    // this.setMeta("channel_person", null);
    this.setMeta("interaction", null);
    this.setMeta("client", null);
    this.setMeta("acw", null);
    this.setMeta("type_default_management", null);
    this.setMeta("ivr", null);
    this.setMeta("typeDialing", null);
    this.reactive.setReceiver({ name: null, photo: null, number: null });
    JssipStorage.clear();
  }

  /**
   * Set the status of microphone in the browser
   * @params data if true the microphone is granted
   */
  setMicrophoneState(data: boolean) {
    this.reactive.setMicrophoneState(data);
  }

  /**
   * Call mode is used to know if the current call will follow the default
   * flow or if is a supervision call and do not has to change the user events
   */
  setCallMode(mode: CallMode) {
    this.reactive.setCallMode(mode);
  }

  /**
   * Set the status of the webphone
   * @param data
   */
  updatePhoneState(data: CallStatus) {
    this.reactive.updateStatus(data);
  }

  /**
   * Set the status of the webphone
   * @param data
   */
  updatePhoneTicketTypificated(data: Boolean) {
    this.reactive.updateTypificated(data);
  }


  /**
   * Set the webphone status as in a call
   * To prevent the multiple calls
   * @param data
   */
  setCallStatus(data: boolean) {
    this.isInCall = data;
  }

  /**
   * Video share
   */
  async startScreenSharing(supervisedUser: SupervisedUser) {
    try {
      // const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });

      // const screenTrack = stream.getVideoTracks()[0];

      const currentUser = supervisedUser.user;

      this.setCallMode(CallMode.Supervise);
      const currentSession = await this._call(
        `r${supervisedUser.annex.split("@")[0]}`,
        currentUser.fullname,
        currentUser.picture,
        {},
        0,
        String(currentUser.id),
        undefined,
        undefined,
        { isScreenShareSession: true }
      );
      // const currentSession = this.UA.call(supervisedUser.annex);

      if (!currentSession) throw new Error("No session available");

      console.log(currentSession);

      // currentSession.sendInfo("THIS IUS A FUCKING TEXT");

      // currentSession.connection.addTrack(screenTrack, stream);

      // currentSession.connection.setRemoteDescription(new RTCSessionDescription({ type: "offer" }));

      currentSession.on("accepted", (data: any) => {
        console.log(data);
      });

      currentSession.on("failed", (data) => {
        console.log(data);
        console.log("Screen sharing request failed.");
      });

      currentSession.on("ended", (data) => {
        console.log(data);
        console.log("Screen sharing session ended.");
        EventBus.emit(WorkspaceEvent.USER_SCREEN_MONITORING_ENDED);
      });

      currentSession.connection.addEventListener("track", async (event: RTCTrackEvent) => {
        const videoStream = new MediaStream();
        console.log(event);

        if (event.track.kind !== "video") return;
        // const remoteStream = event.streams[0];

        videoStream.addTrack(event.track);

        console.log("EVENTO STREAM:", videoStream);

        EventBus.emit(WorkspaceEvent.USER_SCREEN_MONITORING_INITIATED, videoStream);
        // Display the remote stream in a video element
        // const videoElement = document.querySelector("#mainVideo") as HTMLVideoElement;
        // videoElement.srcObject = videoStream;
        // videoElement.play();
      });
      // const receivers = currentSession.connection.getReceivers();
      // console.log(receivers);

      // currentSession.connection.addTrack(streamTrack, stream);

      // console.log({ stream, streamTrack, currentSession });
    } catch (error) {
      console.error("Ha ocurrido un error al compartir", error);
    }
  }
}
