import httpManager from "../../../shared/utils/httpManager";

/**
 * Obtener listado de contactos.
 *
 * @param {Object} filters Filtros
 * @param {Object} pagination Paginación
 * @return {Promise<Object>} Listado de contactos filtrados.
 */
import { TABLE_PAGE_SIZE } from "../../../shared/utils/constants";

export async function fetchContacts(filters = {}, pagination = {}) {
  const search = filters.search || "";
  const entity = filters.entity || "";
  const campaign = filters.campaign || "";
  const base = filters.base || "";
  const coverage = filters.coverage || "";
  const status = filters.status || "";
  const code = filters.code || "";

  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE;

  const url = `/contacts/clients/?offset=${offset}&limit=${pageSize}&search=${search}&entity=${entity}&portfolio=${campaign}&base=${base}&coverage=${coverage}&status=${status}&code=${code}`;
  const response = await httpManager.get(url);
  return response.data;
}

export async function fetchContactsIdsWithBasicFilters(filters = {}) {
  console.log(filters, 'filters');
  const search = filters.search || "";
  const entity = filters.entity || "";
  const campaign = filters.campaign || "";
  const base = filters.base || "";
  const coverage = filters.coverage || "";
  const status = filters.status || "";

  const url = `/contacts/clients/for-massive-actions/?search=${search}&entity=${entity}&portfolio=${campaign}&base=${base}&coverage=${coverage}&status=${status}`;
  const response = await httpManager.get(url);
  return response.data;
}
