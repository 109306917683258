import {
  fetchDefaultTickets,
  fetchTicketsWithBasicFilters,
  fetchTicketsWithAdvancedFilters,
  tagTickets,
  getTagDetails,
  resolveTickets,
  resolveSingleTicket,
  editTicketTags,
  fetchTransferData,
  transferTicketToAgent,
  transferTicketToQueue,
  transferTicketToQueueUser,
  fetchAdvancedFiltersData,
  fetchUsersData,
  fetchAllChannels,
} from "../../services";
import { fetchCustomerChannels } from "@/app/shared/services";

import {
  SET_TABLE_PAGE,
  SET_BASIC_FILTERS,
  SET_TICKETS_ADVANCED_FILTERS,
  SET_TICKETS,
  SET_TAG_TO_TICKETS,
  RESOLVE_TICKETS,
  RESOLVE_SINGLE_TICKET,
  EDIT_TICKET_TAGS,
  SET_TRANSFER_DATA,
  SET_ADVANCED_FILTERS_DATA,
  SET_USERS_DATA,
  SET_TICKETS_MANAGER_CHANNELS,
  SET_LOADING,
} from "./mutation-types";

const actions = {
  setTablePage({ commit }, tablePage) {
    commit(SET_TABLE_PAGE, tablePage);
  },
  setBasicFilters({ commit }, basicFilters) {
    commit(SET_BASIC_FILTERS, basicFilters);
  },
  setAdvancedFilters({ commit }, ticketsAdvancedFilters) {
    commit(SET_TICKETS_ADVANCED_FILTERS, ticketsAdvancedFilters);
  },
  async fetchDefaultTickets({ commit }, tablePage) {
    const data = await fetchDefaultTickets(tablePage);
    commit(SET_TICKETS, data);
  },
  async fetchTicketsWithBasicFilters({ commit }, params) {
    const data = await fetchTicketsWithBasicFilters(params);
    commit(SET_TICKETS, data);
  },
  async fetchTicketsWithAdvancedFilters({ commit }, params) {
    const data = await fetchTicketsWithAdvancedFilters(params);

    commit(SET_TICKETS, data);
  },
  async fetchAdvancedFiltersData({ commit }, params) {
    const data = await fetchAdvancedFiltersData(params);
    const identifiers = await fetchCustomerChannels(undefined, null);

    data.tags.push({ id: "without_tag", name: "Sin Etiquetas" });
    data.channels_customers = identifiers.map((el) => ({
      ...el,
      channel: {
        id: el.channel_id,
        name: el.channel_name,
        icon: el.channel_icon,
        status: el.channel_status,
      },
    }));
    commit(SET_ADVANCED_FILTERS_DATA, data);
  },
  async tagTickets({ commit }, payload) {
    const { ticketsId, tagId } = payload;

    const [tagDetails] = await Promise.allSettled([getTagDetails(tagId), tagTickets(payload)]);

    commit(SET_TAG_TO_TICKETS, { ticketsId, tagDetails });
  },
  async resolveTickets({ commit }, ticketsId) {
    await resolveTickets(ticketsId);

    commit(RESOLVE_TICKETS, ticketsId);
  },
  async resolveSingleTicket({ commit }, ticketId) {
    await resolveSingleTicket(ticketId);

    commit(RESOLVE_SINGLE_TICKET, ticketId);
  },
  async editTicketTags({ commit }, payload) {
    const { ticketId, tagsId } = payload;

    const ticketData = await editTicketTags(ticketId, tagsId);

    commit(EDIT_TICKET_TAGS, { ticketId, ticketData });
  },
  async fetchTransferData({ commit }, { ticket, origin, onlyUsers = false }) {
    const transferData = await fetchTransferData(ticket, origin, onlyUsers);

    commit(SET_TRANSFER_DATA, transferData);
  },
  async transferTicketToAgent(_, payload) {
    const { ticketIdList, agentId, origin, isCall, isAsync } = payload;

    // console.log(payload);

    const response = await transferTicketToAgent(ticketIdList, agentId, origin, isCall, isAsync);

    return response;
    // commit(TRANSFER_TICKET_TO_AGENT, { ticketId, agentData });
  },
  async transferTicketToQueue(_, payload) {
    const { ticketIdList, queueId, origin, isCall, isAsync } = payload;

    const response = await transferTicketToQueue(ticketIdList, queueId, origin, isCall, isAsync);

    return response;
    // commit(TRANSFER_TICKET_TO_QUEUE, { ticketId, queueData });
  },
  async transferTicketToQueueUser(_, payload) {
    const { ticketIdList, queueId, user_agent, origin, isCall, isAsync } = payload;

    const response = await transferTicketToQueueUser(ticketIdList, queueId, user_agent, origin, isCall, isAsync);

    return response;
    // commit(TRANSFER_TICKET_TO_QUEUE, { ticketId, queueData });
  },
  async fetchUsersData({ commit }, name) {
    const data = await fetchUsersData(name);

    commit(SET_USERS_DATA, data);
  },
  async fetchTicketManagerAllChannels({ commit }) {
    const data = await fetchAllChannels();

    commit(SET_TICKETS_MANAGER_CHANNELS, data);
  },
  async fetchTicketsExtern({ commit, state }) {
    commit(SET_LOADING, true);
    const basicFilters = state.ticketsBasicFilters;
    const tablePage = state.tablePage;
    const advancedFilters = state.ticketsAdvancedFilters;
    const filters = { ...basicFilters, page: tablePage };

    if (tablePage === 1 && basicFilters) {
      const data = await fetchAdvancedFiltersData(basicFilters);
      commit(SET_ADVANCED_FILTERS_DATA, data);
    }

    if (!basicFilters) {
      const data = await fetchDefaultTickets();
      commit(SET_TICKETS, data);
    } else if (!advancedFilters) {
      const data = await fetchTicketsWithBasicFilters(filters);
      commit(SET_TICKETS, data);
    } else {
      const data = await fetchTicketsWithAdvancedFilters({
        ...basicFilters,
        ...advancedFilters,
        page: tablePage,
      });
      commit(SET_TICKETS, data);
    }
    commit(SET_LOADING, false);
  },
};

export default actions;
