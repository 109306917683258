export const ErrorTypes = Object.freeze({
  NotMarchingFbUser: 1,
});

export const WhatsappTemplateCategories = Object.freeze({
  Marketing: "MARKETING",
  Transactional: "UTILITY",
  OTP: "AUTHENTICATION",
});

export const WhatsappTemplateStatus = Object.freeze({
  Active: 1,
  Inactive: 2,
});

export const WhatsappTemplateStates = Object.freeze({
  Approved: "APPROVED",
  Rejected: "REJECTED",
  Pending: "PENDING",
  Failed: "FAILED",
});

export const WhatsappTemplateTypes = Object.freeze({
  Text: "TEXT",
  TextAndImage: "IMAGE",
  TextAndDocument: "DOCUMENT",
  TextAndVideo: "VIDEO",
});

export const WhatsappTemplateButtons = Object.freeze({
  WithoutButton: "WITHOUT_BUTTON",
  WithButton: "WITH_BUTTON",
});
export const WhatsappTemplateTypeButtons = Object.freeze({
  CallToAction: "CALL_TO_ACTION",
  QuickReply: "QUICK_REPLY",
});
export const WhatsappTemplateTypeSpecificButtons = Object.freeze({
  PhoneNumber: "PHONE_NUMBER",
  Url: "URL",
  OTP: "OTP",
});

export const EmailType = Object.freeze({
  Gmail: 1,
  Outlook: 2,
  Webmail: 3,
  Others: 4,
});

export const EmailTypeIcon = Object.freeze({
  [EmailType.Gmail]: 'gmail.svg',
  [EmailType.Outlook]: 'outlook.svg',
  [EmailType.Webmail]: 'webmail.svg',
  [EmailType.Others]: 'others.svg',
});

export const EmailTypeTitle = Object.freeze({
  [EmailType.Gmail]: 'GMAIL',
  [EmailType.Outlook]: 'Outlook',
  [EmailType.Webmail]: 'Webmail',
  [EmailType.Others]: 'Otros proveedores',
});

export const EmailTypeDescription = Object.freeze({
  [EmailType.Gmail]: 'Servicio de correo de Google con\n almacenamiento en la nube.',
  [EmailType.Outlook]: 'Correo electrónico de Microsoft con\n integración Office.',
  [EmailType.Webmail]: 'Acceso a tu correo desde cualquier\n navegador web.',
  [EmailType.Others]: 'Diversos proveedor de correo con\n características únicas.',
});

export const EmailTypeExampleMail = Object.freeze({
  [EmailType.Gmail]: 'ejemplo@gmail.com',
  [EmailType.Outlook]: 'nombre@outlook.com',
  [EmailType.Webmail]: 'nombre@ejemplo.com',
  [EmailType.Others]: 'nombre@ejemplo.com',
});