<template>
  <a-modal
    :open="open"
    centered
    :title="'Transferir por cola y usuario'"
    @cancel="handleClose"
  >
    <div class="content">
      <p>Selecciona la cola y el usuario al que desea transferir el ticket.</p>

      <div class="filters">
        <a-form layout="vertical">
          <p style="margin-bottom: 0" v-if="portfolios?.length < 2" class="subtitle">
            <!-- <strong>Campaña:</strong> {{ portfolios.at(0).name }} -->
            <strong>Campaña:</strong> {{ portfolio?.name }}
          </p>
          <a-form-item v-else label="Selecciona la campaña:">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="portfolioId"
              placeholder="Seleccionar campaña"
            >
              <a-select-option v-for="portfolio in portfolios" :key="portfolio.id">
                {{ portfolio.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <p>
            Empresa:
            <!-- {{
              portfolios.length === 1
                ? portfolios.at(0)?.entity?.name || "--"
                : portfolios.find((p) => p.id === portfolioId)?.entity?.name || "--"
            }} -->
            {{
              portfolios.length < 2
                ? entity?.name || "--"
                : portfolios.find((p) => p.id === portfolioId)?.entity?.name || "--"
            }} <br/>
            <span v-if="ticketIdList?.length > 0">
              Tickets seleccionados: {{ ticketIdList.length }}
            </span>
          </p>


          <a-form-item>
            <template #label>
              <span class="subtitle">Selecciona la cola:</span>
            </template>
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="queueId"
              @change="handleChangeQueue"
              placeholder="Seleccionar cola"
            >
              <a-select-option
                v-for="queue in portfolios.length === 1
                  ? queues
                  : queues.filter((q) => q.portfolios.at(0)?.id === portfolioId)"
                :key="queue.id"
              >
                {{ queue.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item style="margin-top: 20px">
            <template #label>
              <span class="subtitle">Seleccionar usuario</span>
            </template>
            <a-radio-group v-model:value="typeUser" style="width: 100%">
              <a-card class="card_radio">
                <a-radio :style="radioStyle" :value="1">
                  Asignar automáticamente a un usuario
                  <a-tooltip>
                    <template #title>
                      El ticket será asignado a un <br/>
                      usuario disponible o el primero <br/>
                      en liberarse.
                    </template>
                    <InfoCircleOutlined style="margin-left: 3px" />
                  </a-tooltip>
                </a-radio>
              </a-card>
              <a-card class="card_radio">
                <a-radio :style="radioStyle" :value="2">
                  Asignar manualmente a un usuario
                  <a-tooltip>
                    <template #title>
                      Selecciona a qué usuario de la <br/>
                      cola deseas asignar el ticket
                    </template>
                    <InfoCircleOutlined style="margin-left: 3px" />
                  </a-tooltip>
                </a-radio>
                <div v-if="typeUser == 2">
                  <a-form-item style="margin-left: 20px; margin-top: 10px" required label="Seleccionar usuario">
                    <a-select
                      v-model:value="userId"
                      @focus="handleDropdownVisibleChange"
                      :disabled="!queueId"
                      :placeholder="!queueId ? 'Seleccione una cola primero' : 'Seleccionar'"

                    >
                      <a-select-option v-for="option in userOptions" :key="option.id" :value="option.id">
                        <div class="agent" >
                          <div class="agent_name">{{ option.fullname }}</div>
                          <div class="agent_status">
                            <a-badge :color="eventColor(option.event_management.id)" /> {{ eventText(option.event_management.id) }} 
                          </div>   
                        </div>                        
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </a-card>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <template #footer>
      <footer class="footer">
        <a-tooltip style="width: 100%">
          <template #title v-if="!queueId || (typeUser == 2 && !userId)">
            <span v-if="!queueId">Debe seleccionar una cola <br/> para poder continuar</span>
            <span v-else>Debe seleccionar a un usuario <br/> para poder continuar</span>
          </template>
          <a-button
            type="primary"
            :style="'width: 80%'"
            :disabled="!queueId || (typeUser == 2 && !userId)"
            @click="handleTransfer"
          >
            Transferir
          </a-button>
        </a-tooltip>
      </footer>
    </template>
  </a-modal>
</template>

<script setup>
import { message } from "ant-design-vue";
import { computed, h, ref, watch, createVNode, reactive } from "vue";
import { useStore } from "vuex";

import { Modal } from "ant-design-vue";
import { EventData } from "@/app/shared/utils/globalVariables";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { fetchUsersByQueue } from "@/app/ticketsManager/services";

const props = defineProps({
  open: { type: Boolean, default: false },
  queues: { type: Array, default: () => [] },
  ticketId: { type: Number },
  ticketIdList: { type: Array, default: () => [] },
  fromTypification: { type: Boolean, default: false },
  defaultSelected: { type: Number, default: null },
  origin: { type: Number, default: 1 }, // 1: llamadas o workspace | 2: gestor de tickets
  inCall: { type: Boolean, default: false },
  isAsync: { type: Boolean, default: false },
  agentsList: { type: Array, default: () => [] },
  portfolio: { type: Object, default: false },
  entity: { type: Object, default: false },
});

const emit = defineEmits(["update:open", "update", "handleBack", "transferCall"]);

const store = useStore();

const portfolioId = ref();
const queueId = ref();
const typeUser = ref(1);
const radioStyle = reactive({
  display: 'flex',
  height: '30px',
  lineHeight: '30px',
});
const userOptions = ref([]);
const userId = ref();

const portfolios = computed(() => {
  const portfoliosList = props.queues.flatMap((q) => q.portfolios);
  const uniquePortfolios = [...new Map(portfoliosList.map((item) => [item.id, item])).values()];

  return uniquePortfolios;
});
const eventColor = (id) => {
  return id ? EventData?.[id]?.color : "#00000026";
};
const eventText = (id) => {
  return id ? EventData?.[id]?.name : "Sin evento activo";
};
const handleClose = () => emit("update:open", false);

const handleBack = () => {
  emit("update:open", false);
  emit("handleBack", { key: "queues", idSelected: queueId.value });
};

const handleTransfer = async () => {
  Modal.confirm({
    title: createVNode('div', {}, [
      createVNode('span', {}, props.ticketIdList?.length > 1 
        ? "Estás a punto de transferir los tickets seleccionados."
        : "Estás a punto de transferir el ticket seleccionado."
      ),
      createVNode('br'),
      createVNode('span', {}, "¿Deseas continuar?")
    ]),
    centered: true,
    width: 416,
    icon: createVNode(QuestionCircleOutlined),
    onOk: async () => {
      try {
        const { total_tickets, tickets_transfered } = await store.dispatch(
          "transferTicketToQueueUser",
          {
            ticketIdList: props.ticketId ? [props.ticketId] : props.ticketIdList,
            queueId: queueId.value,
            user_agent: userId.value,
            origin: props.origin,
            isCall: props.inCall,
            isAsync: props.isAsync,
          }
        );

        emit("update", "queues");

        if (!props.isAsync) {
          const successMessage =
            total_tickets === 1
              ? "Se realizó la transferencia correctamente"
              : h("span", [
                  "Se han transferido ",
                  h("strong", `${tickets_transfered} de ${total_tickets}`),
                  ` tickets con éxito. ${
                    total_tickets - tickets_transfered
                  } tickets cuentan con llamada activa. `,
                ]);

          if (tickets_transfered >= 1) {
            message.success(successMessage);
            emit("transferCall", { queueId: queueId.value, itemId: userId.value, type: "queue-user" });
          } else
            message.error(
              h("span", [
                "No se ha transferido ",
                h("strong", "ningún"),
                " ticket con éxito. Todos los tickets cuentan con llamada activa. ",
              ])
            );
        }

        handleClose();
      } catch (error) {
        if (error?.status === 400)
          if (error?.data?.user)
            message.error(error?.data?.user);
          else
            message.error(
              h("span", [
                "No se ha transferido este ticket ",
                h("strong", "porque cuenta con una llamada activa."),
              ])
            );
        console.error(error);
      }
    },
    okText: "Si, Transferir",
    cancelText: "No, Cancelar",
  });
};

const handleFetchAgentsByQueue = async () => {
  const response = await fetchUsersByQueue(queueId.value);
  userOptions.value = response?.agents.filter((user) => {
    return !props.agentsList.some((agent) => agent?.id === user.id);
  });
}

const handleDropdownVisibleChange = (visible) => {
  console.log(visible, 'handle drodown visible change');
  if (visible) handleFetchAgentsByQueue();
}

const handleChangeQueue = async (value) => {
  console.log('handleChangeQueue', value);
  await handleFetchAgentsByQueue();
  userId.value = null;
}

watch(typeUser,
  (value) => {
    console.log('type user');
    userId.value = null;
  }
)

watch(props.defaultSelected, (value) => {
  queueId.value = value;
});
</script>

<style scoped lang="sass">
.footer
  width: 100%
  text-align: center

.filters
  margin: 20px 0 20px

.ant-form-item
  margin-bottom: 0

.subtitle
  font-weight: 500

.card_radio
  margin-bottom: 10px

:deep(.ant-tooltip-disabled-compatible-wrapper)
  width: 100%

.agent
  justify-content: space-between
  display: flex
  &_status
    width: 120px
    text-align: left
  &_name
    white-space: normal
    flex-grow: 1
    max-width: 250px
</style>
