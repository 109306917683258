<template>
  <a-select
    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
    v-model:value="country"
    show-search
    :filter-option="false"
    :not-found-content="null"
    placeholder="Seleccionar país"
    @search="filterCountries"
    @dropdownVisibleChange="handleChange"
    @select="selectCountry"
  >
    <a-select-option v-for="country in copyCountries" :key="country.id">
      <div>
        <img class="flag" :src="country.url_flag" alt="flag" />
        <span> {{ country.name }}</span>
      </div>
    </a-select-option>
  </a-select>
</template>

<script>
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  props: {
    code: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const showCountry = ref(false);
    const store = useStore();
    const getDataUser = computed(() => store.getters["getDataUser"]);
    const countries = computed(() => store.getters["getAllCountries"]);
    const country = ref(props.code ?? getDataUser.value?.customer?.country ?? 1);
    const copyCountries = ref([]);

    watch(countries, (newValue) => {
      copyCountries.value = newValue;
    });

    return {
      country,
      countries,
      copyCountries,
      showCountry,
      getDataUser,
    };
  },
  created() {
    this.$store.dispatch("fetchAllCountries");
    if (!this.code && !this.getDataUser?.customer?.country) {
      this.country = 1;
      this.$emit("onSelected", this.country);
    }
  },
  methods: {
    filterCountries(value) {
      value = value.toLowerCase();
      this.copyCountries = this.countries.filter((item) => {
        return item.name.toLowerCase().includes(value);
      });
    },
    handleChange(data) {
      this.showCountry = data;
      this.copyCountries = this.countries;
    },
    selectCountry(data) {
      this.$emit("onSelected", data);
    },
  },
});
</script>

<style lang="sass" scoped>
.flag
  width: 20px
  margin-bottom: 2px
  margin-right: 4px
</style>
