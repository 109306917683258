<template>
  <div class="information">
    <div :class="{ disabled: !getWorkspace }">
      <span class="input">
        Activado

        <a-switch :checked="checked" @change="handleActiveMassive" :disabled="!getWorkspace" />
      </span>

      <p class="details">
        Permitir que tu cuenta pueda pertenecer al listado de usuarios disponibles para gestiones
        masivas (colas y estrategias).
      </p>
    </div>

    <small class="info" v-if="!getWorkspace">
      Necesitas tener el módulo de Workspace para activarlo. Gestiona tu
      <RouterLink to="/users?key=profiles">perfil aquí.</RouterLink>
    </small>
  </div>
</template>

<script>
import { defineComponent, ref, createVNode, computed, watch } from "vue";
import { useStore } from "vuex";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";

import { activateMassManagement } from "@/app/settings/services";

export default defineComponent({
  setup() {
    const store = useStore();
    const userData = computed(() => store.getters["getDataUser"]);

    const checked = ref(false);

    // onMounted(() => {
    //   checked.value = userData.value.in_strategy;
    // });

    watch(
      userData,
      (newValue) => {
        checked.value = newValue?.in_strategy;
      },
      {
        immediate: true,
      }
    );

    return {
      userData,
      checked,
    };
  },
  computed: {
    getWorkspace() {
      return !!this.userData?.options_menu.find((item) => item.scope === "menu_workspace");
    },
  },
  methods: {
    async handleSendMassive(isInStrategy) {
      const data = {
        in_strategy: isInStrategy,
      };
      await activateMassManagement(data);
    },
    handleActiveMassive() {
      if (!this.userData.in_strategy) {
        Modal.confirm({
          title: "¿Estás seguro de activar la opción de gestiones masivas?",
          content:
            "Recuerda que tu cuenta estará en la lista de usuarios disponibles para realizar gestiones masivas como colas y estrategias. Puedes volver a inactivar esta opción más adelante.",
          onOk: async () => {
            await this.handleSendMassive(true);
            this.checked = true;
            this.$message.success(
              "Su configuración de gestiones masivas ha sido activada correctamente"
            );
            setTimeout(() => window.location.reload(), 200);
          },
          icon: createVNode(QuestionCircleOutlined),
          okText: "Activar",
          onCancel: () => {
            this.checked = false;
          },
        });
      }
      if (this.userData.in_strategy) {
        Modal.confirm({
          title: "¿Estás seguro de inactivar la opción de gestiones masivas?",
          content:
            "Recuerda que si tienes tickets activos se reenviarán a su cola respectiva para ser atendidos y te desasignarás de las estrategias a las que perteneces actualmente. Puedes volver a activar esta opción en cualquier momento",
          onOk: async () => {
            await this.handleSendMassive(false);
            this.checked = false;
            this.$message.success(
              "Su configuración de gestiones masivas ha sido inactivada correctamente"
            );
            setTimeout(() => window.location.reload(), 200);
          },
          icon: createVNode(QuestionCircleOutlined),
          okText: "Inactivar",
          onCancel: () => {
            this.checked = true;
          },
        });
      }
    },
  },
});
</script>

<style lang="sass" scoped>
.text__strong
  font-weight: 600
  font-size: 16px

.flex
  display: flex

.input
  display: flex
  align-items: center
  gap: 40px
  margin-bottom: 12px

.details
  margin-bottom: 8px

.info
  font-size: 12px
  color: $gray-8

.disabled
  opacity: 0.30
</style>

<style></style>
