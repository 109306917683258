<template>
  <a-modal
    :visible="open"
    width="726px"
    title="Instagram para empresas"
    centered
    :maskClosable="false"
    :bodyStyle="{ height: successIntegration ? '650px' : 'inherit' }"
    :footer="null"
    @cancel="handleClose"
  >
    <template v-if="!successIntegration">
      <header>
        <SocialIcon :type="'instagram'" />
        <h2 class="title">
          Necesitamos validar la siguiente información para conectar con éxito tu canal de Instagram
          Business
        </h2>
      </header>

      <a-divider class="divider" type="horizontal" />

      <CustomScrollbar height="50vh">
        <!-- 1 -->
        <h3 class="title">1. ¿Tienes una cuenta comercial de Instagram?</h3>
        <p>Necesitas una cuenta comercial de Instagram para continuar.</p>

        <a-checkbox v-model:checked="validationChecks.businessAccount" class="checkbox">
          Tengo una cuenta comercial de Instagram.
        </a-checkbox>

        <p>
          ¿No es una cuenta comercial? Sigue
          <a href="https://business.instagram.com/getting-started?locale=es_LA" target="_blank" rel="noopener noreferrer">estos pasos</a>
          para actualizar tu cuenta. Necesitarás una página de Facebook y acceso de administrador.
        </p>

        <a-divider class="divider" type="horizontal" />

        <!-- 2 -->
        <h3 class="title">
          2. ¿Está tu cuenta comercial de Instagram vinculada con una página de Facebook?
        </h3>
        <p>
          Para continuar, debes tener acceso de administrador y una página de Facebook vinculada a
          tu cuenta de Instagram. Esto solo se puede realizar desde la App móvil de Instagram.
        </p>

        <a-checkbox v-model:checked="validationChecks.fbPageLinked" class="checkbox">
          Tengo mi cuenta de Facebook vinculada a mi cuenta comercial de Instagram.
        </a-checkbox>

        <p>
          ¿No tienes acceso? Ponte en contacto con tu admin de Facebook para proceder. ¿No estás
          seguro/a de cómo vincular una página de Facebook?
          <a
            href="https://www.facebook.com/business/help/connect-instagram-to-page"
            target="_blank"
            rel="noopener noreferrer"
          >
            Descubre como, aquí.
          </a>
        </p>

        <a-divider class="divider" type="horizontal" />

        <!-- 3 -->
        <h3 class="title">3. Habilitar "Permitir acceso a los mensajes" en Instagram</h3>
        <p>
          Asegúrate de tener activado "Permitir acceso a los mensajes" en tu configuración de
          Instagram. Solo puede hacerse desde la App móvil de Instagram.
        </p>

        <a-checkbox v-model:checked="validationChecks.messageAccessEnabled" class="checkbox">
          Tengo habilitado "Permitir acceso a los mensajes".
        </a-checkbox>

        <p>¿Cómo permitir el acceso a los mensajes en Instagram?</p>

        <ul>
          <li>Abre la App móvil de Instagram y accede a "Configuración".</li>
          <li>Accede a "Privacidad" y selecciona "Mensajes".</li>
          <li>Activa "Permitir acceso a los mensajes" en "Herramientas conectadas".</li>
        </ul>

        <img :src="enableInstagramMessagesImage" alt="Habilitar premiso a los mensajes" />
      </CustomScrollbar>

      <a-divider class="divider" type="horizontal" />

      <footer>
        <iframe
          ref="instagramRef"
          :src="iFrameSrc"
          title="Login with Instagram"
          id="login-instagram-iframe"
          name="login-instagram-iframe"
          height="32px"
          width="100%"
        />
      </footer>
    </template>

    <CompleteIntegration
      v-else
      description="Tu nueva página de Instagram se encuentra lista"
      @remove-listener="handleRemoveListener"
    />
  </a-modal>
</template>

<script setup>
import { reactive, computed, ref, watch, onUnmounted } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";

import { tokenManager } from "@/app/shared/utils/tokenManager";

import SocialIcon from "@/app/channels/components/cards/SocialIcon.vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import CompleteIntegration from "@/app/channels/components/templates/SocialChannels/CompleteIntegration.vue";

import enableInstagramMessagesImage from "@/app/channels/assets/enable-instagram-messages.png";

defineProps({ open: Boolean });
const emit = defineEmits(["close"]);

const store = useStore();
const userData = computed(() => store.getters["getDataUser"]);

const token = tokenManager.getToken();
const workspace = localStorage.getItem("workspace");
const hostname =
  window.location.hostname == "localhost" ? "localhost:8080" : import.meta.env.VITE_HOSTNAME;

const iFrameSrc = `https://${hostname}/loginInstagram/?embed=true&access_token=${token}&workspace=${workspace}&userId=${userData.value.id}&userFullname=${userData.value.fullname}`;

const instagramRef = ref(null);
const successIntegration = ref(false);
const validationChecks = reactive({
  businessAccount: false,
  fbPageLinked: false,
  messageAccessEnabled: false,
});

const listener = (event) => {
  console.log(event);
  if (event.origin !== `https://${hostname}`) return;
  if (event.data === "success") {
    message.success("La página de Instagram se ha vinculado correctamente");
    successIntegration.value = true;
  }
  if (event.data === "error") message.error("Ha ocurrido un error");
};

window.addEventListener("message", listener);

onUnmounted(() => {
  window.removeEventListener("message", listener, false);
});

const isButtonDisabled = computed(() => {
  for (const key in validationChecks) {
    if (!validationChecks[key]) return true;
  }

  return false;
});

watch(isButtonDisabled, () => {
  instagramRef.value.contentWindow.postMessage("toggleDisable", `https://${hostname}`);
});

const handleClose = () => emit("close");
</script>

<style lang="sass" scoped>
header
  text-align: center

.title
  font-size: 16px
  font-weight: 600
  margin: 0
  margin-top: 10px

.divider
  background-color: $gray-7

p
  color: $gray-9
  line-height: 22px
  margin: 0

.checkbox
  color: $gray-8
  margin: 12px 0

ul
  // padding-left: 26px

li
  margin-top: 12px

  &::marker
    content: "✅  "

img
  width: 60%

footer
  text-align: center

iframe
  border: none
</style>
