import { register } from "register-service-worker";

if (import.meta.env.MODE === "production" || import.meta.env.MODE === "development") {
  register(`${import.meta.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
      // document.dispatchEvent(new CustomEvent("appUpdated", { detail: null }));
      // document.dispatchEvent(new CustomEvent("refreshApp"));
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
      localStorage.setItem('content_downloading', 'true')
    },
    updated(registration) {
      const alreadyReloaded = localStorage.getItem('serviceWorkerUpdated');
  
      if (alreadyReloaded === 'true') {
        console.log("Página ya recargada, evitando bucle.");
        localStorage.removeItem('serviceWorkerUpdated'); // Limpiar estado
        localStorage.removeItem('openRefreshNotification'); // Limpiar estado
        document.dispatchEvent(new CustomEvent("refreshApp"));
        return;
      }
      if (localStorage.getItem('content_downloading') == 'true') {
        console.log("New content is available; please refresh.", registration);
        localStorage.removeItem('content_downloading')
        document.dispatchEvent(new CustomEvent("appUpdated", { detail: registration }));
      }
    },
    offline() {
      console.log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
    
  });
}
