<template>
  <section class="transferencia">
    <a-form ref="formRef" :rules="rules" :model="formState">
      <a-row>
        <a-col class="padding-select" :span="24">
          <a-form-item
            class="select-item"
            placeholder="Selecciona tu transferencia"
            label="Tipo de transferencia"
            name="type_transfer"
          >
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              width="300px"
              v-model:value="formState.type_transfer"
              placeholder="Seleccionar tipo de transferencia"
              class="form__item"
            >
              <a-select-option :key="1"> Cola </a-select-option>
              <a-select-option :key="2"> Agente </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="formState.type_transfer == null">
        <a-col :span="24">
          <div class="text-item">Selecciona el tipo de transferencia que deseas realizar</div>
        </a-col>
      </a-row>
      <a-row v-else-if="formState.type_transfer == 1">
        <a-col class="padding-select" :span="24">
          <div class="container container-list">
            <span class="title-item">Selecciona la cola a la que deseas transferir</span>
            <CustomScrollbar height="200px" style="padding-right: 10px">
              <div v-for="item in queue" :key="item.id">
                <div class="items-list">
                  <span class="name">{{ item.name }} </span>
                  <ArrowRightOutlined class="arrow-class" @click="handleTransferCall(item)" />
                </div>
                <div class="container__line">
                  <input class="line__gray" />
                </div>
              </div>
            </CustomScrollbar>
          </div>
        </a-col>
      </a-row>
      <a-row v-else-if="formState.type_transfer == 2">
        <a-col class="padding-select" :span="24">
          <div class="container container-list">
            <span class="title-item">Selecciona el agente al que deseas transferir</span>
            <CustomScrollbar height="200px" style="padding-right: 10px">
              <div v-for="item in agents" :key="item.id">
                <div class="items-list">
                  <TransferAgents :item="item" />
                  <ArrowRightOutlined class="arrow-class" @click="handleTransferCall(item)" />
                </div>
                <div class="container__line">
                  <input class="line__gray" />
                </div>
              </div>
            </CustomScrollbar>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </section>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { defineComponent, ref, reactive, createVNode, watch } from "vue";
import {
  ArrowRightOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { StateOptions, StateTexts, ChannelType } from "@/app/shared/utils/enums";
import { secondsToHHMMSS } from "@/packages/webphone/utils/time";
import { fetchListQueues, fetchAgentsTransfer } from "@/app/workspace/services";
import TransferAgents from "@/app/workspace/components/filters/TransferAgents.vue";
import { Modal } from "ant-design-vue";

const stateColors = {
  [StateOptions.Connected]: "green",
  [StateOptions.Refreshment]: "orange",
  [StateOptions.Occupied]: "red",
  [StateOptions.Services]: "blue",
  [StateOptions.Training]: "purple",
  [StateOptions.NotAvailable]: "cyan",
  [StateOptions.Disconnected]: "",
};
// const stateText = {
//   [StateOptions.Connected]: "Conectado",
//   [StateOptions.Refreshment]: "Refrigerio",
//   [StateOptions.Occupied]: "Ocupado",
//   [StateOptions.Services]: "En servicios",
//   [StateOptions.Training]: "En capacitación",
//   [StateOptions.NotAvailable]: "No disponible",
//   [StateOptions.Disconnected]: "Desconectado",
// };
export default defineComponent({
  components: {
    CustomScrollbar,
    ArrowRightOutlined,
    TransferAgents,
  },
  setup() {
    const formRef = ref();
    const agents = ref([]);
    const queue = ref([]);
    const formState = reactive({
      type_transfer: null,
    });
    const rules = {
      type_transfer: [{ required: true, message: "Campo requerido" }],
    };
    const interval = ref(null);

    return {
      ChannelType,
      formRef,
      formState,
      rules,
      agents,
      queue,
      interval,
    };
  },
  computed: {
    users() {
      return this.$store.getters["getMonitorUserList"];
    },
    user() {
      return this.$store.getters["getDataUser"];
    },
    currentCallChannel() {
      return this.$jssip.phone.meta?.channel;
    },
  },
  created() {
    this.fetchListAgent();
    this.fetchListQueues();
    this.fetchMonitorUsers();
  },
  
  methods: {
    async fetchListQueues() {
      // this.queue = await fetchListQueues({ tickets: [this.$route.query.ticket] });
      this.queue = await fetchListQueues({ tickets: [this.$jssip.phone.meta?.ticket] });
    },
    async fetchListAgent() {
      const response = await fetchAgentsTransfer();
      this.agents = response.filter((agent) => agent.id != this.user.id && agent.state == 1);
    },
    fetchMonitorUsers() {
      this.$store.dispatch("fetchMonitorUsers");
    },
    handleTransferCall(item) {
      if (item.state != 6) {
        if (item.fullname) {
          Modal.confirm({
            title: `¿Estás seguro de transferir esta llamada al usuario "${item?.fullname}"?`,
            content:
              "Recuerda que una vez transferida la llamada, esta será asignada al nuevo usuario seleccionado. Este usuario será el encargado de colocar la tipificación correspondiente.",
            onOk: () => this.transferCallToAgent(item.id),
            icon: createVNode(QuestionCircleOutlined),
            centered: true,
            okText: "Transferir",
            cancelText: "Cancelar",
          });
        } else {
          Modal.confirm({
            title: `¿Estás seguro de transferir esta llamada a "${item?.name}"?`,
            content:
              "Recuerda que una vez transferida la llamada, esta será asignada a uno de los agentes listados a esta cola. El nuevo usuario asignado será el encargado de colocar la tipificación correspondiente.",
            onOk: () => this.transferCallToQueue(item),
            icon: createVNode(QuestionCircleOutlined),
            centered: true,
            okText: "Transferir",
            cancelText: "Cancelar",
          });
        }
      } else {
        Modal.info({
          title: "El usuario no esta disponible",
          content: "No puedes transferir a usuarios desconectados.",
          icon: createVNode(ExclamationCircleOutlined),
          onOk: () => {
            console.log("");
          },
        });
      }
    },
    transferCallToAgent(id) {
      const user = this.users.find((item) => item.user.id == id);

      this.$emit("transferringToAgent", user);
    },
    transferCallToQueue(queue) {
      this.$emit("transferringToQueue", queue);
    },
    getColor(state) {
      return stateColors[state];
    },
    getText(state) {
      return StateTexts[state];
    },
    hour(seconds) {
      return secondsToHHMMSS(seconds);
    },
  },
});
</script>

<style lang="sass" scoped>
.transferencia
  padding: 5px
  height: 275px

.select-item
  display: contents
  > label
    font-size: 10px

.title-item
  font-weight: 400
  font-size: 12px
  line-height: 20px
  color: $gray-9

.text-item
  display: flex
  padding: 45px 20px
  text-align: center
  color: $gray-6
  font-size: 14px
  font-weight: 400
  line-height: 22px

.container-list
  background-color: $white
  padding: 5px
  margin-top: 13px
  width: 100%
  height: 220px

.items-list
  margin-left: 1px
  display: flex
  justify-content: space-between
  padding-top: 10px
  .name
    color: $gray-9
    font-size: 14px
  .state-text
    color: $gray-8
    font-size: 12px

.state__color
  border-radius: 50%
  height: 8px
  width: 8px
  margin-right: 12px
  display: inline-block
  &--green
    background: $green-6

  &--gold
    background: $gold-6

  &--red
    background: $red-6

  &--blue
    background: $blue-6

  &--cian
    background: $cian-1

.container__line
  margin-top: -4px
  width: 100%
  // width: calc(100% - 25px)

.line__gray
  height: 1px
  // margin-left: 5px
  background: $gray-5
  border: 10px
  width: 100%
.arrow-class
  color: #1890ff
  font-size: 18px
  margin-right: 10px
.padding-select
  padding: 0 10px
</style>
