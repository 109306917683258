<template>
  <a-modal v-model:visible="visible" :width="isIntegrationCompleted || complete ? '670px' : '520px'" :bodyStyle="{
    height: isIntegrationCompleted ? '650px' : complete ? '550px' : 'inherit',
  }" :footer="showFooter ? undefined : null" :title="modalTitle" centered @cancel="handleConfirmationClose()">
    <div v-if="isIntegrationCompleted">
      <CompleteIntegration description="Tu nuevo número del canal de Voz se encuentra listo" />
    </div>
    <div v-else>
      <div v-if="showModal" class="template">
        <div v-if="complete" class="title-container">
          <h2>¡Solicitud de número entrante enviada a {{ CompanyName }}! 🚀</h2>
          <p>
            Nuestro equipo estelar te contactará para ayudarte en las próximas 24 horas <br />
            como máximo. ¡Gracias por tu confianza y paciencia cósmica!
          </p>

          <div class="button-style">
            <a-button type="primary" @click="handleGoToChannel">Ver mis canales</a-button>
          </div>

          <div class="image__register">
            <img :src="SuccessEmailImage" style="width: 253px" alt="letter" />
          </div>
        </div>
        <div v-else>
          <a-form ref="formRef2" :model="formState2" :rules="rules2" layout="vertical">
            <p style="margin-bottom: 20px">
              Se solicitará un número entrante para conectarlo con la troncal de {{ CompanyName }}.
              Completa los siguientes campos y nuestro equipo te contactará.
            </p>

            <!-- <h3>Datos de la empresa</h3>

            <a-form-item label="Nombre de la empresa" name="company_name">
              <a-input v-model:value="formState2.company_name" @input="showConfirmModal = true" />
            </a-form-item> -->

            <h3>Datos de contacto</h3>

            <!-- <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item label="Nombres" name="first_name">
                  <a-input v-model:value="formState2.first_name" @input="showConfirmModal = true" />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item label="Apellidos" name="last_name">
                  <a-input v-model:value="formState2.last_name" @input="showConfirmModal = true" />
                </a-form-item>
              </a-col>
            </a-row> -->

            <a-form-item label="Nombre completo" name="fullname">
              <a-input v-model:value="formState2.fullname" @input="showConfirmModal = true" />
            </a-form-item>

            <a-form-item label="Correo" name="email">
              <a-input v-model:value="formState2.email" @input="showConfirmModal = true" />
            </a-form-item>

            <a-form-item label="Teléfono de contacto" name="phone_number">
              <a-row :gutter="16">
                <a-col span="9">
                  <selector-country :code="codePhone" @selected="
                    codePhone = $event;
                  showConfirmModal = true;
                  " />
                </a-col>

                <a-col span="15">
                  <a-input v-model:value="formState2.phone_number" placeholder="Número de celular"
                    @input="showConfirmModal = true" />
                </a-col>
              </a-row>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div v-else class="template-2">
        <template v-if="!isEdit">
          <h2>¿Aún no cuentas con un número?</h2>

          <a-button class="button__request" @click="showModal = true">
            Solicitar nuevo número
            <WebphoneIcon />
          </a-button>

          <a-divider class="divider"> o </a-divider>
        </template>

        <h2>
          {{ isEdit ? "Datos del número entrante" : "Integra tu número" }}
        </h2>

        <p v-if="!isEdit">
          Completa los campos solicitados para poder integrar tu número para llamadas entrantes. Ten
          en cuenta que los datos externos no podrán ser modificados nuevamente.
        </p>

        <div :class="isEdit ? 'form--edit' : 'form--create'">
          <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical">
            <a-form-item name="alias" v-if="isEdit">
              <template #label> Nombre de número <span class="info" style="margin-left: 2px">(Alias)</span>
                <a-tooltip
                  :title="`Nombre que se le dará al número en ${ProductName} para que puedas identificarlo fácilmente en cualquier módulo.`">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-input v-model:value="formState.alias" placeholder="Escribir nombre del número entrante"
                @input="showConfirmModal = true" />
            </a-form-item>

            <a-form-item label="Troncal" name="trunk">
              <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="formState.trunk"
                placeholder="Seleccionar troncal" @change="showConfirmModal = true" :disabled="isEdit">
                <a-select-option v-for="trunk in trunks" :key="trunk.id">
                  {{ trunk.name }}
                </a-select-option>
              </a-select>
              <p v-if="!isEdit" style="margin-top: 4px">
                Si deseas asociar un número entrante de otra troncal, configúrala en el
                <router-link to="/pbx/trunk"> módulo PBX.</router-link>
              </p>
            </a-form-item>
            <a-form-item name="integration_code">
              <template #label>
                Código de integración del proveedor (DID)
                <a-tooltip title="Se requiere ingresar el código que envía el proveedor.">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>
              <a-input v-model:value="formState.integration_code" placeholder="Escribir código de integración (DID)"
                @input="showConfirmModal = true" :disabled="isEdit || !formState.trunk" />
            </a-form-item>
            <a-form-item name="phone_number">
              <template #label>
                Número de marcación del contacto

                <a-tooltip title="Es el número que marcarán tus contactos.">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-input v-model:value="formState.phone_number" placeholder="Escribir número de marcación del contacto"
                @input="showConfirmModal = true" :disabled="isEdit || !formState.trunk" />
            </a-form-item>

            <a-form-item name="alias" v-if="!isEdit" required>
              <template #label>
                Nombre de número <span class="info" style="margin-left: 2px">(Alias)</span>

                <a-tooltip
                  :title="`Nombre que se le dará al número en ${ProductName} para que puedas identificarlo fácilmente en cualquier módulo.`">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-input v-model:value="formState.alias" placeholder="Escribir nombre del número entrante"
                @input="showConfirmModal = true" :disabled="!formState.trunk" />
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>

    <template #footer v-if="!(isIntegrationCompleted || complete)">
      <a-button
        :class="(!isEdit && showModal && isDisabled2) || (!isEdit && !showModal && isDisabled) ? 'button-cancel' : ''"
        @click="handleConfirmationClose()"> Cancelar </a-button>
      <a-tooltip>
        <template #title v-if="!isEdit && !showModal && isDisabled">
          <div>Completa todos los campos solicitados para integrar tu número.</div>
        </template>
        <template #title v-else-if="!isEdit && showModal && isDisabled2">
          <div>
            Completa todos los campos requeridos para poder enviar la solicitud.
          </div>
        </template>
        <a-button type="primary" :disabled="showModal ? isDisabled2 : isDisabled" :loading="isLoading"
          @click="handleOk">
          {{ isEdit ? "Guardar cambios" : showModal ? "Enviar solicitud" : "Integrar" }}
        </a-button>
      </a-tooltip>
    </template>
  </a-modal>
</template>

<script>
import CompleteIntegration from "@/app/channels/components/templates/SocialChannels/CompleteIntegration.vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import { fetchLoggedUser } from "@/app/settings/services";
import { defineComponent, ref, reactive, toRaw, createVNode } from "vue";
import { Modal, message } from "ant-design-vue";
import { QuestionCircleOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import { CompanyName, ProductName } from "@/app/shared/utils/constants";
import SuccessEmailImage from "@/app/channels/assets/success-email.webp?url";

import WebphoneIcon from "@/app/shared/components/icons/WebphoneIcon.vue";

export default defineComponent({
  name: "VoiceChannel",
  components: {
    CompleteIntegration,
    SelectorCountry,
    InfoCircleOutlined,
    WebphoneIcon,
  },
  props: {
    open: {
      required: true,
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
    },
  },
  setup(props) {
    const formRef = ref();
    const showConfirmModal = ref(false);
    const formState = reactive({
      alias: props.record?.alias || "",
      integration_code: props.record?.data_parameters?.integration_code || "",
      phone_number: props.record?.data_parameters?.phone_number || "",
      trunk: props.record?.data_parameters?.trunk,
    });
    const rules = {
      integration_code: [{ required: true, message: "Campo requerido" }],
      phone_number: [{ required: true, message: "Campo requerido" }],
      trunk: [{ required: true, message: "Campo requerido" }],
      alias: [{ required: true, message: "Campo requerido" }],
    };

    const formRef2 = ref();
    const formState2 = reactive({
      fullname: "",
      email: "",
      phone_number: "",
      company_name: "",
    });
    const rules2 = {
      fullname: [{ required: true, message: "Campo requerido" }],
      email: [{ required: true, message: "Campo requerido" }],
      phone_number: [{ required: true, message: "Campo requerido" }],
      company_name: [{ required: true, message: "Campo requerido" }],
    };
    return {
      rules,
      formState,
      formRef,
      rules2,
      formState2,
      formRef2,
      showConfirmModal,
      CompanyName,
      ProductName,
      SuccessEmailImage,
    };
  },
  data() {
    return {
      codePhone: 1,
      isIntegrationCompleted: false,
      showModal: false,
      profile: undefined,
      business: undefined,
      complete: false,
      isLoading: false,
      textComplete: {
        channel: "voice",
        title: "Voice",
        description: "Tu canal de llamada esta listo para su implementación",
      },
    };
  },
  computed: {
    isDisabled() {
      return (
        !this.formState.phone_number ||
        !this.formState.integration_code ||
        this.formState.trunk == undefined ||
        !this.formState.alias
      );
    },
    isDisabled2() {
      return (
        this.formState2.fullname == "" ||
        this.formState2.email == "" ||
        this.formState2.phone_number == "" ||
        this.formState2.company_name == ""
      );
    },
    visible() {
      return this.open;
    },
    showFooter() {
      return !this.isIntegrationCompleted && !this.complete;
    },
    trunks() {
      return this.$store.getters["getTrunkList"].filter((el) => el.use_incoming_calls);
    },
    infoBussiness() {
      return this.$store.getters["getBussiness"];
    },
    user() {
      return this.$store.getters["getDataUser"];
    },
    modalTitle() {
      if (this.isEdit) return `Detalle del número “${this.formState.phone_number}”`;
      if (this.showModal) return "Solicitud de número entrante";

      return "Integración de número para llamadas entrantes";
    },
  },
  watch: {
    complete(isCompleted) {
      if (!isCompleted) return;

      const modal = document.querySelector(".setup-modal .ant-modal-close-icon");

      modal.style.color = "white";
    },
  },
  // watch: {
  //   visible(value) {
  //     if (value) {
  //       this.formState.alias = this.isEdit ? this.record.alias : "";
  //       this.formState.phone_number = this.isEdit ? this.record.data_parameters.phone_number : "";
  //       this.formState.trunk = this.isEdit ? this.record.data_parameters.trunk : undefined;
  //     }
  //   },
  // },
  mounted() {
    this.$store.dispatch("fetchAllTrunks", { useIncomingCalls: true });
    this.fetchProfile();
  },
  methods: {
    handleOk() {
      this.isLoading = true;
      if (this.showModal) {
        this.formRef2.validateFields().then(() => {
          const data = {
            channel: 7,
            data_parameters: {
              ...toRaw(this.formState2),
              code_phone: this.codePhone,
            },
            provider: 4,
          };
          this.handleCreateNewChannel(data);
        });
      } else {
        this.formRef.validateFields().then(() => {
          const data = {
            channel: 7,
            data_parameters: {
              ...toRaw(this.formState),
            },
            provider: 4,
            alias: this.formState.alias,
          };
          if (this.isEdit) {
            this.handleUpdateChannel(data);
          } else {
            this.handleCreateNewChannel(data);
          }
        });
      }
    },
    async handleUpdateChannel(data) {
      try {
        data.id = this.record.id;
        await this.$store.dispatch("updateChannelCreate", data);
        this.$message.success(`Se han guardado los cambios correctamente de tu canal`);
        this.isLoading = false;
        this.$emit("update");
        this.handleCancel();
      } catch (error) {
        console.error(error);
        const errorMessage = error?.getErrorMessage?.() || "Ha courrido un error";

        message.warning(errorMessage);
        this.isLoading = false;
      }
    },
    async handleCreateNewChannel(data) {
      try {
        await this.$store.dispatch("createNewChannel", data);
        if (this.showModal) this.complete = true;
        else this.isIntegrationCompleted = true;
        this.showConfirmModal = false;
        this.isLoading = false;
      } catch (error) {
        const errorMessage = error?.getErrorMessage() || "Ha courrido un error";

        message.warning(errorMessage);
        this.isLoading = false;
      }
    },
    handleConfirmationClose() {
      if (this.showConfirmModal) {
        Modal.confirm({
          title: "¿Deseas descartar los cambios realizados?",
          content: "Recuerda que si no guardas los cambios registrados, estos se perderán.",
          icon: createVNode(QuestionCircleOutlined),
          onOk: () => this.handleCancel(),
          centered: true,
          okText: "Sí, descartar cambios",
          cancelText: "No, seguir editando",
        });
      } else {
        this.handleCancel();
      }
    },
    handleGoToChannel() {
      this.$router.push("/channels/board");
    },
    handleCancel() {
      this.showConfirmModal = false;
      this.isIntegrationCompleted = false;
      this.complete = false;
      if (this.showModal) {
        this.formRef2.resetFields();
      }
      if (this.formRef) this.formRef.resetFields();
      this.showModal = false;

      if (this.isEdit) this.$router.push("/channels/board");

      this.$emit("close");
    },
    async fetchProfile() {
      this.profile = await fetchLoggedUser(this.user);
      this.formState2.company_name = this.infoBussiness?.name;
      this.formState2.fullname = this.profile.fullname;
      this.formState2.email = this.profile.email;
      this.formState2.phone_number = this.profile.phone;
      this.codePhone = this.profile.code_phone;
    },
  },
});
</script>

<style lang="sass" scoped>
p
  color: $gray-8

h2, h3
  color: $gray-9

.divider
  border-color: $gray-5 !important
  font-size: 14px !important
  font-weight: 400 !important

.template
  padding: 0px 18px
  h2
    text-align: center
  h3
    margin-top: 5px

.template-2
  padding: 0 18px

  h2
    // text-align: center
    font-size: 16px
    font-weight: 600
    margin-bottom: 12px

  h3
    margin-top: 5px

.form
  &--create
    margin: 24px 0 0

  &--edit
    margin: 8px 0 0

.button__request
  padding: 0
  display: flex
  align-items: center
  justify-content: center
  width: 100%

.container
  margin: auto
  width: 540px
.link
  color: $blue-6
  margin-bottom: 0px
  font-weight: normal

.info
  color: $gray-7

.title
  font-weight: 600
  font-size: $font-large
  line-height: 28px
  text-align: center
  color: $gray-9
  margin-bottom: $margin-tiny
  margin-top: 30px
.description
  font-size: $font-normal
  line-height: 22px
  color: $gray-9
  text-align: center
.card-details
  background: $geekblue-2
  border: 1px solid $blue-1
  box-sizing: border-box
  border-radius: 2px
  padding: $padding-medium
  width: 100%
  margin-top: 28px
  margin-bottom: 28px
  p
    text-align: left
    margin-bottom: 0px
.card-active
  background: $white
  border: 1px solid $gray-4
  box-sizing: border-box
  border-radius: 8px
  margin-top: $margin-small
  padding: 8px 0px 8px 12px
  margin-bottom: $margin-large
  span
    font-size: $font-small

.image__register
  margin-top: 3rem
  margin-left: 10px
  transform: rotate(-9.36deg)

.button-style
  margin-top: 1rem
  margin-bottom: 3rem
  button
    max-width: 250px

.title-container
  text-align: center
  position: absolute
  inset: 0
  // background-color: #001529
  border-radius: 8px
  padding: 72px 35px 0

  h2
    // color: $gray-2
    font-size: 38px
    font-weight: 600
    line-height: 46px
    margin-bottom: 16px

  p
    // color: $gray-2
    font-size: 16px
    font-weight: 400
    line-height: 24px
    margin-bottom: 40px
.button-cancel
  margin-right: 10px
</style>
