<template>
  <header class="header-chat" :class="{ grid: route.query.client }">
    <ResolveTicket class="button button-resolve" :hasTypification="hasTypification" />

    <TypificationModal
      v-if="route.query.client"
      class="button button-typification"
      :disabled="!hasTypification"
      :isChat="isChat"
    />
  </header>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { useRoute } from "vue-router";
import ResolveTicket from "@/app/workspace/components/modal/ResolveTicket.vue";
import TypificationModal from "@/app/workspace/components/modal/TypificationModal.vue";

const route = useRoute();
const hasTypification = inject("hasTypification");

defineProps({
  isChat: {
    type: Boolean,
  },
});

</script>

<style lang="sass" scoped>
.header-chat
  padding: 8px 12px
  gap: 8px
  border-bottom: $border-medium
  background-color: white

.grid
  display: grid
  grid-template-columns: 1fr 1fr

// .button
//   width: 100%
//   height: 32px
//   // height: 24px
//   // width: 50%
//   div
//     width: 100%
</style>

<!-- <style>
.header-chat div {
  width: 50%;
}
</style> -->
