<template>
  <div v-if="step == 1" class="container-whatsapp">
    <div class="title-container">
      <!-- <p>Completa los datos para integrar con {{ CompanyName }}</p> -->
      <SocialIcon :type="'WhatsApp'" />
      <h3>Solicita tu WhatsApp Business API</h3>
      <span>Completa los siguientes campos y nuestro equipo te contactará <br/>para gestionar tu solicitud.</span>
    </div>    
    <!-- <CustomScrollbar height="520px" auto> -->
    <a-form class="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
      <!-- <div class="title">Datos para tu Waba</div>
      <a-form-item name="alias">
        <template #label>
          <div class="label">
            <span>
              Nombre de Whatsapp integrado <span style="color: #8c8c8c">(Alias)</span>
            </span>
          </div>
        </template>

        <a-input
          v-model:value="formState.alias"
          placeholder="Escribir el Nombre de Whatsapp integrado"
          @input="showConfirmModal = true"
        />
        <span style="color: #8c8c8c">
          Este dato será validado en el momento del registro oficial en meta. Recuerda que no se
          podrá modificar luego.
        </span>
      </a-form-item>
      <div class="title">
        Datos de Facebook
        <a-tooltip>
          <template #title>
            Si no cuentas con este dato, igual {{ CompanyName }} responderá para ayudarte
          </template>
          <InfoCircleOutlined />
        </a-tooltip>
      </div>
      <a-form-item label="Página de Facebook" name="facebook_page_whatsapp">
        <a-input
          v-model:value="formState.facebook_page_whatsapp"
          placeholder="Escribir facebook"
          @input="showConfirmModal = true"
        />
      </a-form-item>
      <div class="title">Datos de la empresa</div>
      <a-form-item name="company_name" label="Nombre de la empresa">
        <a-input
          v-model:value="formState.company_name"
          placeholder="Escribir el nombre de la empresa"
          @input="showConfirmModal = true"
        />
      </a-form-item>
      <a-row :gutter="[12]" style="margin-bottom: 5px">
        <a-col :span="12">
          <a-form-item label="Industria" name="type_industry">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="formState.type_industry"
              @change="showConfirmModal = true"
              placeholder="Seleccionar industria"
            >
              <a-select-option v-for="industry of industries" :key="industry.id">
                {{ industry.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Idioma" name="company_language">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="formState.company_language"
              @change="showConfirmModal = true"
              placeholder="Seleccionar idioma"
            >
              <a-select-option :key="languages.spanish">
                {{ languages.spanish }}
              </a-select-option>
              <a-select-option :key="languages.english">
                {{ languages.english }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[12]" style="margin-bottom: 5px">
        <a-col :span="12">
          <a-form-item label="País" name="country">
            <selector-country-business
              :code="formState.country"
              @onSelected="formState.country = $event"
              placeholder="Seleccionar país"
              @change="handleGetCities"
              @keypress="handleSearchCountry($event)"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Ciudad" name="city">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="formState.city"
              show-search
              :filter-option="false"
              :not-found-content="null"
              placeholder="Selecciona una ciudad"
              @search="filterCity"
              @change="showConfirmModal = true"
            >
              <a-select-option v-for="city in copyCities" :key="city.id">
                {{ city.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item label="Dirección" name="address">
        <a-input
          v-model:value="formState.address"
          placeholder="Escribir dirección de la empresa"
          @input="showConfirmModal = true"
        />
      </a-form-item>
      <a-form-item label="Pagina Web" name="website">
        <a-input
          v-model:value="formState.website"
          placeholder="Escribir dirección web de la empresa"
          @input="showConfirmModal = true"
        />
      </a-form-item> -->
      <h3>Datos de contacto</h3>
      <a-form-item label="Nombre del contacto" name="contact_name">
        <a-input
          v-model:value="formState.contact_name"
          placeholder="Escribir nombre del contacto"
          @input="showConfirmModal = true"
        />
      </a-form-item>

      <a-form-item label="Correo electrónico" name="contact_email">
        <a-input
          v-model:value="formState.contact_email"
          placeholder="Escribir correo electrónico"
          @input="showConfirmModal = true"
        />
      </a-form-item>

      <a-form-item label="Teléfono de contacto" name="phone_number">
        <a-row :gutter="16" type="flex">
          <a-col span="8">
            <selector-country :code="code_phone" @selected="code_phone = $event" />
          </a-col>
          <a-col flex="1" span="16">
            <a-input
              v-model:value="formState.phone_number"
              placeholder="Número de celular"
              @input="showConfirmModal = true"
            />
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
    <!-- </CustomScrollbar> -->
  </div>
  <div v-else class="title-step2-container">
    <h2>¡Solicitud de WhatsApp Business API enviada a {{ CompanyName }}! 🚀</h2>
    <div class="subtitle-text">
      Nuestro equipo estelar te contactará como máximo en las próximas 24 horas para ayudarte.
      ¡Gracias por tu confianza y paciencia cósmica!
    </div>
    <div class="image__register">
      <img :src="SuccessEmailImage" style="width: 253px" alt="letter" />
    </div>
    <div class="button-style">
      <a-button type="primary" @click="toChannels">Ver en Mis canales</a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRaw, watch } from "vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import SelectorCountryBusiness from "@/app/shared/components/selectors/SelectorCountryBusiness.vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { LanguageTypes } from "@/app/shared/utils/enums";
import { cityClient } from "@/app/workspace/services";
import { fetchLoggedUser } from "@/app/settings/services";
import { message } from "ant-design-vue";
import { CompanyName } from "@/app/shared/utils/constants";

import wppLayoutSmall from "@/app/channels/assets/whatsapp-layout-small.svg?url";
import SocialIcon from "@/app/channels/components/cards/SocialIcon.vue";
import SuccessEmailImage from "@/app/channels/assets/success-email.webp";

export default defineComponent({
  components: {
    SelectorCountry,
    CustomScrollbar,
    InfoCircleOutlined,
    SelectorCountryBusiness,
    SocialIcon,
  },
  name: "SecuritecWhatsapp",
  props: {
    typemodal: {
      type: String,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  setup(_, { emit }) {
    const languages = LanguageTypes;
    const formRef = ref();
    const code_phone = ref(undefined);
    const disabled = ref(false);
    const formState = reactive({
      // alias: "",
      // facebook_page_whatsapp: "",
      // company_name: "",
      // type_industry: null,
      // company_language: null,
      country: undefined,
      // city: undefined,
      // website: "",
      // address: "",
      phone_number: "",
      contact_name: "",
      contact_email: "",
    });
    watch(formState, (newValue) => {
      const values = Object.values(newValue);
      console.log(formState, 'form state')
      disabled.value = false;
      for (const value of values) {
        if (value == null || (typeof value === "string" && value.trim() === "")) {
          disabled.value = true;
        }
      }
      emit("disabled", disabled.value);
    });
    const rules = {
      // alias: [{ required: true, message: "Campo requerido" }],
      // facebook_page_whatsapp: [],
      // company_name: [{ required: true, message: "Campo requerido" }],
      // type_industry: [{ required: true, message: "Campo requerido" }],
      // company_language: [{ required: true, message: "Campo requerido" }],
      country: [{ required: true, message: "Campo requerido" }],
      // city: [{ required: true, message: "Campo requerido" }],
      // website: [{ required: true, message: "Campo requerido" }],
      // address: [{ required: true, message: "Campo requerido" }],
      phone_number: [
        {
          pattern: /^([0-9])*$/g,
          message: "Solo números",
        },
        { required: true, message: "Campo requerido" },
      ],
      contact_name: [{ required: true, message: "Campo requerido" }],
      contact_email: [{ required: true, message: "Campo requerido" }],
    };
    return {
      rules,
      formState,
      formRef,
      code_phone,
      languages,
      CompanyName,
      wppLayoutSmall,
      SuccessEmailImage,
    };
  },
  data() {
    return {
      providerId: 4, // Securitec
      showConfirmModal: false,
      cities: [],
      copyCities: [],
    };
  },
  computed: {
    countries() {
      return this.$store.getters["getAllCountries"];
    },
    industries() {
      return this.$store.getters["getAllIndustries"];
    },
    infoBussiness() {
      return this.$store.getters["getBussiness"];
    },
    user() {
      return this.$store.getters["getDataUser"];
    },
    isReady() {
      return (
        // this.formState.company_name != "" &&
        // this.formState.type_industry != null &&
        // this.formState.company_language != null &&
        this.formState.country != null &&
        // this.formState.city != null &&
        // this.formState.website != null &&
        // this.formState.address != null &&
        this.formState.phone_number != null
      );
    },
  },
  created() {
    // this.handleGetCities(this.formState.country);
    this.fetchProfile();
  },
  methods: {
    handleSearchCountry(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    async validateStep1() {
      let valid = false;
      await this.formRef.validateFields().then(() => {
        const data = {
          channel: 3,
          data_parameters: {
            ...toRaw(this.formState),
            code_phone: this.code_phone,
            // alias: this.formState.alias,
          },
          // queue: 1,
          provider: this.providerId,
        };
        valid = this.handleSaveChannel({ data, isEditing: false });
      });
      return valid;
    },
    async handleSaveChannel({ data, isEditing }) {
      try {
        await this.$store.dispatch(isEditing ? "updateChannelCreate" : "createNewChannel", data);
        return true;
      } catch (error) {
        const errorMessage = error?.getErrorMessage() || "Ha courrido un error";

        message.warning(errorMessage);
        return false;
      }
    },
    async handleGetCities(value) {
      this.cities = await cityClient(value);
      this.copyCities = this.cities;
      this.formState.city = undefined;
      this.$emit("change");
    },
    filterCity(value) {
      value = value.toLowerCase();
      this.copyCities = this.cities.filter((item) => {
        return item.name.toLowerCase().includes(value);
      });
    },
    async fetchProfile() {
      const profile = await fetchLoggedUser(this.user);
      // this.formState.company_name = this.infoBussiness?.name;
      // this.formState.type_industry = this.infoBussiness?.type_industry;
      this.formState.country = this.infoBussiness ? this.infoBussiness.country : undefined;
      // this.formState.city = this.infoBussiness?.city;
      // this.formState.website = this.infoBussiness?.website;
      // this.formState.address = this.infoBussiness?.address;
      this.formState.phone_number = profile.phone;
      this.codePhone = profile.code_phone;
      this.formState.contact_name = profile.fullname;
      this.formState.contact_email = profile.email;
    },
    toChannels() {
      this.$emit('close');
      this.$router.push("/channels/board");
    }
  },
});
</script>

<style lang="sass" scoped>

.form
  padding: 0 10px

.title
  font-size: 14px
  font-weight: 600
  text-align: left
  padding-bottom: 10px

.container-whatsapp
  padding: 10px 60px
.space
  margin-bottom: $margin-tiny

.small
  max-height: 20px
.color-icon
  color: $blue-6
  font-size: $font-medium
  margin-top: $margin-tiny
.ant-btn
  // border-radius: 2px
  width: 100%
  max-width: 200px

.ant-form-item
  margin-bottom: $margin-small

p
  margin-bottom: 2px
  font-weight: 600 !important
  color: $gray-9
.flag
  width: 20px
  margin-bottom: 2px
.iconEdit
  color: $blue-6
  margin-right: 10px
  margin-left: 6px
.title-container
  margin-top: 10px
  margin-bottom: 15px
  text-align: center
  h3
    margin-top: 8px
.title-step2-container
  margin-top: 30px
  text-align: center

  h2
    color: $gray-9
    font-size: 30px
    font-weight: 600
    line-height: 38px
    padding: 0 40px
.bodyStyle
  margin-top: 25px
  // padding: 0 10%
  padding: 12px 16px
.image__register
  margin-top: 3rem
  margin-left: 10px
  transform: rotate(-9.36deg)
.button-style
  margin-top: 3rem
  button
    max-width: 250px
.flag
  width: 20px
  margin-bottom: 2px
  margin-right: 4px
.subtitle-text
  color: $gray-8
  text-align: center
  padding: 0 65px

.label
  width: 100%
  display: flex
  justify-content: space-between
  &__description
    color: $gray-6

.preview-name
  position: absolute
  bottom: 62px
  left: 0
  right: 0
  margin: 0 auto
  font-weight: 600
  width: 100%
  text-align: center

:deep(.ant-form-item-label)
  width: 100%

:deep(label[for="form_item_company_name"])
  width: 100%
:deep(label[for="form_item_alias"])
  width: 100%
</style>
