<template>
  <div
    class="cards-channels"
    @click="openModal(channel.type)"
    :class="isDisabled === true ? 'container__disabled' : ''"
  >
    <div class="card-channel" :class="isDisabled === false ? 'hover__blue' : ''">
      <!-- Título -->
      <div class="section-icon-channels">
        <div class="check-circle">
          <SocialIcon class="social-icon" :type="channel.type" width="45" height="45" />
          <a-tag :color="channel.namecolor" class="tag">
            {{ channel.status }}
          </a-tag>
          <!-- <a-tooltip v-if="isActiveChannel">
            <template #title> Contiene canales activos </template>
            <img :src="checkCircleUrl" alt="check-circle" />
          </a-tooltip> -->
        </div>
        <!-- <div class="section-title">
          <SocialIcon :type="channel.type" />
        </div> -->
        <!-- <div class="description">
          {{ channel.button }}
        </div> -->
        <div class="title">
          {{ channel.name }}
        </div>
        <div class="description">
          {{ channel.description }}
          <router-link v-if="channel?.descriptionLink" to="/pbx/trunk">
            {{ channel.descriptionLink }}
          </router-link>
        </div>
        <a-divider class="divider" />

        <div class="check-circle">
          <div>
            <span v-if="channel.type !== 'SMS'" class="info">
              {{ countActiveChannels }} {{ countActiveChannels == 1 ? 'configurado' : 'configurados' }}
            </span>
          </div>
          <div style="display: flex; align-items: center;">
            <span v-if="channel.name == 'SMS'" class="icon-sms-status" :class="isDisabled ? 'button_disabled' : ''">
              <img v-if="hasIntegrated" :src="checkCircleUrl" alt="check-circle" />
              <HourglassOutlined v-else-if="hasPending" />
            </span>
            <span class="button" :class="isDisabled && hasPending ? 'button_disabled' : ''">
              {{ channel.name == 'SMS' ? buttonSms : channel.button }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialIcon from "@/app/channels/components/cards/SocialIcon.vue";
import checkCircleUrl from "@/app/channels/assets/check-circle.svg?url";
import { ChannelType } from "@/app/shared/utils/enums";
import { HourglassOutlined } from "@ant-design/icons-vue";

export default {
  name: "CardChannel",
  components: { SocialIcon, HourglassOutlined },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sms: 4,
      voice: 5,
      facebook: 3,
      whatsApp: 1,
      webChat: 2,
      email: 6,
      checkCircleUrl,
      ChannelType,
      hasPending: false,
      hasIntegrated: false,
    };
  },
  computed: {
    channelsCustomer() {
      return this.$store.getters["getChannelsCustomersList"] || [];
    },
    isDisabled() {
      if (this.channel.type === "SMS") {
        const index = this.channelsCustomer.findIndex(channel => 
          this.channel.channelName === channel.channel_name && (channel.status == 4 || channel.status == 1)
        );
        return index != -1;
      }
      return false;
      // return this.channel.type === "Messenger";
      // const workspace = localStorage.getItem("workspace");
      // return !["cocachola", "sandbox"].includes(workspace);
    },
    buttonText() {
      return this.channel.button;
    },
    countActiveChannels() {
      const channelsActive = this.channelsCustomer.filter((channel) => 
        this.channel.channelName === channel.channel_name && channel.status === 1
      );
      return channelsActive.length;
    },
    isActiveChannel() {
      let active = false;
      this.channelsCustomer.forEach((channel) => {
        if (this.channel.channelName === channel.channel_name && channel.status === 1) {
          active = true;
        }
      });
      return active;
    },
    buttonSms() {
      for (const channel of this.channelsCustomer) {
        if (this.channel.channelName === channel.channel_name) {
          if (channel.status == 4) {
            this.hasPending = true;
          } else if (channel.status == 1) {
            this.hasIntegrated = true;
          }
        }
      }
      if (this.hasIntegrated) return 'Canal integrado';
      else if (this.hasPending) return 'Solicitud pendiente';
      else return 'Integrar canal';
    },
  },
  methods: {
    openModal(type) {
      if (this.isDisabled) return;
      this.$emit("openModal", type);
    },
  },
};
</script>
<style lang="sass" scoped>
.disabled
  opacity: 0.5
  pointer-events: none
.cards-channels
  cursor: pointer
  // min-width: 500px
  max-width: 22rem
  min-width: 17rem
  flex: 0 0 22%

.hover__blue:hover
  border: 1px solid #1677FF
  -webkit-box-shadow: 0px 0px 5px 1px rgba(189,189,189,1)
  -moz-box-shadow: 0px 0px 5px 1px rgba(189,189,189,1)
  box-shadow: 0px 0px 5px 1px rgba(189,189,189,1)
.card-channel
  background-color: $white
  // box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1)
  padding: 18px
  border-radius: 8px
  border: 1px solid $gray-5
  box-sizing: border-box
  height: 240px
  // width: 24vw
  // max-width: 320px
  // max-height: 254px
  .section-icon-channels
    margin-bottom: $margin-tiny
    text-align: left
    height: 100%
    display: flex
    flex-direction: column
    .section-title
      display: flex
      justify-content: center
      margin-bottom: 5px
    .title
      font-size: 20px
      font-weight: 600
      margin-bottom: 5px
      .linkblue, .linkgreen
        margin-bottom: 0px
        margin-left: $margin-tiny
        font-weight: normal
      .linkblue
        color: $blue-6 !important
      .linkgreen
        color: $green-6 !important
    .tag
      margin-right: 0px
      margin-bottom: 5px
      border-radius: 5px
  .description
    font-size: 14px
    line-height: 22px
    color: $gray-8
    min-height: 80px
    // padding: 0 10%
  .button-link
    text-align: right
  .check-circle
    justify-content: space-between
    display: flex
    align-items: start
    img
      width: 20px
      height: 20px

.container__disabled
  cursor: not-allowed

.container__blue
  background-color: $white
  padding: 3% 12px
  border-radius: 8px
  border: 1px solid $gray-5
  box-sizing: border-box
  height: 240px

.social-icon
  margin-top: 5px
  margin-bottom: 8px

.divider
  margin: 0 0 10px

.info
  color: $gray-7

.button
  font-size: 14px
  line-height: 22px
  color: $gray-8
  &_disabled
    color: $gray-6

.icon-sms-status
  margin-right: 5px
  display: flex
  align-items: center
</style>
