/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * =================================================================
 * Copyright 2022, Securitec Perú S.A.C
 * All Rights Reserved.
 *
 *
 */
import { createApp } from "vue";
import { CallStatus, CallMode } from "./phone";
import { Event } from "./events";

import JssipStorage from "./storage";
import { getAcwTimeFrom } from "@/app/workspace/utils/acw";
import EventBus from "@/app/shared/utils/eventBus";
import jssip from "jssip";
import { RTCSession } from "jssip/lib/RTCSession";
import { IncomingRequest } from "jssip/lib/SIPMessage";
import { OutgoingAckEvent, OutgoingEvent, IncomingEvent } from "jssip/lib/RTCSession";
import { IncomingMessageEvent, OutgoingMessageEvent } from "jssip/lib/UA";
import { updateOutboundCall } from "@/app/workspace/services";
import { WorkspaceEvent } from "@/app/workspace/domain/events";

// const HEADER_TO_FIND = "X-IdAsterisk";
const HEADER_TO_FIND = "x-idasterisk";

// function instanceOf<T>(object: any, element: string): object is T {
//   return element in object;
// }

export class DataSession {
  originator: string;
  session: RTCSession;
  request: IncomingRequest;

  constructor(originator: string, session: RTCSession, request: IncomingRequest) {
    this.originator = originator;
    this.session = session;
    this.request = request;
  }
}

export interface JssipReactive {
  app: any;
  session: RTCSession;
  dataSession: DataSession;
  element: HTMLAudioElement;
  ctx: AudioContext;
  source: MediaElementAudioSourceNode;
}

/**
 * WebRTC Component, based in jssip, this component is a new instance of vue that contains all methods implemtation of the jssip library
 */
export class JssipReactive {
  constructor() {
    const app = createApp({
      data: () => ({
        userId: null,
        /**
         * Call status.
         */
        status: CallStatus.None,
        isTypificated: false,
        mode: CallMode.Default,
        /**
         * Call receiver.
         */
        micGranted: false,
        receiver: {
          number: null,
          name: null,
          photo: null,
        },
        /**
         * Determine if the phone is avaible to receive calls.
         */
        available: false,
        /**
         * Determine if the user agent is registered.
         */
        registered: false,
        registerState: 2,
        /**
         * Determine if the call is muted.
         */
        muted: false,
        /**
         * Time control for the call.
         */
        time: {
          start: null,
          end: null,
          elapsedTime: 0,
        },
        /**
         * Custom properties.
         */
        meta: {
          ticket: null,
          channel: null,
          acw: null,
          times: null,
          strategyPhone: null,
          ivr: null,
          asteriskId: null,
          typeDialing: null,
        },

        clock: {
          interval: null,
          now: new Date(),
        },

        predictiveData: {
          phone: null,
          idStrategy: null,
          idClient: null,
        },
      }),
      computed: {
        /**
         * Elapsed time in seconds.
         * @return {number} Elapsed seconds.
         */
        availableTime() {
          const times = JssipStorage.getAcwTimes();
          return getAcwTimeFrom(this.meta.acw, times, this.now);
        },
      },
      methods: {
        setCurrentUserId(id: number) {
          this.userId = id;
        },
        dateToSeconds(date: Date) {
          if (!date) return null;
          return Math.floor(date.getTime() / 1000);
        },
        runClock() {
          if (this.clock.interval) clearInterval(this.clock.interval);
          const now = this.dateToSeconds(new Date());
          this.clock.interval = setInterval(() => {
            const start = this.dateToSeconds(new Date());
            this.time.elapsedTime = start - now;
          }, 1000);
        },
        stopClock() {
          if (this.clock.interval) clearInterval(this.clock.interval);
        },
        /**
         * @param openedTicketId current ticket in workspace url -> route.params.ticket
         */
        isCurrentCallTicket(openedTicketId: string | number): boolean {
          const webphoneTicketId = this.meta.ticket || "";

          return openedTicketId == webphoneTicketId;
        },
        /**
         * @param openedTicketId current ticket in workspace url -> route.params.ticket
         * @param openedChannelId current channel_person in workspace url -> route.params.channel
         */
        isCurrentCallTicketInteraction(
          openedTicketId: string | number,
          openedInteractionId: string | number
        ): boolean {
          const webphoneTicketId = this.meta.ticket || "";
          const webphoneInteractionId = this.meta.interaction || "";

          return openedTicketId == webphoneTicketId && openedInteractionId == webphoneInteractionId;
        },
      },
    });
    this.app = app.mount("#app");
  }

  /**
   * Plugin state.
   */
  get state() {
    return this.app;
  }

  /**
   *
   * @returns {boolean} if mic is granted
   */
  getMicrophoneState(): boolean {
    return this.app.$data.micGranted;
  }

  /**
   * Set if the mic is granted
   */
  setMicrophoneState(state: boolean): void {
    this.app.$data.micGranted = state;
  }

  setCallMode(mode: CallMode) {
    this.app.$data.mode = mode;
  }

  /**
   *
   * @param {CallStatus} status set the status of webphone
   */
  updateStatus(status: CallStatus): void {
    this.app.$data.status = status;
    this.app.$data.available = false;
    if (status === CallStatus.None) this.app.$data.available = true;
  }

  /**
   *
   * @param {Boolean} isTypificated set the typificated of webphone ticket
   */
  updateTypificated(isTypificated: Boolean): void {
    this.app.$data.isTypificated = isTypificated;
  }

  /**
   * Set call receiver data.
   * @param {object} receiver Call receiver.
   * @param {string} receiver.number Phone number.
   * @param {string} receiver.name Receiver name.
   * @param {string} receiver.photo Receiver photo.
   */
  setReceiver(receiver: any) {
    this.app.$data.receiver.number = receiver?.number;
    this.app.$data.receiver.name = receiver?.name;
    this.app.$data.receiver.photo = receiver?.photo;
  }

  /**
   * Set meta data of call.
   * @param {any} key header.
   * @param {any} value
   */
  setMetaData(key: any, value: any) {
    this.app.$data.meta[key] = value;
  }

  setAsteriskId(id: string | undefined, event: string) {
    if (id) {
      this.setMetaData("asteriskId", id);
      const ticketId = this.getMetaData("ticket") as number | undefined;

      if (ticketId) {
        console.log("[setAsteriskId]", { event, ticketId, asteriskId: id });
        updateOutboundCall(ticketId, id);
      }
    }
  }

  getMetaData(key: any) {
    return this.app.$data.meta[key];
  }

  /**
   * Set the webphone as ready to receive or make call.
   * @param {boolean} ready
   */
  setReady(ready: boolean) {
    this.app.$data.status = CallStatus.None;
    this.app.$data.registered = ready;
    this.app.$data.available = ready;
    this.app.$data.time.start = null;
    this.app.$data.time.end = null;
    this.app.$data.time.elapsedTime = 0;
  }

  startAcwTime() {
    JssipStorage.addAcwTime();
  }

  stopAcwTime() {
    const times = JssipStorage.getAcwTimes();
    if (times.length <= 0) return;
    JssipStorage.addAcwTime();
  }

  clearAcwTime() {
    JssipStorage.clearAcwTime();
  }
  /**
   * Function that emit events from the webrtc to the main project
   * @param {string} event name of event
   * @param {any} data any type of data
   */
  emit(event: string, data?: any) {
    EventBus.emit(event, data);
  }

  stopStreamTracks(stream: MediaStream | undefined) {
    if (!stream) return;

    const track = stream.getVideoTracks()[0];

    track.stop();
  }

  /**
   * Function that register events of the session coming from the Asterisk server
   * For more information consult the SECURITEC webrtc documentation
   * @param {jssip.UA }UA instance of jssip
   */
  registerEvents(UA: jssip.UA) {
    UA.on("connecting", (data) => {
      console.log("CONECTANDO");
      this.setReady(false);
      this.emit(Event.Connecting, data);
      this.app.$data.registerState = 2;
    });

    UA.on("connected", (data) => {
      console.log("CONECTADO");
      this.app.$data.registerState = 2;
      this.setReady(false);
      this.emit(Event.Connected, data);
    });

    UA.on("disconnected", (data) => {
      console.log("DESCONECTADO");
      this.setReady(false);
      this.emit(Event.Disconnected, data);
      this.app.$data.registerState = 3;
    });

    UA.on("unregistered", (data) => {
      console.log("NO REGISTRADO");
      this.setReady(false);
      this.emit(Event.Unregistered, data);
      this.app.$data.registerState = 3;
    });

    /**
     * Ready to call and receive calls.
     */
    UA.on("registered", (data) => {
      console.log("REGISTRADO");
      this.setReady(true);
      this.emit(Event.Registered, data);
      this.app.$data.registerState = 1;
    });

    let asteriskIdInMessage: string | undefined;

    UA.on("newMessage", (data: IncomingMessageEvent | OutgoingMessageEvent) => {
      // const body = data.request.body.match(/(\d+\.?\d*)/);
      const body = data.request.body.match(/(\d+(\.\d+)+)/);
      
      console.log("-> [WebRTC] UA NEW MESSAGE: ", {
        data,
        message: data.message,
        header: data.request.getHeaders(HEADER_TO_FIND),
        body: body,
      });

      asteriskIdInMessage = body?.[0];
    });

    UA.on("newRTCSession", (data: DataSession) => {
      console.log("-> [WebRTC] NEW RTC SESSION:", data);

      const sessionData = data;
      const isScreenShareSession = sessionData.request.getHeader("X-Withvideo");

      let screenStream: MediaStream | undefined = undefined;

      this.session = data.session;
      this.updateStatus(CallStatus.Connecting);
      this.emit("SET_CLEAR_TIME", null);
      this.app.$data.available = false;
      this.stopAcwTime();
      this.emit(Event.NewSession, data);

      //Inbound calls (Transferidas y Predictivo)
      if (this.session.direction == "incoming") {
        this.session.on("refer", (data) => {
          console.log("-> [WebRTC] INCOMING REFER: ", {
            data,
            header: data.request.getHeaders(HEADER_TO_FIND),
          });
          this.updateStatus(CallStatus.Transfered);
          this.emit(Event.Transfer, data);
        });
        this.session.on("connecting", (data) => {
          console.log("-> [WebRTC] INCOMING CONNECTING: ", {
            data,
            header: data.request.getHeaders(HEADER_TO_FIND),
          }); // recibo algo?
          this.updateStatus(CallStatus.Connecting);
          this.emit(Event.Incomming, data);
          this.emit(Event.Predictive, data); // genero el predictivo
        });
        this.session.on("progress", async (data: IncomingEvent) => {
          console.log("-> [WebRTC] INCOMING PROGRESS: ", data);
          if (isScreenShareSession) {
            screenStream = await navigator.mediaDevices.getDisplayMedia({
              video: { displaySurface: "monitor" },
              audio: false,
            });

            console.log("GET USER MEDIA", screenStream.getVideoTracks());
          }

          this.emit(Event.Answer, { isScreenShareSession }); // respondo

          if (screenStream) {
            const screenTrack = screenStream.getVideoTracks()[0];
            console.log(screenTrack);

            screenTrack.onended = () => {
              this.emit(WorkspaceEvent.USER_SCREEN_MONITORING_ENDED, screenTrack);
            };

            this.session.connection.addTrack(screenTrack, screenStream);
            this.setCallMode(CallMode.Supervise);
          }
        });
        this.session.on("accepted", (data: IncomingEvent) => {
          console.log("-> [WebRTC] INCOMING ACCEPTED: ", data);
          this.updateStatus(CallStatus.InCall);

          console.log("ACTUAL JSSIP SESSION", {
            session: this.session,
            connection: this.session.connection,
            track: this.session.connection.getSenders(),
            incoming: this.session.connection.getReceivers(),
          });

          // if (screenStream) {
          //   const screenTrack = screenStream.getVideoTracks()[0];
          //   console.log(screenTrack);

          //   screenTrack.onended = () => {
          //     this.emit(WorkspaceEvent.USER_SCREEN_MONITORING_ENDED, screenTrack);
          //   };

          //   this.session.connection.addTrack(screenTrack, screenStream);
          // }

          this.app.runClock();
          this.app.$data.available = false;
          this.clearAcwTime();
          const receivers = this.session.connection.getReceivers();
          this.useStream(receivers);
        });
        this.session.on("update", (data) => {
          console.log(data);
          const receivers = this.session.connection.getReceivers();
          console.log(receivers);
        });
        this.session.on("ended", (data) => {
          console.log("-> [WebRTC] INCOMING ENDED: ", data);
          this.updateStatus(CallStatus.Finished);
          this.app.$data.available = true;
          this.app.stopClock();
          this.startAcwTime();
          this.emit(Event.Ended, data);
          this.stopStreamTracks(screenStream);
        });
        this.session.on("failed", (data) => {
          console.log("-> [WebRTC] INCOMING FAILED: ", data);
          this.updateStatus(CallStatus.Failed);
          this.app.$data.available = true;
          this.app.stopClock();
          this.startAcwTime();
          this.emit(Event.Ended, data);
          this.stopStreamTracks(screenStream);
        });
      }
      //Outbound call (Manuales y Progresivo)
      if (this.session.direction == "outgoing") {
        this.session.on("refer", (data) => {
          console.log("-> [WebRTC] OUTGOING REFER: ", {
            data,
            header: data.request.getHeaders(HEADER_TO_FIND),
          });
          this.updateStatus(CallStatus.Transfered);
          this.emit(Event.Transfer, data);
        });
        this.session.on("connecting", (data) => {
          console.log("-> [WebRTC] OUTGOING CONNECTING: ", {
            data,
            header: data.request.getHeaders(HEADER_TO_FIND),
          });
          this.updateStatus(CallStatus.Connecting);
          this.emit(Event.Incomming, data);
        });
        this.session.on("confirmed", (data: OutgoingAckEvent) => {
          console.log("-> [WebRTC] OUTGOING CONFIRMED: ", data);
          this.app.$data.available = false;
          this.clearAcwTime();
          this.app.$data.time.elapsedTime = 0;
          this.app.$data.available = false;
          this.app.runClock();
          this.emit(Event.Confirmed, data); // This Event is not used in actual call flow
        });

        // Is progress event the UA.on('newMessage') body is available
        this.session.on("progress", (data: OutgoingEvent) => {
          console.log("-> [WebRTC] OUTGOING PROGRESS: ", {
            data,
            header: data.response.getHeaders(HEADER_TO_FIND),
          });
          this.updateStatus(CallStatus.Ringing);
          this.app.$data.time.elapsedTime = 0;
          const receivers = this.session.connection.getReceivers();
          this.useStream(receivers);
          this.setAsteriskId(asteriskIdInMessage, "progress");
          this.emit(Event.Progress, data);
        });

        this.session.on("accepted", (data: OutgoingEvent) => {
          console.log("-> [WebRTC] OUTGOING ACCEPTED: ", {
            data,
            header: data.response.getHeaders(HEADER_TO_FIND),
          });
          this.updateStatus(CallStatus.InCall);
          const receivers = this.session.connection.getReceivers();
          this.useStream(receivers);
          this.emit(Event.Accepted, data);
        });

        this.session.on("ended", (data) => {
          console.log("-> [WebRTC] OUTGOING ENDED: ", data);
          this.updateStatus(CallStatus.Finished);
          this.app.stopClock();
          this.app.$data.available = true;
          this.startAcwTime();
          this.emit(Event.Ended, data);
        });
        this.session.on("failed", (data) => {
          console.log("-> [WebRTC] OUTGOING FAILED: ", data);
          this.updateStatus(CallStatus.Failed);
          this.app.$data.available = true;
          this.app.stopClock();
          this.startAcwTime();
          this.emit(Event.Ended, data);
        });
      }
    });
  }

  /**
   * Function that configure a audio element to deploy in DOM and then hear the call
   * also introduce an audio analyser to show a signal that audio is being received
   * @param {any} iterable
   * @param {boolean} autoplay
   */
  useStream(iterable: any[], autoplay = true) {
    //Create a audio component in the DOM
    this.element = document.createElement("audio");
    // this.videoElement = document.querySelector("#mainVideo");
    //Create a media stream
    const stream = new MediaStream();
    if (!iterable.length) return;

    // if (iterable.some((element) => element.track.kind === "video")) return;

    console.log("ITERABLE", iterable);
    //analize the audio of the call and set in the stream component
    iterable.forEach((element) => {
      if (element.track.kind === "audio") stream.addTrack(element?.track);
      // if (element.track.kind === "video") videoStream.addTrack(element?.track);
    });
    //set stream in the audio component
    this.element.srcObject = stream;
    if (autoplay) this.element.play();

    //Introduce of audio analyzer
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(function () {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = function () {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          let values = 0;

          const length = array.length;
          for (let i = 0; i < length; i++) {
            values += array[i];
          }

          const average = values / length;

          if (Math.round(average) > 15) {
            EventBus.emit("MIC_ON");
          } else {
            EventBus.emit("MIC_OFF");
          }
        };
      })
      .catch(function (err) {
        console.log(err);
        /* handle the error */
      });
  }
}
