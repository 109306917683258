<template>
  <div>
    <a-dropdown 
      placement="bottomRight" 
      :trigger="['click']" 
      @click="handleTransferClick"
      :open="isDropdownOpen"
    >
      <a-tooltip title="Transferir ticket">
        <a-button
          type="text"
          :disabled="!hasSelectedTickets && uniquePortfolios.length !== 1"
          class="button-transfer"
          @click.prevent
        >
          <SwapOutlined class="icon" />
          Transferir ticket
        </a-button>
      </a-tooltip>

      <template #overlay>
        <a-menu @click="handleDropdownClick">
          <a-menu-item key="users" :disabled="uniqueQueues.length !== 1">
            <a-tooltip
              :title="
                uniqueQueues.length !== 1
                  ? 'Los tickets seleccionados deben pertenecer a una misma cola para poder ser transferidos a un usuario.'
                  : null
              "
              placement="left"
            >
              Transferir a un usuario
            </a-tooltip>
          </a-menu-item>

          <a-menu-item key="queues"> Transferir a cola </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

    <QueueTransferModal
      v-if="openQueueTransferModal"
      v-model:open="openQueueTransferModal"
      :queues="transferData.queues"
      :ticketIdList="selectedTickets.map((ticket) => ticket.id)"
      :origin="1"
      @update="handleRefreshList"
    />

    <UserTransferModal
      v-if="openUserTransferModal"
      v-model:open="openUserTransferModal"
      :origin="1"
      :users="transferData.agents"
      :queue="uniqueQueues.at(0)"
      :ticketIdList="selectedTickets.map((ticket) => ticket.id)"
      @update="handleRefreshList"
    />
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { SwapOutlined } from "@ant-design/icons-vue";
import { computed, ref, toRaw } from "vue";
import { useRoute, useRouter } from "vue-router";

import QueueTransferModal from "@/app/ticketsManager/components/modals/transfer/QueueTransferModal.vue";
import UserTransferModal from "@/app/ticketsManager/components/modals/transfer/UserTransferModal.vue";
import { clone } from "lodash";

const props = defineProps({
  hasSelectedTickets: { type: Boolean, default: false },
});

const store = useStore();
const route = useRoute();
const router = useRouter();

const selectedTickets = computed(() => store.getters["getSelectedTickets"]);
const transferData = computed(() => store.getters["getTransferData"]);
const isDropdownOpen = ref(false);

const uniquePortfolios = computed(() => [
  ...new Set(selectedTickets.value.map((item) => item.portfolio?.id)),
]);

const uniqueQueues = computed(() => [
  ...new Set(selectedTickets.value.map((item) => item.queue?.name)),
]);

const openQueueTransferModal = ref(false);
const openUserTransferModal = ref(false);
let prevSelectedTickets = undefined;

const handleTransferClick = async (visible) => {
  if (props.hasSelectedTickets || uniquePortfolios.value?.length == 1) {
    isDropdownOpen.value = visible;
    if (visible) {
      const ticketId = selectedTickets.value.at(0)?.id;
      prevSelectedTickets = clone(toRaw(selectedTickets.value));

      await store.dispatch("fetchTransferData", { ticket: ticketId, origin: 1 });
    }
  } else {
    isDropdownOpen.value = false; // Evita que se abra si no cumple la condición
  }
};

const handleDropdownClick = async ({ key } = {}) => {
  if (key === "users") openUserTransferModal.value = true;
  if (key === "queues") openQueueTransferModal.value = true;
};

const handleRefreshList = async () => {
  await store.dispatch("fetchActiveWorkspaceTickets");

  const isOpenTicketTransferred = prevSelectedTickets
    ? prevSelectedTickets.some((ticket) => ticket.id === Number(route.query.ticket))
    : false;

  if (isOpenTicketTransferred) router.push("/workspace");
};
</script>

<style lang="sass" scoped>
.button-transfer
  width: 100%
  display: flex
  align-items: center

  .icon
    font-size: 18px

.ant-dropdown-menu
  width: 190px !important
.color-icon
  color: $blue-6
  font-size: $font-medium
.color-icon-disabled
  color: $gray-6
  font-size: $font-medium
  cursor: not-allowed
</style>
