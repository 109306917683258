<template>
  <a-modal
    v-model:visible="visible"
    width="700px"
    :bodyStyle="{ height: showFooter === undefined ? '592px' : '470px' }"
    :footer="false"
    :title="!isIntegrationCompleted ? 'Integrar canal de SMS' : undefined"
    centered
    :maskClosable="false"
    @cancel="handleCancel"
  >
    <a-divider v-if="!isIntegrationCompleted" class="divider" />
    <div v-if="isIntegrationCompleted" class="title-step2-container">
      <!-- <CompleteIntegration :text-complete="textComplete" type="sms" /> -->
      <h2>¡Solicitud para integrar canal de SMS enviada a {{ CompanyName }}! 🚀</h2>
      <div class="subtitle-text">
        Nuestro equipo estelar te contactará para ayudarte en las próximas 24 horas <br/>
        como máximo. ¡Gracias por tu confianza y paciencia cósmica!
      </div>
      <div class="button-style">
        <a-button type="primary" @click="toChannels">Ver en Mis canales</a-button>
      </div>
      <div class="image__register">
        <img :src="SuccessEmailImage" style="width: 253px" alt="letter" />
      </div>
    </div>
    <div v-else class="container">
      <div class="title-container">
        <SocialIcon :type="'SMS'" />
        <h3 class="title">Solicita integrar un canal de SMS</h3>
        <span>
          Completa los siguientes campos y nuestro equipo te contactará <br/>
          para gestionar tu solicitud. Se podrá integrar solo 1 canal de SMS.
        </span>
      </div>

      <a-form class="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
        <h3>Datos de contacto</h3>
        <a-form-item label="Nombre completo" name="contact_name">
          <a-input
            v-model:value="formState.contact_name"
            placeholder="Escribir nombre completo"
            @input="showConfirmModal = true"
          />
        </a-form-item>

        <a-form-item label="Correo electrónico" name="contact_email">
          <a-input
            v-model:value="formState.contact_email"
            placeholder="Escribir correo electrónico"
            @input="showConfirmModal = true"
          />
        </a-form-item>

        <a-form-item label="Teléfono de contacto" name="phone_number">
          <a-row :gutter="16" type="flex">
            <a-col span="6">
              <selector-country :code="code_phone" @selected="code_phone = $event" />
            </a-col>
            <a-col flex="1" span="18">
              <a-input
                v-model:value="formState.phone_number"
                placeholder="Número de celular"
                @input="showConfirmModal = true"
              />
            </a-col>
          </a-row>
        </a-form-item>
      </a-form>

      <!-- <p class="title">SMS</p>
      <p class="description">¡Activa tu canal SMS doble vía para conversar con tus clientes!</p>
      <div class="card-details">
        <p class="description">
          • La numeración mostrada al enviar es aleatoria. <br />
          • La efectividad de entrega es del 99%. <br />
          • Los resultados, en tiempos y estados, dependen del operador móvil.
          <br />
          • Comunicación bidireccional.
        </p>
      </div>

      <a-row :gutter="4" type="flex">
        <a-col span="12"> <InfoCircleOutlined /> Válido solo para Perú </a-col>
      </a-row> -->
    </div>
    <div class="footer" v-if="showFooter">
      <a-tooltip style="width: 100%">
        <template #title v-if="disabled">
          Completa todos los campos <br/> requeridos para poder enviar <br/> la solicitud
        </template>
        <a-button class="footer_button" type="primary" :disabled="disabled || isLoading" :loading="isLoading" @click="handleOk">
          Enviar solicitud
        </a-button>
      </a-tooltip>
    </div>

    <!-- <template #footer>
      <a-button @click="handleCancel">Atras</a-button>

      <a-button type="primary" :disabled="isDisabled" @click="handleOk"> Activar </a-button>
    </template> -->
  </a-modal>
</template>

<script>
import CompleteIntegration from "@/app/channels/components/templates/SocialChannels/CompleteIntegration.vue";
import { defineComponent, ref, reactive, watch, toRaw, createVNode } from "vue";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import SocialIcon from "@/app/channels/components/cards/SocialIcon.vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import { fetchLoggedUser } from "@/app/settings/services";
import { ChannelType } from "@/app/shared/utils/enums";
import SuccessEmailImage from "@/app/channels/assets/success-email.webp?url";
import { CompanyName } from "@/app/shared/utils/constants";
import { Modal } from "ant-design-vue";

export default defineComponent({
  name: "SmsChannels",
  components: {
    CompleteIntegration,
    InfoCircleOutlined,
    SocialIcon,
    SelectorCountry,
  },
  props: {
    open: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const formRef = ref();
    const code_phone = ref(1);
    const disabled = ref(false);
    const isLoading = ref(false);
    const formState = reactive({
      country: 1,
      phone_number: "",
      contact_name: "",
      contact_email: "",
    });
    const rules = {
      country: [{ required: true, message: "Campo requerido" }],
      phone_number: [
        {
          pattern: /^([0-9])*$/g,
          message: "Solo números",
        },
        { required: true, message: "Campo requerido" },
      ],
      contact_name: [
        { required: true, message: "Campo requerido" },
        {
          pattern: /^(?!\s*$).+/,
          message: "No se permiten solo espacios",
        },
      ],
      contact_email: [
        { required: true, message: "Campo requerido" },
        {
          pattern: /^(?!\s*$).+/,
          message: "No se permiten solo espacios",
        },
      ],
    };
    watch(formState, (newValue) => {
      const values = Object.values(newValue);
      disabled.value = false;
      for (const value of values) {
        if (value == null || (typeof value === "string" && value.trim() === "")) {
          disabled.value = true;
        }
      }
      // emit("disabled", disabled.value);
    });
    return {
      rules,
      code_phone,
      formState,
      formRef,
      disabled,
      SuccessEmailImage,
      CompanyName,
      isLoading,
    };
  },
  watch: {
    open(value) {
      if (value) {
        this.formRef?.resetFields();
        this.fetchProfile();
      }
    },
  },
  data() {
    return {
      providerId: 4, // Securitec
      isIntegrationCompleted: false,
      textComplete: {
        channel: "sms",
        title: "SMS",
        description: "Tu canal de SMS esta listo para su implementación",
      },
    };
  },
  computed: {
    visible() {
      return this.open;
    },
    isDisabled() {
      return this.infoBussiness?.country !== 1;
    },
    showFooter() {
      return !this.isIntegrationCompleted;
    },
    infoBussiness() {
      return this.$store.getters["getBussiness"];
    },
    user() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    async handleOk() {
      await this.formRef.validateFields().then(async () => {
        this.isLoading = true;
        const data = {
          channel: ChannelType.Sms,
          data_parameters: {
            ...toRaw(this.formState),
            code_phone: this.code_phone,
          },
          provider: this.providerId,
        };
        this.isIntegrationCompleted = await this.handleSaveChannel({ data, isEditing: false });
        this.isLoading = false;
      });
    },
    async handleSaveChannel({ data, isEditing }) {
      try {
        await this.$store.dispatch(isEditing ? "updateChannelCreate" : "createNewChannel", data);
        return true;
      } catch (error) {
        const errorMessage = error?.getErrorMessage() || "Ha courrido un error";

        message.warning(errorMessage);
        return false;
      }
    },
    async handleCreateNewChannel(data) {
      try {
        await this.$store.dispatch("createNewChannel", data);
        this.isIntegrationCompleted = true;
        this.formRef.resetFields();
      } catch (error) {
        const errorMessage = error?.getErrorMessage() || "Ha courrido un error";

        this.$message.error(errorMessage);
      }
    },
    handleCancel() {
      if (!this.isIntegrationCompleted) {
        Modal.confirm({
          title: "¿Estás seguro de descartar cambios?",
          content: "Al cerrar no se guardarán los datos previamente ingresados",
          icon: createVNode(QuestionCircleOutlined),
          onOk: () => {
            this.isIntegrationCompleted = false;
            this.$emit("close");
          },
          centered: true,
          okText: "Descartar",
          cancelText: "Cancelar",
        });
      } else {
        this.isIntegrationCompleted = false;
        this.$emit("close");
      }
    },
    async fetchProfile() {
      console.log('fetchProfile');
      const profile = await fetchLoggedUser(this.user);
      this.formState.country = this.infoBussiness ? this.infoBussiness.country : 1;
      this.formState.phone_number = profile.phone;
      this.codePhone = profile.code_phone;
      this.formState.contact_name = profile.fullname;
      this.formState.contact_email = profile.email;
    },
    toChannels() {
      this.$emit('close');
      this.$router.push("/channels/board");
    },
  },
});
</script>

<style lang="sass" scoped>
.template
  padding: 32px 20px 8px
.container
  margin: auto
  margin-bottom: 30px
  margin-top: 30px
  width: 440px
.link
  color: $blue-6
  margin-bottom: 0px
  margin-left: $margin-tiny
  font-weight: normal
.title
  font-weight: 600
  font-size: $font-large
  line-height: 28px
  text-align: center
  color: $gray-9
  margin-bottom: $margin-tiny
  margin-top: 30px
.description
  font-size: $font-normal
  line-height: 22px
  color: $gray-9
  text-align: center
.card-details
  background: $geekblue-2
  border: 1px solid $blue-1
  box-sizing: border-box
  border-radius: 2px
  padding: $padding-medium
  width: 100%
  margin-top: 28px
  margin-bottom: 28px
  p
    text-align: left
    margin-bottom: 0px
.card-active
  background: $white
  border: 1px solid $gray-4
  box-sizing: border-box
  border-radius: 8px
  margin-top: $margin-small
  padding: 8px 0px 8px 12px
  margin-bottom: $margin-large
  span
    font-size: $font-small
.btns
  display: flex
  justify-content: flex-end
  button
    border-radius: 2px
  .btn-return
    margin-right: 12px

.container-sms
  padding: 10px 60px
.title-container
  margin-top: 10px
  margin-bottom: 20px
  text-align: center
  h3
    margin-top: 8px

.footer
  display: flex
  align-items: center
  justify-content: center
  padding: 14px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0
  button
    width: calc(100% - 180px)

:deep(.ant-tooltip-disabled-compatible-wrapper)
  width: 100% !important
  text-align: center
.divider
  position: absolute
  left: 0
  top: 45px
  margin: 10px 0

.title-step2-container
  margin-top: 30px
  text-align: center
  h2
    color: $gray-9
    font-size: 30px
    font-weight: 600
    line-height: 38px
    padding: 0 40px

.button-style
  margin-top: 1rem
  margin-bottom: 3rem
  button
    max-width: 250px

</style>
