<template>
  <header>
    <h3>¿Cual es tu proveedor de correo electrónico?</h3>
    <p>
      Selecciona el proveedor de tus correos electrónicos.<br/>
      Esto nos permitirá ayudarte con su documentación necesaria
    </p>
  </header>

  <!-- @/app/flows/sequences/assets/ -->

  <div class="container__card">
    <a-card class="card-type" v-for ="([key, value]) in Object.entries(EmailType)" :key="value"
      @click="selectedType(value)">
      <div class="icon">
        <img :src="urlImage(EmailTypeIcon[value])" alt="multichannel" />
      </div>
      <div class="content">
        <p class="title">{{ EmailTypeTitle[value] }}</p>
        <p class="description">
          {{ EmailTypeDescription[value] }}
        </p>
      </div>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { EmailType, EmailTypeIcon, EmailTypeTitle, EmailTypeDescription } from "@/app/channels/utils/enums";

const emit = defineEmits<{
  selectedType: [type: number];
}>();

const selectedType = (type: number) => {
  emit("selectedType", type);
};

const urlImage = (image: string) => {
  return new URL(`../../image/${image}`, import.meta.url).href;
};
</script>

<style scoped lang="sass">
h3
  color: $gray-9
  font-size: 20px
  margin-bottom: 16px

h4
  color: $gray-9
  font-size: 14px
  margin-bottom: 16px
  font-weight: 600

.divider
  background-color: $gray-6

header
  width: 100%
  text-align: center
  margin-bottom: 25px
  margin-top: 30px

  p
    color: $text-secondary
    margin: 0
    font-size: 16px

.container
  &__card
    display: flex
    justify-content: center
    flex-wrap: wrap
    margin: 30px 14px
    gap: 20px
    .card-type
      min-width: 340px
      text-align: center
      cursor: pointer
      flex: 0 0 30%
      .content
        padding: 0 30px
        .title
          font-weight: 600
          font-size: 14px
          line-height: 22px
          margin: 10px 0 10px
        .description
          font-size: 12px
          line-height: 20px
    .card-type:hover
      border: 1px solid #1677FF
</style>
