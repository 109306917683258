<template>
  <div class="general">
    <a-form>
      <a-form-item label="De:" v-if="emailTransmitter" class="identifier">
        <!-- <p>{{ transmitter }}</p> -->
        <p>{{ emailFrom(formState.fromEmail) }}</p>
        <div class="transfer">
          <IconFolderArrow small />
          <a-button type="link" @click="handleChangeEmailFrom"
            >Responder desde otro identificador</a-button
          >
        </div>
      </a-form-item>
      <a-form-item label="De:" v-else>
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          class="form-item-xl"
          placeholder="Seleccionar identificador"
          show-search
          :filter-option="addresseeOptions"
          mode="single"
          v-model:value="formState.fromEmail"
        >
          <template #tagRender="{ closable, onClose, value }">
            <a-tag :closable="closable" color="green" style="margin-right: 3px" @close="onClose">
              {{ value }}
            </a-tag>
          </template>
          <a-select-option v-for="option in addresseeOptions" :key="option.id">
            <div class="option">
              <span>{{ option.email }}</span>
              <p>{{ option.name }}</p>
            </div>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Para:" class="send-email">
        <div class="flex-container">
          <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" class="form-item-xl"
            placeholder="Seleccionar identificador" show-search :filter-option="filteredOptions"
            v-model:value="formState.selectedItems" mode="tags" @search="fetchEmails">
            <template #tagRender="{ closable, onClose, value }">
              <a-tag
                :closable="closable"
                :color="getTagColor(value)"
                style="margin-right: 3px"
                @close="onClose"
              >
                <template v-if="shouldRenderIcon(value)">
                  <component :is="getTagIcon(value)" />
                </template>
                <template v-else>
                  <component :is="getTagIcon(value)" />
                  {{ value }}&nbsp;&nbsp;
                </template>
                <template v-if="shouldRenderPopover(value)">
                  <a-popover trigger="hover">
                    <template #content>
                      <h4>{{ displayName(value) }}</h4>
                      <p>Correo: {{ value }}</p>
                    </template>
                    {{ value }}&nbsp;&nbsp;
                  </a-popover>
                </template>
              </a-tag>
            </template>
            <a-select-option v-for="option in options" :key="option.email">
              <div class="option">
                <p>{{ option.name }}</p>
                <span>{{ option.email }}</span>
              </div>
            </a-select-option>
          </a-select>
          <div class="link">
            <a-button type="link" v-if="!showCC" @click="showCC = true">CC</a-button>
            <a-button type="link" v-if="!showCCO && !showCC" @click="showCCO = true">CCO</a-button>
          </div>
        </div>
      </a-form-item>
      <a-form-item label="CC:" class="send-email" v-if="showCC">
        <div class="flex-container">
          <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" class="form-item-xl"
            placeholder="Seleccionar identificador" show-search :filter-option="filteredOptionsCC"
            v-model:value="formState.selectedItemsCC" mode="tags" @search="fetchEmails">
            <template #tagRender="{ closable, onClose, value }">
              <a-tag
                :closable="closable"
                :color="getTagColor(value)"
                style="margin-right: 3px"
                @close="onClose"
              >
                <template v-if="shouldRenderIcon(value)">
                  <component :is="getTagIcon(value)" />
                </template>
                <template v-else>
                  <component :is="getTagIcon(value)" />
                  {{ value }}&nbsp;&nbsp;
                </template>
                <template v-if="shouldRenderPopover(value)">
                  <a-popover trigger="hover">
                    <template #content>
                      <h4>{{ displayName(value) }}</h4>
                      <p>Correo: {{ value }}</p>
                    </template>
                    {{ displayName(value) }}&nbsp;&nbsp;
                  </a-popover>
                </template>
              </a-tag>
            </template>
            <a-select-option v-for="option in options" :key="option.email">
              <div class="option">
                <p>{{ option.name }}</p>
                <span>{{ option.email }}</span>
              </div>
            </a-select-option>
          </a-select>
          <div class="link">
            <a-button type="link" v-if="!showCCO" @click="showCCO = true">CCO</a-button>
          </div>
        </div>
      </a-form-item>
      <a-form-item label="CCO:" class="send-email" v-if="showCCO">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" class="form-item-xl"
          placeholder="Seleccionar identificador" show-search :filter-option="filteredOptionsCCO"
          v-model:value="formState.selectedItemsCCO" mode="tags" @search="fetchEmails">
          <template #tagRender="{ closable, onClose, value }">
            <a-tag
              :closable="closable"
              :color="getTagColor(value)"
              style="margin-right: 3px"
              @close="onClose"
            >
              <template v-if="shouldRenderIcon(value)">
                <component :is="getTagIcon(value)" />
              </template>
              <template v-else>
                <component :is="getTagIcon(value)" />
                {{ value }}&nbsp;&nbsp;
              </template>
              <template v-if="shouldRenderPopover(value)">
                <a-popover trigger="hover">
                  <template #content>
                    <h4>{{ displayName(value) }}</h4>
                    <p>Correo: {{ value }}</p>
                  </template>
                  {{ displayName(value) }}&nbsp;&nbsp;
                </a-popover>
              </template>
            </a-tag>
          </template>
          <a-select-option v-for="option in options" :key="option.email">
            <div class="option">
              <p>{{ option.name }}</p>
              <span>{{ option.email }}</span>
            </div>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Asunto:">
        <a-input v-model:value="formState.subject" placeholder="Escribir asunto..." />
      </a-form-item>
    </a-form>
    <EditorComponent v-model="formState.text" />
    <InputActionButtons v-model:file="file" v-model:files="files" @select-emoji="handleAddEmoji" multiple />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, inject, Ref, watch } from "vue";
import { UserOutlined, UserAddOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import IconFolderArrow from "@/app/common/components/atoms/IconFolderArrow.vue";
import EditorComponent from "@/app/emails/components/editor/Editor.vue";
import { onMounted } from "vue";
// import { emailService } from "@/app/emails/services/Email.service";
import InputActionButtons from "@/app/shared/components/buttons/InputActionButtons.vue";
import { IMenutem, EmailForm } from "@/@types/emails";
import { useStore } from "vuex";
import { debounce } from "lodash";
import { emailService } from "@/app/emails/services/Email.service";

const props = defineProps({
  open: { type: Boolean, default: false },
  emailTransmitter: { type: Boolean, default: false },
  // defaultEmail: {
  //   type: EmailForm,
  //   default: {
  //     fromEmail: null,
  //     selectedItems: [],
  //     selectedItemsCC: [],
  //     selectedItemsCCO: [],
  //     subject: "",
  //     text: "",
  //   },
  // },
});

// const formState = ref<EmailForm>(props.defaultEmail);

const formState: Ref<EmailForm> =
  inject("emailFromResponse") ??
  ref({
    fromEmail: null,
    selectedItems: [],
    selectedItemsCC: [],
    selectedItemsCCO: [],
    subject: "",
    text: "",
  });

const emit = defineEmits(["handleChangeEmailFrom"]);

// const formState: {
//   fromEmail: number | undefined;
//   selectedItems: string[];
//   selectedItemsCC: string[];
//   selectedItemsCCO: string[];
//   subject: string;
//   text: string;
// } = reactive({
//     fromEmail: undefined,
//     selectedItems: [],
//     selectedItemsCC: [],
//     selectedItemsCCO: [],
//     subject: "",
//     text: "",
//   });

const selectedCampaign = computed(() => {
  return store.getters.getSelectedCampaign;
});

const emailFrom = (email) => {
  const emailFind = addresseeOptions.value.find((el) => el.email == email);
  return emailFind?.name + " <" + emailFind?.email + ">";
};

const file = ref<File>();
const files = ref<File[]>();

const handleAddEmoji = (emoji: string) => {
  const lastClosingTagIndex = formState.value.text.lastIndexOf("</p>");

  const inputText = formState.value.text;

  formState.value.text =
    inputText.slice(0, lastClosingTagIndex) + emoji + inputText.slice(lastClosingTagIndex);
};

defineExpose({ formState, file, files });

// const fromEmail = ref<string>();
// const selectedItems = ref<string[]>([]);
// const selectedItemsCC = ref<string[]>([]);
const showCC = ref(false);
const showCCO = ref(false);
// const transmitter = ref<string>("Ventas Score <ventas@securitec.pe>");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const options = ref<any>([]);

// const addresseeOptions = [
//   {
//     name: "Reclamos Score",
//     email: "reclamos@securitec.pe",
//   },
//   {
//     name: "Ventas Securitec",
//     email: "ventas.cmr@securitec.pe",
//   },
//   {
//     name: "Reclamos Torres",
//     email: "reclamos@gmail.com",
//   },
// ];
// const addresseeOptions = ref<{ id: number; name: string; email: string }[]>([]);
const store = useStore();

// #TODO: cambiar inject de menu items por computed
// const menuItems = inject("menuItems") as Ref<IMenutem[]>;
const menuItems = computed(() => {
  return store.getters.getMenuItems;
});

onMounted(async () => {
  // const identifiers = menuItems?.value.flatMap((i) => i.subItems);
  // if (identifiers) {
  //   addresseeOptions.value = identifiers.map((i) => ({
  //     name: i.title,
  //     id: i.id,
  //     email: i.email,
  //   }));
  // }
  if (formState.value) {
    showCC.value = formState.value.selectedItemsCC.length > 0 ? true : false;
    showCCO.value = formState.value.selectedItemsCCO.length > 0 ? true : false;
    // formState.value = props.defaultEmail;
  }
});

// const filterOption = (input, option) => {
//   const lowerInput = input.toLowerCase();
//   const optionValue = option.value.toLowerCase();
//   const optionLabel = option.label.toLowerCase();

//   return optionValue.includes(lowerInput) || optionLabel.includes(lowerInput);
// };

const filteredOptions = computed(() => {
  const lowerInput =
    formState.value.selectedItems.length > 0
      ? formState.value.selectedItems[0]?.toLowerCase()
      : null;
  return options.value
    .filter(
      (o) => o.name?.toLowerCase().includes(lowerInput) || o.email.toLowerCase().includes(lowerInput)
    )
    .map((item) => ({ value: item.email, label: item.name }));
});

const filteredOptionsCC = computed(() => {
  const lowerInput =
    formState.value.selectedItemsCC.length > 0
      ? formState.value.selectedItemsCC[0]?.toLowerCase()
      : null;
  return options.value
    .filter(
      (o) => o.name?.toLowerCase().includes(lowerInput) || o.email.toLowerCase().includes(lowerInput)
    )
    .map((item) => ({ value: item.email, label: item.name }));
});

const filteredOptionsCCO = computed(() => {
  const lowerInput =
    formState.value.selectedItemsCCO.length > 0
      ? formState.value.selectedItemsCCO[0]?.toLowerCase()
      : null;
  return options.value
    .filter(
      (o) => o.name?.toLowerCase().includes(lowerInput) || o.email.toLowerCase().includes(lowerInput)
    )
    .map((item) => ({ value: item.email, label: item.name }));
});

const addresseeOptions = computed(() => {
  const identifiers = menuItems?.value.flatMap((i) => i.subItems);

  if (identifiers) {
    return identifiers.map((i) => ({
      name: i.title,
      id: i.id,
      email: i.email,
    }));
  }

  return [];
});

const shouldRenderPopover = computed(() => {
  return (value: string) => {
    const color = getTagColor(value);
    return color !== "red";
  };
});

const getTagIcon = (value: string) => {
  const color = getTagColor(value);
  return {
    green: UserOutlined,
    orange: UserAddOutlined,
    red: CloseCircleOutlined,
  }[color];
};

const shouldRenderIcon = (value: string) => {
  return getTagColor(value) !== "red";
};

const isValidEmail = (email: string): boolean => {
  const emailRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
  return emailRegex.test(email);
};

const getTagColor = (value: string) => {
  const containEmail = options.value.some((option) => option.email === value);
  const isValid = isValidEmail(value);

  const color = containEmail ? "green" : isValid ? "orange" : "red";

  if (color === "red") {
    if (!errorModalDisplayed) {
      errorModalDisplayed = true;
      displayErrorModal(value);
    }
  }
  return color;
};

let errorModalDisplayed = false;

const displayName = (value: string) => {
  const contact = options.value.find((option) => option.email === value);
  // return contact?.name || "Contacto no identificado";
  return contact?.name || `No identificado <${value}>`;
};

const displayErrorModal = (email: string) => {
  Modal.error({
    title: "Haz ingresado correos inválidos",
    content: `No se ha podido reconocer la dirección  “${email}”. Asegúrate de que todas las direcciones tengan el formato correcto.`,
    onOk() {
      console.log("ok");
    },
  });
};

const handleChangeEmailFrom = () => {
  emit("handleChangeEmailFrom");
};

const fetchEmails = debounce(async function (event) {
  console.log(event, "event");
  console.log(selectedCampaign.value, "selectedCampaign.value");
  const data = await emailService.getEmailIdentifiers(selectedCampaign.value.channelCustomerId, event);
  options.value = data;
  console.log(data, "data");
}, 300);


// const onClose = (removedTag) => {
//   const newSelectedItems = selectedItems.value.filter((item) => item !== removedTag);
//   selectedItems.value = newSelectedItems;
// };

watch(
  () => formState.value,
  (newValue) => {
    if (newValue) {
      showCC.value = newValue.selectedItemsCC.length > 0 ? true : false;
      showCCO.value = newValue.selectedItemsCCO.length > 0 ? true : false;
      // formState.value = props.defaultEmail;
    }
  }
);
</script>
<style lang="sass" scoped>
p
  margin: 0
.option
  .first
    display: none
.send-email
  position: relative
  .link
    position: absolute
    right: 10px
    top: 0
    button
      margin: 0
      padding: 0 5px
.flex-container
  display: flex
  justify-content: space-between
  align-items: center
.ant-form-item
  margin-bottom: 7px
</style>
<style>
.general .identifier .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.general .transfer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.general .transfer button {
  margin: 0;
  padding: 0;
}

.general .transfer svg path {
  fill: #1677ff;
}
</style>
