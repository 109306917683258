<template>
  <a-modal
    :visible="open"
    width="600px"
    title="Tutorial"
    centered
    :maskClosable="false"
    :bodyStyle="{ height: 'inherit' }"
    :footer="null"
    @cancel="handleClose"
    class="modal-email"
  >
    <div class="image-container" v-if="tutorial.multimediaType == 'image'">
      <img class="image_tutorial" :src="tutorial.urlMedia" alt="image_tutorial">
      <div class="buttons">
        <a-tooltip>
          <a-button type="text" size="large" @click="openPreview = true">
            <img :src="expandIcon" draggable="false" class="icon" alt="icon-expand" />
          </a-button>
        </a-tooltip>
      </div>
      <ImagePreviewModal previewFull v-model:open="openPreview" :image-url="tutorial.urlMedia" />
    </div>
    <div class="video-container" v-else-if="tutorial.multimediaType == 'video'">
      <video controls class="preview_video">
        <source :src="tutorial.urlMedia" type="video/mp4" />
      </video>
    </div>
    <h3>{{ tutorial.title }}</h3>
    <ol>
      <li v-for="step in tutorial.steps" :key="step">
        {{ step }}
      </li>
    </ol>
    <a-alert type="info" show-icon style="margin-bottom: 10px">
      <template #message>
        {{ tutorial.messageInfo }}
      </template>
    </a-alert>

    <a
      v-if="tutorial.linkExternal"
      class="link-info"
      :href="tutorial.linkExternal"
      target="_blank"
    >
      <span>{{ tutorial.linkExternalText }}</span>
      <ExportOutlined />
    </a>

    <div class="footer">
      <a-button @click="handleClose">
        Cerrar tutorial
      </a-button>
    </div>
  </a-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { ExportOutlined } from "@ant-design/icons-vue";
import expandIcon from "@/app/channels/components/image/expand_icon.svg?url";
import ImagePreviewModal from "@/app/chatbots/components/modals/ImagePreviewModal.vue";

defineProps<{
  open: boolean;
  tutorial: any;
}>();

const openPreview = ref(false);
const urlGenerateGoogle = 'https://support.google.com/mail/answer/185833?hl=es-419#zippy=%2Colvidaste-tu-contrase%C3%B1a-de-la-aplicaci%C3%B3n';

const emit = defineEmits<{
  "update:open": [visible: boolean];
}>();

const handleClose = () => {
  emit('update:open', false);
}

</script>
<style lang="sass" scoped>
.email
  display: flex
  sidebar
    width: 35%
    text-align: center
    padding: 10px
    border-right: 1px solid #00000026
    display: flex
    flex-direction: column
    align-items: center
    .content
      flex-grow: 1
      .icon
        color: #389E0D
        display: flex
        align-items: center
        justify-content: center
        padding-bottom: 8px
    .message
      flex-grow: 0
  .form
    width: 65%
    padding: 10px 20px
    .server
      margin-top: 20px
      &-smtp,
      &-imap
        display: flex
        gap: 15px
        p
          margin-bottom: 10px
      .inbound-title
        display: flex
        align-items: center
        > *
          margin-right: 6px

.modal-email
  .ant-form-item
    margin-bottom: 10px
.security
  margin-bottom: 8px
footer
  display: flex
  justify-content: flex-end
  gap: 10px

.help_message
  color: rgba(0, 0, 0, 0.45)

:deep(label[for="form_item_password"])
  display: flex

:deep(.ant-form-item-label)
  .label
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

ol
  padding-inline-start: 18px

.link_external
  padding-left: 0
  margin-top: 10px

.image_tutorial
  width: 550px

.video-container
  border: 1px solid #ede5e5
  border-radius: 10px

.image-container, .video-container
  position: relative
  display: flex
  justify-content: center
  align-items: center
  max-height: 500px
  overflow: hidden
  background-color: #f0f0f0
  cursor: grab
  
  img
    max-width: 100%
    max-height: 500px
    transition: transform 0.3s ease
    user-select: none

  img:active
    cursor: grabbing

  .buttons
    position: absolute
    bottom: 10px
    right: 10px
    padding: 0
    background-color: #00000073
    border-radius: 12px
    color: white
    display: flex
    align-items: center
    .ant-btn
      padding: 0
      color: white
      height: 34px
      display: flex
      align-items: center
  .icon
    margin: 0 8px
    font-size: 20px

.preview_video
  display: block
  margin: 0 auto
  max-width: 100%
  max-height: 380px

.link-info
  padding-left: 0
  span
    text-decoration: underline
    padding-right: 5px

.footer
  width: 100%
  display: flex
  justify-content: center
  margin-top: 20px
</style>
  