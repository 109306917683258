<template>
  <a-drawer
    title="Detalles del contacto"
    placement="right"
    :visible="visible"
    destroyOnClose
    width="890px"
    @close="handleCloseDrawer"
  >
    <ClientCard
      @handleCloseModal="handleCloseModal"
      @handleCloseDrawer="handleCloseDrawer"
      :itemId="currentId"
      :campaignName="campaign || campaignName"
      :isWorkspace="isWorkspace"
      :portfolio="contactPortfolio"
      :isCallInactiveModal="isCallInactiveModal"
      :isCallActiveModal="isCallActiveModal"
    />
    <!-- <div>
      <a-tabs v-model:default-active-key="selectedTabId">
        <a-tab-pane key="1" tab="Datos personales">
          <contact-data-table :itemId="itemId" v-if="itemId" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Teléfonos" force-render>
          <contact-phones-table
            :itemId="itemId"
            v-if="itemId"
            :userDataInfo="getWorkspace"
            @update-table="handleUpdate"
          />
        </a-tab-pane>
        <a-tab-pane key="3" tab="Direcciones">
          <contact-addresses-table :itemId="itemId" v-if="itemId" />
        </a-tab-pane>
        <a-tab-pane key="4" tab="Emails">
          <contact-emails-table :itemId="itemId" v-if="itemId" />
        </a-tab-pane>
        <a-tab-pane key="5" tab="Referidos">
          <contact-referrals-table :itemId="itemId" v-if="itemId" />
        </a-tab-pane>
        <a-tab-pane key="6" tab="Datos personalizados">
          <contact-data-custom-table :itemId="itemId" v-if="itemId" />
        </a-tab-pane>
      </a-tabs>
    </div> -->

    <!-- {{ contact }} -->
    <!-- v-if="showProductsSection" -->

    <!-- <section class="container" v-if="showProductsSection">
      <a-tabs default-active-key="1" class="client-tab">
        <a-tab-pane key="1" tab="Productos" v-if="contact.operationType === 4"
          ><contact-products-table :itemId="itemId" v-if="itemId" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Pagos" v-if="contact.operationType === 4">
          <contact-payments-data-table :itemId="itemId"
        /></a-tab-pane>
        <a-tab-pane key="3" tab="Promesas">
          <contact-promises-data-table :itemId="itemId"
        /></a-tab-pane>
        <a-tab-pane key="4" tab="Ventas" v-if="contact.operationType === 3">
          <contact-promises-data-table :itemId="itemId"
        /></a-tab-pane>
      </a-tabs>
    </section> -->

    <!-- <div class="container">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="Tipificación">
          <contact-typifications-table :itemId="itemId" v-if="itemId" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Historial">
          <contact-record-table :itemId="itemId" v-if="itemId" :contactInfo="getChannels" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="Tipificaciones externas">
          <ExternalTypificationsTable :contactId="contact?.id" />
        </a-tab-pane>
      </a-tabs>
    </div> -->
    <div class="drawer__footer--fixed">
      <template v-if="this.fromSchedule">
        <a-button class="button__drawe--close" @click="handleCloseDrawer">Ocultar</a-button>
      </template>

      <template v-else>
        <a
          class="text__delete"
          @click="validationIsCallActive"
          v-if="business?.profile_name != 'Agente' && contact.status == 1"
        >
          Inactivar contacto
        </a>
        <a
          class="text__activate"
          @click="openDrawerActivate"
          v-else-if="business?.profile_name != 'Agente' && contact.status == 2"
        >
          Activar contacto
        </a>
        <div>
          <a-button class="button__drawe--close" @click="handlePreviousContact">
            Ver anterior contacto
          </a-button>
          <a-button class="button__drawe--close" type="primary" @click="handleNextContact">
            Ver siguiente contacto
          </a-button>
        </div>
      </template>
    </div>
  </a-drawer>
</template>

<script>
import { confirmIsCallUser } from "@/app/contacts/services";
import { defineComponent, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import ClientCard from "@/app/contacts/components/drawers/ClientCard.vue";

export default defineComponent({
  components: {
    ClientCard,
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    fromSchedule: {
      required: true,
      type: Boolean,
    },
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
    contactsInfo: {
      type: Object,
      required: true,
    },
    campaignName: {
      required: false,
      type: String,
    },
    isWorkspace: {
      required: false,
      type: Boolean,
      default: false,
    },
    portfolioId: {
      required: false,
      type: Number || null,
      default: null,
    },
  },
  data() {
    return {
      business: undefined,
      currentId: undefined,
      dataPorfolio: undefined,
      isCall: false,
      isCallInactiveModal: false,
      isCallActiveModal: false,
      position: null,
    };
  },

  watch: {
    itemId(value) {
      if (value) {
        this.currentId = value;
        this.position = this.contactsInfo.items.findIndex((contact) => {
          return contact.id === this.itemId;
        });
      }
    },
  },

  computed: {
    campaign() {
      if (this.contactsInfo) {
        const user = this.contactsInfo.items.find((contact) => contact.key === this.contact.id);
        if (user) return user.portfolioName;
      }
      return "";
    },
    contactPortfolio() {
      if (this.portfolioId) {
        return { id: this.portfolioId, name: this.campaignName };
      } else {
        if (!this.contactsInfo) return;

        const contact = this.contactsInfo.items.find((contact) => contact.key === this.contact.id);

        if (contact) {
          return { id: contact.portfolio, name: contact.portfolio_name };
        }
        return {};
      }
    },
    contact() {
      return this.$store.getters["getContact"];
    },
    lastInteraction() {
      return this.$store.getters["getContact"].lastInteraction;
    },
    // showProductsSection() {
    //   const hasProductsData = this.$store.getters["getProductContactData"]?.results?.length;

    //   const hasPaymentsData = this.$store.getters["getPaymentsClient"]?.results?.length;

    //   const hasPromisesData = this.$store.getters["getPromisesClient"]?.results?.length;

    //   return hasProductsData || hasPaymentsData || hasPromisesData;
    // },
  },
  methods: {
    handleUpdate() {
      this.$emit("update");
    },
    handleCloseDrawer() {
      // Reset contact
      this.$store.commit("SET_CONTACT", {});
      // this.$emit("update:open", false);
      this.$emit("onCloseDrawer");
    },
    async validationIsCallActive() {
      const response = await confirmIsCallUser({
        client: this.contact?.id,
      });
      // this.isCallInactiveModal = true;
      if (response.detail === "Este cliente tiene una llamada en curso.") {
        this.isCallInactiveModal = false;
        this.isCallActiveModal = true;
      } else {
        this.isCallInactiveModal = true;
      }
    },

    openDrawerActivate() {
      this.isCallInactiveModal = true;
    },
    handlePreviousContact() {
      if (this.position > 0) {
        this.position = this.position - 1;
        this.currentId = this.contactsInfo.items[this.position].id;
        this.handleSendData(this.currentId);
      }
    },
    handleNextContact() {
      if (this.position <= 9 && this.position < this.contactsInfo.items.length - 1) {
        this.position = this.position + 1;
        this.currentId = this.contactsInfo.items[this.position].id;
        this.handleSendData(this.currentId);
      }
    },
    handleSendData(currentId) {
      // this.$store.dispatch("fetchContact", currentId);
      this.$store.dispatch("fetchWorkspaceClientByTicket", { ticketId, refresh: true });
      // this.$store.dispatch("fetchPhoneContact", { id: currentId });
      // this.$store.dispatch("fetchCustomerPersonalField", currentId);
      // this.$store.dispatch("fetchCustomerPersonalData", currentId);
      // this.$store.dispatch("fetchAddressContact", currentId);
      // this.$store.dispatch("fetchEmailContact", currentId);
      // this.$store.dispatch("fetchReferralsClient", currentId);
      // this.$store.dispatch("fetchTypificationsClient", currentId);
      // this.$store.dispatch("filtersTypifications", currentId);
      // this.$store.dispatch("fetchRecordsClient", currentId);

      // this.$store.dispatch("fieldsProductContact", currentId);
      // this.$store.dispatch("fetchProductContact", currentId);
      // this.$store.dispatch("fetchPaymentsClient", currentId);
      // this.$store.dispatch("fetchPromisesClient", currentId);
      // this.$store.dispatch("setEmailsTab", { contactId: currentId });
    },
    handleCloseModal() {
      this.isCallInactiveModal = false;
      this.isCallActiveModal = false;
    },
  },
  mounted() {
    this.currentId = this.itemId;
  },
});
</script>

<style lang="sass" scoped>
.ant-form-item
  margin-bottom: 4px

.button__drawe--close
  margin-right: 8px

.drawer__footer--fixed
  bottom: 0
  width: 100%
  border-top: 1px solid $gray-4
  padding: 10px 16px
  left: 0
  background: $white
  border-radius: 0 0 4px 4px
  margin-right: 10px
  position: absolute
  display: flex
  justify-content: flex-end
  align-items: center
  z-index: 1

.image
  width: 17.78px
  margin-right: 15px
  margin-bottom: 10px

.drawer__body
  padding-bottom: 35px

  &--title
    font-weight: 600
    color: $gray-10

.phone
  cursor: pointer

.text__delete
  color: $red-6
  margin-right: 372px

.text__activate
  margin-right: 372px

.image--user
  width: 50px
  height: 50px
  border-radius: 50%

.channels
  width: 16px
  height: 16px
  margin-left: 15px

.container__flex
  display: flex

.container__info--user
  display: flex
  justify-content: space-between
  margin-top: -10px

.container__flex--channels
  display: flex
  flex-direction: row-reverse

.data--user
  margin-left: 10px
  .campaign
    color: $gray-6
    font-style: italic

.text--bold
  margin-bottom: 5px
  color: $gray-9
  font-weight: 600
  font-size: 16px

:deep(.ant-tabs-nav .ant-tabs-tab)
  // margin: 0

:deep(.ant-tabs-nav-scroll)
  border-bottom: 2px solid white !important

.last__contact
  font-size: 12px
  margin-left: 10px
  color: $gray-6
  font-style: italic

.channel--id
  width: 600px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.container
  margin-top: 0
  border-top: $border-medium
  border-bottom: $border-medium

.warning--portfolio
  margin-top: 20px
  margin-bottom: 2px
  font-style: italic

.warning--workspace
  margin-top: 15px
  margin-bottom: 5px
  font-style: italic

.client-tabs
  margin-top: 10px
  :deep(.ant-tabs-nav)
    margin: 0

  :deep(.ant-tabs-nav-wrap)
    display: block !important

  :deep(.ant-tabs-nav-list)
    justify-content: space-evenly

  :deep(.ant-tabs-tab)
    min-width: 23%
    padding-top: 14px !important
    padding-bottom: 14px !important
    justify-content: center

    .tab-content
      display: flex
      justify-content: center
      align-items: center
      gap: 10px
      color: $gray-8

    &.ant-tabs-tab-active
      .tab-content
        color: $blue-6

.container
  margin-top: 0
  padding: 12px 16px
  border-top: $border-medium

  .client-tab
    :deep(.ant-tabs-nav)
      margin-bottom: 16px

    :deep(.ant-tabs-nav-wrap)
      display: flex !important

    :deep(.ant-tabs-nav-list)
      justify-content: flex-start !important
      padding: 0 !important

    :deep(.ant-tabs-tab)
      min-width: 0 !important
      padding-top: 12px !important
      padding-bottom: 12px !important
</style>
