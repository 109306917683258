<template>
  <div>
    <a-menu-item
      v-if="transferUser && $route.query.client"
      @click="handleShowModal"
      key="userstype"
      :disabled="!hasTypification"
    >
      Tipificar y Transferir a usuario
    </a-menu-item>
    <a-menu-item
      v-else-if="transferQueue && $route.query.client"
      @click="handleShowModal"
      key="queuestype"
      :disabled="!hasTypification"
    >
      Tipificar y Transferir a cola
    </a-menu-item>
    <a-tooltip class="tooltip-wrapper" v-else-if="$route.query.client">
      <template #title v-if="disabled">
        Esta campaña no cuenta con <br/>tipificaciones. Solicita a un <br/>
        encargado que los configure <br/>para habilitar este botón.
      </template>
      <a-button
        :type="buttonType"
        :disabled="disabled"
        class="modal-button"
        @click="handleShowModal"
      >
        Tipificar
      </a-button>
    </a-tooltip>

    <a-modal
      v-model:visible="visible"
      :body-style="{ padding: 0 }"
      class="typification-modal"
      centered
      @cancel="handleMinimize"
      width="630"
    >
      <template #title>
        <TypificationHeaderModal
          :show="show"
          :isChat="isChat"
          :transferUser="transferUser"
          :transferQueue="transferQueue"
        />
      </template>

      <!-- Icon slot -->
      <template #closeIcon>
        <MinusOutlined />
      </template>

      <section class="forms__wrapper">
        <CustomScrollbar :height="scrollHeight" auto>
          <div class="forms forms__typification">
            <InformationCard inModal style="margin-bottom: 12px" />

            <a-form class="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
              <h3 v-if="transferUser || transferUser">Paso 1: Tipificar</h3>
              <h3>Selecciona la tipificación</h3>
              <a-space :size="8" class="info-section" align="center">
                <InfoCircleOutlined />

                <span>
                  Inmediatamente después de guardar esta tipificación, se finalizará la llamada.
                </span>
              </a-space>

              <a-row :gutter="24">
                <a-col :span="12">
                  <a-form-item label="Grupo" name="group_pallete">
                    <SearchableSelect
                      v-model:value="formState.group_pallete"
                      placeholder="Seleccione un grupo"
                      :options="groups.map((g) => ({ value: g.id, label: g.name }))"
                      @change="handleGroupChange"
                    />
                  </a-form-item>
                </a-col>
                <a-col v-if="palletOne" :span="12">
                  <a-form-item label="Resultado" name="result_pallete">
                    <SearchableSelect
                      v-model:value="formState.result_pallete"
                      placeholder="Seleccione un resultado"
                      :options="results.map((g) => ({ value: g.id, label: g.name }))"
                      @change="handleResultChange"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="24">
                <a-col v-if="palleteTwo" :span="12">
                  <a-form-item label="Motivo" name="motive_pallete">
                    <SearchableSelect
                      v-model:value="formState.motive_pallete"
                      placeholder="Seleccione un motivo"
                      :options="motives.map((g) => ({ value: g.id, label: g.name }))"
                      @change="handleMotiveChange"
                    />
                  </a-form-item>
                </a-col>
                <a-col v-if="palleteThree" :span="12">
                  <a-form-item label="Submotivo" name="submotive_pallete">
                    <SearchableSelect
                      v-model:value="formState.submotive_pallete"
                      placeholder="Seleccione un submotivo"
                      :options="submotives.map((g) => ({ value: g.id, label: g.name }))"
                      @change="handleSubMotiveChange"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-item label="Ingrese un comentario" name="comment">
                    <a-textarea
                      v-model:value="formState.comment"
                      class="textarea"
                      rows="3"
                      placeholder="Escribe aquí..."
                      :maxlength="commentLimit"
                      @input="handleChangeDescription"
                    />
                    <span class="counter"> {{ formState.comment.length }}/{{ commentLimit }} </span>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>

            <AIConclusionTextArea
              :conclusion="conclusion"
              type="management"
              :summaryId="summaryGeneratedId"
            />

            <div class="reminder">
              <a-divider class="divider" />

              <h3>
                Agregar recordatorio
                <a-switch
                  size="small"
                  v-model:checked="hasReminder"
                  :disabled="hasTypeReminder"
                />
              </h3>

              <ReminderForm
                v-if="hasReminder"
                ref="reminderRef"
                :client-id="ticket.client.id"
                @toggle-save="reminderFormRequiredFieldsFulfilled = $event"
              />
            </div>
          </div>
        </CustomScrollbar>

        <CustomScrollbar :height="scrollHeight" auto>
          <div class="forms forms__custom" v-if="isFormInputShowed">
            <TypificationDynamicForm
              ref="typificationForm"
              :form="typificationForm"
              :type="typeForm"
              :pdp-type="pdpType"
              :modalKey="modalKey"
              @toggleAllowSave="handleToggleAllowSaveForm"
            />
          </div>
        </CustomScrollbar>
      </section>

      <!-- Fotter Modal -->
      <template #footer>
        <div style="display: flex; justify-content: space-between">
          <div>
            <AIConclusionButton
              v-if="ticket.portfolio.enable_summary_agents ? isChat : false"
              type="management"
              @generated="conclusion = $event"
              @updateDisabled="isDisabled = $event"
              @summaryGeneratedId="summaryGeneratedId = $event"
              :disabled="isDisabled"
            />
          </div>

          <div class="footer__buttons">
            <a-button type="link" :disabled="!isChanged" style="padding: 0" @click="handleCancel">
              Limpiar datos
            </a-button>
            <!-- <p class="linked" v-if="isFormInputShowed">
              Vinculado al {{ typificationAsociate.type }} {{ typificationAsociate.name }}
            </p> -->

            <a-tooltip>
              <template #title v-if="!canSave"> {{ saveInfoText }} </template>

              <a-button
                v-if="!transferUser && !transferQueue"
                key="back"
                :disabled="!canSave"
                @click="handleSaveAndResolve"
              >
                {{ "Guardar y resolver ticket" }}
              </a-button>
            </a-tooltip>

            <a-button
              v-if="transferUser || transferQueue"
              style="margin-left: 10px"
              key="back"
              @click="handleMinimize"
            >
              Cancelar
            </a-button>

            <a-tooltip>
              <template #title v-if="!canSave"> {{ saveInfoText }} </template>

              <a-button key="submit" type="primary" :disabled="!canSave" @click="handleOk">
                {{
                  transferUser || transferQueue
                    ? "Tipificar y continuar"
                    : isInCall && isCurrentTicket && saveManagementAndFinishCall
                    ? "Guardar y terminar llamada"
                    : "Guardar tipificación"
                }}
              </a-button>
            </a-tooltip>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
  <AIFeedback
    v-if="openFeedbackModal"
    v-model:open="openFeedbackModal"
    :summaryId="summaryGeneratedId"
    :emojiFace="0"
  />
</template>

<script>
/**
 * =================================================================
 * Copyright 2022, Securitec Perú S.A.C
 *
 * All Rights Reserved.
 * =================================================================
 */
import { defineComponent, ref, reactive, createVNode, inject, nextTick, computed, watch } from "vue";
import { Modal } from "ant-design-vue";
import { MinusOutlined, QuestionCircleOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";

import { CallStatus } from "@/packages/vue-jssip/jssip/phone";
import JssipStorage from "@/packages/vue-jssip/jssip/storage";
import EventBus from "@/app/shared/utils/eventBus";
import { getChannelCustomerIdentifier, getCurrentDateTime } from "@/app/shared/utils/helpers";
import { FormTypes } from "@/app/shared/utils/enums";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import ticketsStorage from "@/app/workspace/storage/tickets";
import {
  fetchFormsByTypification,
  fetchWorkspaceTypifications,
  saveManagement,
  resolveTicketById,
} from "@/app/workspace/services";
import { Event } from "@/packages/vue-jssip/jssip/events";
import { ticketService } from "@/app/workspace/services/TicketService";
import { useRoute } from "vue-router";
import TypificationHeaderModal from "@/app/workspace/components/modal/TypificationHeaderModal.vue";
import ReminderForm from "@/app/workspace/components/modal/ReminderForm.vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import TypificationDynamicForm from "@/app/workspace/components/form/TypificationDynamicForm.vue";
import InformationCard from "@/packages/webphone/components/cards/InformationCard.vue";
import AIConclusionButton from "@/app/integrations/components/buttons/AIConclusionButton.vue";
import AIConclusionTextArea from "@/app/integrations/components/inputs/AIConclusionTextArea.vue";
import AIFeedback from "@/app/common/components/organisms/AIFeedback.vue";
import SearchableSelect from "@/app/shared/components/inputs/SearchableSelect.vue";
import * as Sentry from "@sentry/vue";

export default defineComponent({
  components: {
    MinusOutlined,
    InfoCircleOutlined,
    TypificationHeaderModal,
    ReminderForm,
    CustomScrollbar,
    TypificationDynamicForm,
    InformationCard,
    AIConclusionButton,
    AIConclusionTextArea,
    AIFeedback,
    SearchableSelect,
  },
  props: {
    isChat: { required: false, type: Boolean },
    transferUser: { required: false, type: Boolean, default: false },
    transferQueue: { required: false, type: Boolean, default: false },
    defaultFormState: { required: false, type: Object, default: null },
    disabled: { required: false, type: Boolean, default: false },
    typifiedInteraction: { required: false, type: Boolean, default: false },
  },
  setup() {
    const scrollHeight = "70vh";
    // const hasTypification = ref(false);
    const hasTypification = inject("hasTypification");
    const ticket = inject("selectedTicket");
    const channel = inject("selectedChannel");
    const fetchTicket = inject("fetchTicket");
    const route = useRoute();
    const formRef = ref();
    const isTypified = ref(false);
    const hasReminder = ref(false);
    const hasTypeReminder = ref(false);
    const reminderRef = ref(null);
    const conclusion = ref("");
    const isDisabled = ref(false);
    const summaryGeneratedId = ref(0);
    const openFeedbackModal = ref(false);

    const formState = reactive({
      group_pallete: undefined,
      result_pallete: undefined,
      motive_pallete: undefined,
      submotive_pallete: undefined,
      type_service: undefined,
      comment: "",
    });

    const saveManagementAndFinishCall = computed(() => {
      return ticket.value?.portfolio?.save_management_and_finish_call;
    });

    return {
      scrollHeight,
      ticket,
      channel,
      formState,
      formRef,
      reminderRef,
      isTypified,
      hasReminder,
      FormTypes,
      fetchTicket,
      conclusion,
      isDisabled,
      summaryGeneratedId,
      openFeedbackModal,
      saveManagementAndFinishCall,
      hasTypification,
      hasTypeReminder,
    };
  },
  data() {
    return {
      group_pallete: undefined,
      result_pallete: undefined,
      motive_pallete: undefined,
      submotive_pallete: undefined,
      type_service: undefined,
      comment: "",
      visible: false,
      commentLimit: 500,
      typifications: null,
      resultpdp: false,
      formFields: null,
      keyTipification: 0,
      typificationForm: null,
      typeForm: 0,
      pdpType: null,
      isChanged: false,
      typificationAsociate: {
        name: "",
        type: "",
      },
      confirmationModal: null,
      reminderFormRequiredFieldsFulfilled: false,
      allowSaveTypificationForm: false,
      modalKey: null,
      ivrData: null,
      mustSentIvr: undefined,
      ticketTypification: null,
      ticketTypificationItem: null,
      // interactionId: undefined,
      loadingManagement: false,
    };
  },
  computed: {
    rules() {
      return {
        group_pallete: [{ required: true, message: "Campo requerido" }],
        result_pallete: [
          { required: Boolean(this.results.length !== 0), message: "Campo requerido" },
        ],
        motive_pallete: [
          { required: Boolean(this.motives.length !== 0), message: "Campo requerido" },
        ],
        submotive_pallete: [
          { required: Boolean(this.submotives.length !== 0), message: "Campo requerido" },
        ],
      };
    },
    isCurrentTicket() {
      const openedTicketId = this.$route.query?.ticket;
      // const channelId = this.$route.params?.channel;
      // const { ticket: webphoneTicketId } = this.$jssip.phone?.meta;
      // return openedTicketId == webphoneTicketId;
      return this.$jssip.phone.isCurrentCallTicket(openedTicketId);
    },
    // channel() {
    //   const channels = this.$store.getters["getWorkspaceClientChannels"];
    //   const channelId = this.$route.params.channel;

    //   return channels.find((item) => item.id == channelId);
    // },
    typifications_pallete() {
      if (!this.typifications) return [];
      return this.typifications.level_palette;
    },
    groups() {
      if (!this.typifications || !this.typifications.groups) return [];
      const copy = [...this.typifications.groups];

      copy.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      return copy;
    },
    results() {
      console.log(this.formState.group_pallete, "computed results");
      if (!this.typifications || !this.typifications.results) return [];
      return this.typifications.results.filter(
        (item) => this.formState.group_pallete === item.group
      );
    },
    motives() {
      if (!this.typifications || !this.typifications.motives) return [];
      return this.typifications.motives.filter(
        (item) => this.formState.result_pallete === item.result
      );
    },
    submotives() {
      if (!this.typifications || !this.typifications.submotives) return [];
      return this.typifications.submotives.filter(
        (item) => this.formState.motive_pallete === item.motive
      );
    },
    palletOne() {
      return this.results.length > 0;
    },
    palleteTwo() {
      return this.motives.length > 0;
    },
    palleteThree() {
      return this.submotives.length > 0;
    },
    // ticket() {
    //   const tickets = this.$store.getters["getWorkspaceTickets"];
    //   const ticket = this.$route.params.ticket;
    //   return tickets.find((item) => item.id == ticket);
    // },
    isFormInputShowed() {
      return this.typeForm > 0;
    },
    isIdentifierTypified() {
      const identifier = this.ticket?.typified?.find(
        (_identifier) => _identifier?.identifier === this.channel?.identifier
      );

      return identifier?.is_typified;
    },
    buttonType() {
      return this.isIdentifierTypified ? "default" : "primary";
    },
    strategyPhone() {
      return this.$jssip.phone?.meta?.strategyPhone;
    },
    isInCall() {
      return (
        this.$jssip.phone.status == CallStatus.InCall ||
        this.$jssip.phone.status == CallStatus.Connecting ||
        this.$jssip.phone.status == CallStatus.Ringing
      );
    },
    isCallEnded() {
      return this.$jssip.phone.status === "finished" || this.$jssip.phone.status === "failed";
    },
    show() {
      const acwState = this.$store.getters["getAcwState"];

      const { ticket } = this.$jssip.phone.meta;
      return (
        this.ticket?.time_acw && this.$route.query.ticket == ticket && this.isCallEnded && acwState
      );
    },
    isStrategyOn() {
      return this.$store.getters["getStrategyStatus"];
    },
    phoneStatus() {
      return this.$jssip.phone.status;
    },
    dialingType() {
      return this.$store.getters["getDialingType"];
    },
    globalState() {
      return this.$store.getters["getStateUser"];
    },
    // typificationFormHasReminder() {
    //   return [FormTypes.Reminder, FormTypes.PayPromiseReminder].includes(this.hasTypeReminder);
    // },
    canSaveTypificationForm() {
      return this.isFormInputShowed ? this.allowSaveTypificationForm : true;
    },
    canSave() {
      return this.hasReminder
        ? this.reminderFormRequiredFieldsFulfilled
        : this.canSaveTypificationForm;
    },
    saveInfoText() {
      return this.canSaveTypificationForm
        ? "Complete los campos requeridos"
        : "Complete el formulario de al menos un producto";
    },
    interactionId() {
      return this.channel ? this.channel?.id : undefined;
    },
    ticketId() {
      return Number(this.$route.query.ticket);
    }
  },
  watch: {
    hasTypeReminder(hasFormReminder) {
      if (hasFormReminder) this.hasReminder = false;
    },
    async isFormInputShowed(newIsFormInputShowed) {
      if (newIsFormInputShowed) {
        await nextTick();
        if (this.defaultFormState) {
          this.$refs.typificationForm?.setDefaultFormsValues(this.defaultFormState.formFields);
        }
      }
    },
  },
  mounted() {
    //Se activa y reactiva el evento ya que la tipificacion por defecto se construye como componente indepentente en cada ticket
    EventBus.off(WorkspaceEvent.TYPIFICATION_DEFAULT);
    this.reactivateEvent();
    this.reactivateEventEnd();
  },
  beforeUnmount() {
    // this.saveTypificationInLocalStorage();
  },
  methods: {
    async saveTypificationInLocalStorage() {
      console.log("Modal de tipificacion", {
        ticket: this.ticket,
        routeInteraction: this.interactionId,
      });

      const typification = {
        typification: this.formState,
        hasReminder: this.hasReminder,
        typificationForm: this.typificationForm,
      };

      if (this.hasReminder) typification.reminder = this.$refs.reminderRef.formState;
      if (this.isFormInputShowed) typification.formFields = this.$refs.typificationForm.formsValues;

      console.log(typification, this.interactionId, "save typification");
      ticketsStorage.saveTypification(this.ticketId, typification);
    },
    deleteTypificationFromLocalStorage() {
      if (!this.channel) return;
      ticketsStorage.clearTypification(this.ticketId);
    },
    async setSavedTypificationValues() {
      console.log("setSavedTypificationValues");
      if (!this.channel) return;
      const localValues = ticketsStorage.getTypification(this.ticketId);
      if (!localValues) return;

      Object.assign(this.formState, localValues.typification);
      console.log(this.formState);
      console.log(localValues, "local values");
      this.hasReminder = localValues.hasReminder;
      this.formFields = localValues.formFields;

      await this.$nextTick();

      if (localValues.hasReminder) this.$refs.reminderRef.handleSetData(localValues.reminder);
      const form = localValues.typificationForm;
      this.typificationForm = form;
      this.hasTypeReminder = form?.form.findIndex(el => el.type == "reminder") != -1;
      this.typeForm = form?.id;
      this.pdpType = form?.pdp_type;

      await this.$nextTick();

      if (localValues.formFields)
        this.$refs.typificationForm.setFormsValues(localValues.formFields);

      this.isChanged = true;
    },
    handleToggleAllowSaveForm(allowSave) {
      this.allowSaveTypificationForm = allowSave;
    },
    async handleShowFeedbackModal() {
      if (this.interactionId) {

        const messages = this.isChat ? await ticketService.getInteractionMessages(this.interactionId) : [];
        const summaries = messages.summaries;
        const totalSummaries = summaries?.filter((item) => item.origin === "workspace");
        console.log(totalSummaries, "totalSummaries");

        // const mostRecentObject = totalSummaries?.reduce((prev, current) =>
        //   new Date(prev.created_at) > new Date(current.created_at) ? prev : current
        // );
        // console.log(mostRecentObject, "mostRecentObject");
        
        // const sameDayObjects = totalSummaries?.filter(
        //   (obj) =>
        //     new Date(obj.created_at).toLocaleDateString() ===
        //     new Date(mostRecentObject.created_at).toLocaleDateString()
        // );
        // console.log(sameDayObjects, "sameDayObjects");

        const mostRecentObject = Array.isArray(totalSummaries) && totalSummaries.length > 0
          ? totalSummaries.reduce((prev, current) => 
              new Date(prev.created_at) > new Date(current.created_at) ? prev : current
            )
          : null;
        console.log(mostRecentObject, "mostRecentObject");

        const sameDayObjects = mostRecentObject
          ? totalSummaries?.filter((obj) =>
                new Date(obj.created_at).toLocaleDateString() ===
                new Date(mostRecentObject.created_at).toLocaleDateString()
            )
          : null;
        console.log(sameDayObjects, "sameDayObjects");

        const countSummaries = sameDayObjects?.length;

        const allNullRankings = sameDayObjects?.every((obj) => obj.ranking === null);

        if (countSummaries === 5 && this.summaryGeneratedId !== 0 && allNullRankings) {
          this.openFeedbackModal = true;
        }
      }
    },
    /**
     * Function to save typifcation
     */
    async saveManagement(shouldResolveTicket) {
      try {
        if (!this.loadingManagement) {
          this.loadingManagement = true;
          if (this.hasReminder) await this.reminderRef.handleCreate();

          const ticket = this.ticketTypification ?? Number(this.$route.query.ticket);

          if (!this.channel) return;

          const management = {
            phone_number: this.channel?.channel?.id == 1 ? this.channel?.identifier : null,
            identifier: this.channel?.identifier,
            channel_customer_identifier: getChannelCustomerIdentifier({
              channel: this.channel?.channel,
              channelCustomer: this.channel?.channel_customer,
            }),
            channel_customer: this.channel?.channel_customer?.id,
            asterisk_id: this.channel?.asterisk_id ?? undefined,
            strategy_phone: this.$jssip.phone.meta.ticket == ticket ? this.$jssip.phone.meta.strategyPhone : undefined,
            ticket,
            // channel_person: this.$route.query.channel,
            interaction_id: this.$route.query.interaction,
            type_interaction: this.channel?.type_interaction,
            type_service: this.type_service,
            group_pallete: this.formState.group_pallete,
            result_pallete: this.formState.result_pallete,
            motive_pallete: this.formState.motive_pallete,
            submotive_pallete: this.formState.submotive_pallete,
            comment: this.formState.comment,
            form: this.formFields,
            strategy: this.isStrategyOn,
            strategy_phone_id: this.ticketTypificationItem?.last_strategy_phone_id,
            ivr: this.$jssip.phone.meta.ticket == ticket ? this.$jssip.phone.meta.ivr?.ivr?.id : undefined,
            summary: this.conclusion,
          };

          await saveManagement(management);

          console.log(this.isInCall, 'this.isInCall esta en llamada');
          if (this.isInCall)
            this.$jssip.updatePhoneTicketTypificated(true);
          
          if (shouldResolveTicket)
            await resolveTicketById(ticket, { observations: this.comment || "-" });

          const finishCall = (this.isInCall && this.isCurrentTicket && this.saveManagementAndFinishCall) || shouldResolveTicket;
          if (finishCall || !this.isInCall) {
            EventBus.$emit(WorkspaceEvent.MANAGEMENT_SAVED, {
              isTypificationDefault: false,
              shouldResolveTicket,
              ticketId: ticket,
              finishCall: finishCall,
            });
          }

          // ticketService.updateTicket(this.ticket.id, { enabled_to_transfer: true });
          ticketService.updateTicket(ticket, { enabled_to_transfer: true });

          if(this.formRef) this.formRef.resetFields();
          this.hasTypeReminder = false;
          this.typeForm = 0;
          this.typificationForm = null;
          if (this.ticketTypification == this.$route.query.ticket && (finishCall || !this.isInCall)) {
            this.$store.commit("SET_ACW_STATE", false);
            if (this.$jssip.phone.meta.ticket == ticket) this.isTypified = true;
          }

          this.deleteTypificationFromLocalStorage();

          if (this.ticketTypification == this.$route.query.ticket && (finishCall || !this.isInCall)) {
            JssipStorage.clearAcwTime();
          }
          console.log('ticket call: ', this.$jssip.phone.meta.ticket);
          this.reactivateEventEnd();
            
          this.$message.success("Se ha guardado la tipificación correctamente");
          this.loadingManagement = false;
        }
      } catch (error) {
        const errorMessage = error.getErrorMessage() || "Ocurrió un error";
        this.$message.error(errorMessage);
        this.loadingManagement = false;

        // registro en sentry
        const errorDetails = error.getErrorDetails();
        if (errorDetails.status == 400) {
          console.log(errorDetails, 'error resolve ticket api');
          Sentry.captureMessage(`Error 400: ${JSON.stringify(errorDetails.url)}`, {
            level: "error",
            extra: errorDetails,
          });
        }
      }
    },
    async fetchFormFields() {
      try {
        this.formFields = null;
        this.hasTypeReminder = false;
        this.typeForm = 0;
        this.typificationForm = null;
        const formTypification = await fetchFormsByTypification(this.$route.query.ticket, {
          group: this.formState.group_pallete,
          result: this.formState.result_pallete,
          motive: this.formState.motive_pallete,
          submotive: this.formState.submotive_pallete,
        });

        console.log("fetchFormFields", formTypification, this.formState);

        let linkedTypification = null;

        if (this.typificationForm?.id !== formTypification.id) {
          if (this.submotive_pallete) {
            linkedTypification = this.submotives.find(
              (submotive) => submotive.id == this.submotive_pallete
            );
            this.typificationAsociate.type = "submotivo";
          }

          if (this.motive_pallete) {
            linkedTypification = this.motives.find((motive) => motive.id == this.motive_pallete);
            this.typificationAsociate.type = "motivo";
          }

          if (this.result_pallete) {
            linkedTypification = this.results.find((result) => result.id == this.result_pallete);
            this.typificationAsociate.type = "resultado";
          }

          this.typificationAsociate.name = linkedTypification?.name;
        }

        this.typificationForm = formTypification;
        const formAux = this.typificationForm.form;
        this.hasTypeReminder = this.typificationForm.form.findIndex(el => el.type == "reminder") != -1;
        this.typeForm = formTypification?.id;
        this.pdpType = formTypification?.pdp_type;
      } catch (error) {
        this.typificationAsociate.name = "";
        this.typificationAsociate.type = "";
      }
    },
    async fetchTypificationDetail(type, id) {
      // const details = await getTypificationItemByType({ id, type });
      //
      // if (details.ivr) this.ivrData = { type, ...details };
      console.log({ type, id });
    },
    handleGroupChange(value) {
      this.group_pallete = value;
      this.resetProperty("result_pallete");
      this.resetProperty("motive_pallete");
      this.hasTypeReminder = false;
      this.typeForm = 0;
      this.isChanged = true;
    },
    addPDP(id) {
      this.resultpdp = this.results.filter((item) => id === item.id);
    },
    handleResultChange(value) {
      this.addPDP(value);
      this.result_pallete = value;
      this.resetProperty("motive_pallete");
      this.formFields = null;
      this.fetchFormFields();
      this.fetchTypificationDetail("result", value);
    },
    handleMotiveChange(value) {
      this.motive_pallete = value;
      this.formFields = null;
      this.fetchFormFields();
      this.fetchTypificationDetail("motive", value);
    },
    handleSubMotiveChange(value) {
      this.submotive_pallete = value;
      this.formFields = null;
      this.fetchFormFields();
      this.fetchTypificationDetail("submotive", value);
    },
    formPallete(level) {
      if (this.typifications_pallete == level) {
        this.fetchFormFields();
      }
    },
    handleChangeDescription(event) {
      this.comment = event.target.value;
    },
    handleServiceChange(value) {
      this.type_service = value;
    },
    resetProperty(key) {
      this[key] = undefined;
      this.formState[key] = undefined;
    },
    async setFormToTypification() {
      if (!this.isFormInputShowed) return;

      this.formFields = null;

      try {
        this.formFields = await this.$refs.typificationForm?.validateForms();
      } finally {
        await this.formRef.validateFields();
      }
    },
    async handleShowModal() {
      this.modalKey = getCurrentDateTime();

      this.visible = true;
      this.ticketTypification = this.$route.query.ticket;
      this.ticketTypificationItem = this.ticket;
      console.log(this.ticketTypification, 'ticketTypification in show modal');
      console.log(this.ticketTypificationItem, 'ticketTypificationItem in show modal');
      if (localStorage.getItem('workspace') == 'romero' && 
        this.ticketTypification == this.ticketTypificationItem?.id) {
        Sentry.captureMessage(`Tipificacion ticket no coincide: ${this.ticketTypification} - ${this.ticketTypificationItem?.id} | ${Date.now()}`, {
          level: "info",
          extra: {
            query: this.$route.query,
            ticketRoute: this.ticketTypification,
            item: this.ticketTypificationItem,
            date: new Date(),
          },
        });
      }
      this.typifications = await fetchWorkspaceTypifications(this.ticketTypification);
      await this.setSavedTypificationValues();

      // const localValues = this.getSavedTypification();
      // await this.$nextTick();
      // // await this.registerEvent(EventManagement.Typifying);
      // if (!localValues) return;
      // this.group_pallete = localValues.group_pallete;
      // this.result_pallete = localValues.result_pallete;
      // this.motive_pallete = localValues.motive_pallete;
      // this.submotive_pallete = localValues.submotive_pallete;
      // this.comment = localValues.comment;
      // this.type_service = localValues.type_service;
      // this.formFields = localValues.formFields;
    },
    async handleOk() {
      try {
        await this.setFormToTypification();

        await this.formRef.validateFields();

        if (!this.transferUser && !this.transferQueue) {
          if (this.formState.group_pallete) {
            await new Promise((res) => {
              this.confirmationModal = Modal.confirm({
                title: `¿Estás seguro(a) de guardar la tipificación de este cliente?`,
                content:
                  "Recuerda que luego podras hacer click en resolver el ticket para poder cerrarlo.",
                centered: true,
                width: this.isInCall ? 516 : 416,
                icon: createVNode(QuestionCircleOutlined),
                onOk: async () => {
                  this.visible = false;
                  this.isChanged = false;
                  // if (this.isInCall && this.isCurrentTicket) {
                  //   this.$jssip.end();
                  // }
                  await this.saveManagement();
                  this.hasTypeReminder = false;
                  this.typeForm = 0;
                  this.typificationForm = null;
                  this.ivrData = null;
                  this.conclusion = "";
                  this.mustSentIvr = null;
                  if(this.formRef) this.formRef.resetFields();
                  this.hasReminder = false;
                  this.handleShowFeedbackModal();
                  this.isDisabled = false;
                  res();
                },
                okText:
                  this.isInCall && this.isCurrentTicket && this.saveManagementAndFinishCall ? "Guardar y terminar llamada" : "Guardar",
                cancelText: this.isInCall && this.isCurrentTicket ? "Volver a llamada" : "Cancelar",
              });
            });

            const ticketId = this.$route.query.ticket;

            const requests = [
              this.$store.dispatch("fetchActiveWorkspaceTickets"),
              this.fetchTicket({ preventLoading: true }),
            ];

            if (ticketId) {
              requests.push(this.$store.dispatch("fetchTypificationsTableData", ticketId));
              requests.push(
                this.$store.dispatch("fetchWorkspaceClientByTicket", { ticketId, refresh: true })
              );
            }

            Promise.allSettled(requests);
          }
        } else {
          await this.saveTypificationInLocalStorage();
          this.$emit("handleNext", {
            key: this.transferUser ? "users" : "queues",
            typificationRef: this,
            formState: {
              formState: { ...this.formState },
              hasReminder: this.hasReminder,
              reminderRef: this.reminderRef,
              typificationForm: this.typificationForm,
              formFields: this.formFields,
              $jssip: this.$jssip,
              channel: this.channel,
              type_service: this.type_service,
              isStrategyOn: this.isStrategyOn,
              conclusion: this.conclusion,
            },
            fromTypificationParam: true,
          });
          this.visible = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async handleMinimize() {
      this.saveTypificationInLocalStorage();
      this.visible = false;
      // const eventAccordingToChannel =
      //   this.channel?.channel?.id === 1 ? this.globalState : EventManagement.Opened;

      // await this.registerEvent(eventAccordingToChannel);
    },
    handleCancel() {
      if (this.isChanged) {
        // const values = { ...toRaw(this.formState) };
        // const ticket = this.$route.query.ticket;
        // ticketsStorage.saveTypification(ticket, {
        //   ...values,
        //   formFields: this.formFields,
        // });
        this.deleteTypificationFromLocalStorage();
        this.formFields = null;
        this.hasTypeReminder = false;
        this.typeForm = 0;
        this.typificationForm = null;
        this.hasReminder = false;
        this.ivrData = null;
        this.conclusion = "";
        this.mustSentIvr = null;
        this.isChanged = false;
        this.formRef?.resetFields();
      }

      if (this.confirmationModal) this.confirmationModal?.destroy();
      // this.visible = false;
    },
    async handleSaveAndResolve() {
      // const ticket = this.$route.query.ticket;

      await this.setFormToTypification();

      this.formRef.validateFields().then(() => {
        if (this.formState.group_pallete) {
          this.confirmationModal = Modal.confirm({
            title: `¿Estás seguro(a) de guardar la tipificación de este cliente y resolverla?`,
            content: "Esto cerrará el ticket y terminará con la llamada.",
            centered: true,
            width: this.isInCall ? 516 : 416,
            icon: createVNode(QuestionCircleOutlined),
            onOk: async () => {
              // if (this.isInCall && this.isCurrentTicket) {
              //   this.$jssip.end();
              // }
              await this.saveManagement(true);
              this.$store.commit("SET_ACW_STATE", false);

              EventBus.$emit(WorkspaceEvent.TICKET_CLOSED, { resolveWithoutTypification: false });
              await this.$store.dispatch("fetchActiveWorkspaceTickets");
              this.deleteTypificationFromLocalStorage();
              JssipStorage.clearAcwTime();
              EventBus.$emit(WorkspaceEvent.DIAL_CLOSE);

              this.formFields = null;
              this.hasTypeReminder = false;
              this.typeForm = 0;
              this.formRef?.resetFields();
              this.visible = false;
              this.isChanged = false;
              this.hasReminder = false;
              this.handleShowFeedbackModal();
              this.isDisabled = false;
            },
            okText: "Guardar y resolver",
            cancelText: this.isInCall ? "Volver a llamada" : "Cancelar",
          });
        }
      });
    },
    /**
     * Function to save typification default
     */
    async handleTypificationDefault() {
      console.log('handle typification default: ', this.isTypified);
      if (!this.isTypified) {
        console.log("HANDLE_TYPIFICATION_DEFAULT");

        const body = {
          ticket: this.$jssip.phone.meta.ticket,
          interaction_id: this.$jssip.phone.meta.interaction,
          phone_number: this.$jssip.phone.receiver.number,
          identifier: this.$jssip.phone.receiver.number,
          channel_customer:
            this.$jssip.phone.meta?.channel_customer?.id ||
            this.$jssip.phone.meta?.channel_customer,
          asterisk_id: this.channel?.asterisk_id ?? undefined, // this.$jssip.phone.meta?.asteriskId,
          strategy_phone: this.$jssip.phone.meta.strategyPhone,
          strategy: this.isStrategyOn,
        };

        const type_default_management = this.$jssip.phone.meta.type_default_management;

        const messageText = type_default_management === 1 ? "el log" : "la tipificación";

        try {
          await this.$store.dispatch("saveDefaultManagement", body);
          this.$message.success(`Se ha guardado ${messageText} por defecto correctamente`);
        } catch (e) {
          this.$message.error(`No se pudo guardar la tipificación por defecto`);
          console.log(e);
        }

        // Update typification history table data
        const ticketId = this.$jssip.phone.meta.ticket;

        if (ticketId == this.$route.query.ticket) {
          this.$store.dispatch("fetchTypificationsTableData", ticketId);
          this.$store.dispatch("fetchWorkspaceClientByTicket", { ticketId, refresh: true });
        }

        // this.$jssip.reset();
        EventBus.off(WorkspaceEvent.TYPIFICATION_DEFAULT);
        this.$store.commit("SET_ACW_STATE", false);
        this.reactivateEvent();
        EventBus.$emit(WorkspaceEvent.MANAGEMENT_SAVED, { isTypificationDefault: true });
        this.isChanged = false;
        this.visible = false;
      }
    },
    /**
     * Function to reactive the TYPIFICATION_DEFAULT Event when fires from main layout
     */
    reactivateEvent() {
      EventBus.on(WorkspaceEvent.TYPIFICATION_DEFAULT, (ticket) => {
        this.handleTypificationDefault(ticket);
        console.log("EVENTBUS_REACTIVE_EVENT", ticket);
        // this.handleCancel();
      });
    },
    reactivateEventEnd() {
      EventBus.$on(Event.Ended, () => {
        this.isTypified = false;
      });
    },
  },
});
</script>

<style lang="sass" scoped>
.forms
  padding: 16px 22px

  &__wrapper
    display: flex

  &__typifications
    width: 520px

  &__custom
    width: 620px
    border-left: 1px solid $gray-4
    padding-top: 0
    min-height: 100%

.divider
  background-color: $gray-4
  margin: 16px 0


h3
  margin: 0
  font-weight: 600
  font-size: 16px

.reminder
  h3
    display: flex
    align-items: center
    gap: 8px

.form
  h3
    margin-bottom: 16px

.info-section
  margin-bottom: 20px
  text-align: justify
  color: $gray-7
  font-size: 14px
  font-style: italic

.label
  display: block
  margin-bottom: 8px

.ant-form-item
  .textarea
    position: relative

  .counter
    position: absolute
    right: 12px
    bottom: 0
    color: $gray-6
    font-size: $font-small
.acw-style
  color: #F5222D

.footer__buttons
  display: flex
  align-items: center

:deep(.footer__buttons .ant-btn-primary)
  margin-left: 8px

.linked
  font-size: 12px
  font-style: italic
  font-weight: 400
  letter-spacing: 0em
  color: $gray-6
  margin: 0
  margin-right: 8px

.scroll-area
  margin: 0

.modal-button
  width: 100%
  height: 100%
  min-height: 32px
  padding: 0

:deep(.tooltip-wrapper)
  width: 100% !important

// :global(.tooltip-wrapper)
//   width: 100% !important
</style>

<style>
.typification-modal.ant-modal {
  width: inherit !important;
}
</style>
