import httpManager from "@/app/shared/utils/httpManager";
import { toFormData } from "@/app/shared/utils/helpers";
/**
 * Crear una nueva capaña
 *
 * @param {Object} portfolio Datos de la campaña
 *
 * @return {Promise<Object>} Datos de la campaña creada
 */
export async function createNewPortfolio(portfolio) {
  const body = portfolio;
  if (!body.is_enable_time_acw) delete body.time_acw;
  if (!body.is_enable_call_time) delete body.call_time;
  if (!body.description) delete body.description;
  body.text_negotiation = body.text_negotiation || "Sin speech";
  const channelCustomer = [...portfolio.channel_customer];
  delete body.channel_customer;

  const formData = toFormData(body, { avoidEmpty: true, avoidFalse: false });
  channelCustomer.forEach((channel) => {
    formData.append("channel_customer", channel);
  });
  const response = await httpManager.upload("/portfolios/", formData);
  return response.data;
}
